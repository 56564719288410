import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';

import { PaymentModel } from '../../model/pouch/auxiliary-table.model';
import { DestinationPouchModel, DestinationPouchResponseModel } from '../../model/pouch/destination-pouch.model';
import { OrderPouchModel } from '../../model/pouch/order-pouch.model';
import { TagListPouchModel, TagPouchModel } from '../../model/pouch/tag.model';
import { ArticleCatalogColumnService } from '../../service/td-data-table/implementation/article-catalog.service';
import { ArticlePublicCatalogColumnService } from '../../service/td-data-table/implementation/article-public-catalog.service';
import { ProductCatalogColumnService } from '../../service/td-data-table/implementation/product-catalog.service';
import { MediaReplayService } from '../../service/util/media-replay.service';
import { SubscribeManagerService } from '../../service/util/subscribe-manager.service';
import * as fromState from '../../state';
import * as fromArticleList from '../../state/article-list/article-list.actions';
import { ArticleListState } from '../../state/article-list/article-list.reducer';
import { CompanyState } from '../../state/company/company.reducer';
import * as fromDestinationList from '../../state/destination-list/destination-list.actions';
import * as fromFamilyList from '../../state/family-list/family-list.actions';
import { FamilyListState } from '../../state/family-list/family-list.reducer';
import * as fromOrder from '../../state/order/order.actions';
import { OrderState } from '../../state/order/order.reducer';
import * as fromTagList from '../../state/tag-list/tag-list.actions';
import {
	ArticleFamilyCatalogFilters,
	FamilyCatalogFilters
} from '../common/catalog/catalog-family/catalog-family.component';
import { ArticleModel } from './../../model/article.model';
import { UtilOrderService } from './../../service/util/util-order.service';
import { ArticleNewQuantity } from './../common/catalog/dialog-catalog-family/dialog-catalog-family-list/dialog-catalog-family-list.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'ngx-webstorage';

@Component({
	selector: 'page-catalog',
	templateUrl: './page-catalog.component.html',
	styleUrls: ['./page-catalog.component.scss'],
	providers: [SubscribeManagerService]
})
export class PageCatalogComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@LocalStorage('language') language: string;

	tagListData$: Observable<TagListPouchModel>;
	tagList: TagPouchModel[] = [];
	familyList: string[] = [];

	company$: Observable<CompanyState> = this.store.select(fromState.getCompanyState);
	company: CompanyState;

	order$: Observable<OrderState> = this.store.select(fromState.getOrderState);
	order: OrderState;

	payments: PaymentModel[] = [];
	payment$: Observable<PaymentModel[]>;
	shipmentAddress: DestinationPouchModel[];
	destinationList$: Observable<any>;
	forkSub: Subscription;

	family$: Observable<FamilyListState> = this.store.select(fromState.getFamilyListState);
	family: FamilyListState = {
		filters: {}
	};

	articleList$: Observable<ArticleListState> = this.store.select(fromState.getArticleListState);
	articleList: ArticleListState = {
		data: [],
		filters: {
			excludes: {
				stato_articolo: 'E'
			}
		}
	};
	articlesTableColumns: ITdDataTableColumn[] = [];

	get isPublic(): boolean {
		return this.router.url.split('?')[0] === '/public/catalog';
	}
	languages = ['it', 'en'];
	selectedLang: string;

	constructor(
		private store: Store<any>,
		private productCatalogColumnService: ProductCatalogColumnService,
		private articleCatalogColumnService: ArticleCatalogColumnService,
		private articlePublicCatalogColumnService: ArticlePublicCatalogColumnService,
		private subscribeManagerService: SubscribeManagerService,
		public mediaReplayService: MediaReplayService,
		private router: Router,
		private utilOrderService: UtilOrderService,
		private snackbar: MatSnackBar,
		private translate: TranslateService,
		private route: ActivatedRoute
	) {

		if (!this.isPublic) {
			this.destinationList$ = this.store.select(fromState.getDestinationListState);
			this.payment$ = this.store.select(fromState.getAuxiliaryPagamenti);
			this.store.dispatch(fromOrder.update(new OrderPouchModel()));
			// Recupero la company e l'ordine
			this.subscribeManagerService.populate(
				this.initMandatoryData().subscribe(() => {
					this.forkSub = forkJoin([this.retrievePaymentDivision(), this.retrieveDestinationList()]).subscribe(
						list => {
							this.fillNewOrderWithMandatoryData();
						}
					);
				}),
				'page-catalog-init'
			);
			this.loadFamilyList();
			this.articlesTableColumns = this.articleCatalogColumnService.columns;
		} else {
				// Subscribe del queryparams lang da passare alle chiamate per il recupero delle descrizioni articoli e filtri
				// priorità lang: 1 queryparam - 2 localStorage - 3 Browser - 4 default (en)
				this.route.queryParams.subscribe(params => {
					// default
					if (this.languages.includes(this.languages[1])) {
						this.selectedLang = this.languages[1];
					}
					// browser
					if (this.languages.includes(this.translate.getBrowserLang())) {
						this.selectedLang = this.translate.getBrowserLang();
					}
					// salvata nel local storage
					if (this.languages.includes(this.language)) {
						this.selectedLang = this.language;
					}
					// query param
					if (params.lang && this.languages.includes(params.lang)) {
						this.selectedLang = params.lang;
					}
					this.translate.use(this.selectedLang);

					this.store.dispatch(fromTagList.loadTagListPublic({ lang: this.selectedLang }));
					this.loadFamilyList();
					this.articlesTableColumns = this.articlePublicCatalogColumnService.columns;
				});
				this.articlesTableColumns = this.articlePublicCatalogColumnService.columns;
		}
		//this.loadFamilyList();
		this.retrieveArticleListDetail();
	}

	ngOnInit() {}

	initMandatoryData() {
		return this.company$.pipe(
			skipWhile((company: CompanyState) => !(company && company.codice)),
			mergeMap((company: CompanyState) => {
				this.company = company;
				this.store.dispatch(fromDestinationList.load({ codice_cliente: this.company.codice }));
				return this.order$;
			}),
			skipWhile((order: OrderPouchModel) => !order),
			map((order: OrderPouchModel) => {
				this.order = order;
			})
		);
	}

	retrieveArticleListDetail() {
		const subKey = 'page-catalog-article-list';
		if (!this.subscribeManagerService.hasSubscription(subKey)) {
			this.subscribeManagerService.populate(
				this.articleList$.subscribe((res: ArticleListState) => {
					if (res && res.data && res.data.length > 0) {
						this.articleList.data = res.data;
					}
				}),
				subKey
			);
		}
	}

	retrievePaymentDivision() {
		return this.payment$.pipe(
			skipWhile((payments: PaymentModel[]) => !(payments && payments.length > 0)),
			take(1),
			tap((payments: PaymentModel[]) => {
				this.payments.push(
					payments.find(payment => payment.codice_elemento === this.company.condizione_pagamento)
				);
			})
		);
	}

	retrieveDestinationList() {
		return this.destinationList$.pipe(
			skipWhile((destinationList: DestinationPouchResponseModel) => !(destinationList && destinationList.values)),
			take(1),
			tap((destinationList: DestinationPouchResponseModel) => {
				this.shipmentAddress = destinationList.values;
			})
		);
	}

	loadArticleList() {
		if (this.isPublic) {
			this.store.dispatch(
				fromArticleList.loadPublic({
					filters: this.articleList.filters
				})
			);
		} else {
			this.store.dispatch(
				fromArticleList.loadWithDetail({
					filters: this.articleList.filters,
					company: this.company
				})
			);
		}
	}
	// modifico l'oggetto in modo che accetti anche la prop lang e la passo come parametro
	loadFamilyList() {
		if (this.isPublic) {
			this.store.dispatch(
				fromFamilyList.loadFamilyPublic({
					pagination: this.family.pagination,
					filters: this.family.filters,
					lang: this.selectedLang
				})
			);
		} else {
			this.store.dispatch(
				fromFamilyList.load({
					pagination: this.family.pagination,
					filters: this.family.filters,
					lang: this.selectedLang
				})
			);
		}
	}

	fillNewOrderWithMandatoryData() {
		this.order = this.utilOrderService.initializeDraftOrder(
			this.order,
			this.company,
			this.payments[0].codice_elemento,
			this.shipmentAddress[0].codice
		);
	}

	getArticlesTableColumns() {
		return this.articlesTableColumns;
	}

	catalogFamilyFiltersChange(filters: FamilyCatalogFilters) {
		this.family.filters.description = filters.textField;
		this.family.filters.families = filters.familyCodes;
		this.loadFamilyList();
	}

	articleFamilyFiltersChange(filters: ArticleFamilyCatalogFilters) {
		this.articleList.filters['family'] = filters.familyCode;
		this.loadArticleList();
	}

	toggleColumn(e) {
		for (let i = 0; i < this.productCatalogColumnService.columns.length; i++) {
			if (this.productCatalogColumnService.columns[i].name === e.name) {
				this.productCatalogColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	articleQuantityChange(articleNewQuantity: ArticleNewQuantity) {
		const newArticle: ArticleModel = articleNewQuantity.article;
		const newQuantity = articleNewQuantity.quantity;
		const foundArticle = this.articleList.data.find(article => article.codice === newArticle.codice);

		if (foundArticle) {
			if (newQuantity !== foundArticle.qty) {
				const description = `${foundArticle.descrizione}-${foundArticle.descrizione_aggiuntiva}`;
				foundArticle.qty = !foundArticle.qty ? 0 : foundArticle.qty;

				let action: string;
				action = newQuantity > foundArticle.qty ? 'Aggiunto' : 'Tolto';

				this.showSnackbar(description, action);
			}
			foundArticle.qty = newQuantity;
			console.log('add article: ', foundArticle);
			this.store.dispatch(
				fromOrder.save(
					this.utilOrderService.addArticleToOrder(
						this.utilOrderService.returnArticleWithCalculatePrice(foundArticle),
						this.order
					)
				)
			);
		}
	}
	showSnackbar(message: string, action: string) {
		this.snackbar.open(message, action, {
			duration: 2000
		});
	}

	ngOnDestroy() {
		//this.onLangChange$.unsubscribe();
		this.subscribeManagerService.destroy();
		this.store.dispatch(fromOrder.reset());
		if (this.forkSub) {
			this.forkSub.unsubscribe();
		}
	}
}
