import { MediaReplayService } from './../../../../service/util/media-replay.service';
import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITdDataTableColumn } from '@covalent/core';

import { ArticleModel } from '../../../../model/article.model';
import { FamilyPouchModel } from '../../../../model/pouch/family-pouch.model';
import { ArticleNewQuantity } from './dialog-catalog-family-list/dialog-catalog-family-list.component';

@Component({
	selector: 'dialog-catalog-family',
	templateUrl: './dialog-catalog-family.component.html',
	styleUrls: ['./dialog-catalog-family.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DialogCatalogFamilyComponent implements OnInit {
	family: FamilyPouchModel;
	articleList: ArticleModel[];
	articlesTableColumns: ITdDataTableColumn[];

	onArticleQuantityChange: EventEmitter<ArticleNewQuantity> = new EventEmitter<ArticleNewQuantity>();

	constructor(@Inject(MAT_DIALOG_DATA) private data, public mediaReplayService: MediaReplayService) {
		this.family = this.data.family;
		this.articleList = this.data.articleList;
		this.articlesTableColumns = this.data.columns;
	}

	ngOnInit() {}

	articleQuantityChange(articleNewQuantity: ArticleNewQuantity) {
		this.onArticleQuantityChange.emit(articleNewQuantity);
	}
}
