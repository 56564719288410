export class LoginModel {
  email?: string;
  username?: string;
  password?: string;
  client_code?: string;
}

export class TokenPayload {

  constructor(data: {
      exp?: number,
      orig_iat?: number,
      user_id?: number,
      username?: string,
      signature?: string,
      codes?: string[]
  } = {}) {
      this.exp = data.exp;
      this.orig_iat = data.orig_iat;
      this.user_id = data.user_id;
      this.username = data.username;
      this.signature = data.signature;
      this.codes = data.codes;
  }

  exp?: number;
  orig_iat?: number;
  user_id?: number;
  username?: string;
  signature?: string;
  codes?: string[];

  private static getConvertedDate(date) {
      const utcSeconds = date;
      const d = new Date(0);
      d.setUTCSeconds(utcSeconds);
      return d;
  }

  getConvertedExp(): Date | null {
      if (this.exp) {
          return TokenPayload.getConvertedDate(this.exp);
      }
      return null;
  }

  getConvertedOrigIat(): Date | null {
      if (this.orig_iat) {
          return TokenPayload.getConvertedDate(this.orig_iat);
      }
      return null;
  }

  equal(tk: TokenPayload) {
      return this.exp === tk.exp && this.orig_iat === tk.orig_iat && this.user_id === tk.user_id && this.username === tk.username;
  }
}
