import { createAction, props } from '@ngrx/store';
import { OrderPouchModel } from '../../model/pouch/order-pouch.model';

export const save = createAction('[Order Context] Save', props<OrderPouchModel>());

export const update = createAction('[Order Context] Update', props<OrderPouchModel>());

export const load = createAction('[Order Detail Context] Load', props<{ id: string }>());

export const reset = createAction('[Order Detail Context] Reset');

export const remove = createAction('[Order Detail Context] Delete', props<{ order: any }>());
