import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';

export class TabelleAusiliariePouch extends AbstractPouchdbMethod{

	getCausaliRigheOdv(): Promise<any> {
		return new Promise(resolve => {
			this.get('causali_righe_odv')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getCausaliSospensioneOrdini(): Promise<any> {
		return new Promise(resolve => {
			this.get('causali_sospensione_ordini')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getCausaliTestateOdv(): Promise<any> {
		return new Promise(resolve => {
			this.get('causali_testate_odv')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getCodiciIva(): Promise<any> {
		return new Promise(resolve => {
			this.get('codici_iva')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getModalitaDiConsegna(): Promise<any> {
		return new Promise(resolve => {
			this.get('modalita_di_consegna')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getModalitaDiSpedizione(): Promise<any> {
		return new Promise(resolve => {
			this.get('modalita_di_spedizione')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getNazioniIso(): Promise<any> {
		return new Promise(resolve => {
			this.get('nazioni_iso')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getPagamenti(): Promise<any> {
		return new Promise(resolve => {
			this.get('pagamenti')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getTipiListini(): Promise<any> {
		return new Promise(resolve => {
			this.get('tipi_listini')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getTipiValuta(): Promise<any> {
		return new Promise(resolve => {
			this.get('tipi_valuta')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getUnitaMisura(): Promise<any> {
		return new Promise(resolve => {
			this.get('unita_misura')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getVettori(): Promise<any> {
		return new Promise(resolve => {
			this.get('vettori')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getZoneCommerciali(): Promise<any> {
		return new Promise(resolve => {
			this.get('zone_commerciali')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
