import { createReducer, on } from '@ngrx/store';
import * as fromCategoryList from './category-list.actions';
// model
import { CategoryListModel } from '../../widget/nested-category-list/nested-category-list.component';

export interface CategoryListState extends CategoryListModel {}

export const initialState: CategoryListState = {}

const _reducer = createReducer(initialState,
  on(fromCategoryList.load, (state) => state),
  on(fromCategoryList.update, (state, categories) => categories)
);

export function reducer(state, action) {
  return _reducer(state, action);
}