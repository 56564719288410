import { MatAccordion } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'mat-accordion-wrapper',
	templateUrl: './mat-accordion-wrapper.component.html',
	styleUrls: ['./mat-accordion-wrapper.component.scss']
})
export class MatAccordionWrapperComponent implements OnInit {
	@Input() color: string;
	@Input() expandedState: boolean;
	constructor() {}

	ngOnInit() {}
}
