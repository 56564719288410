import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';

import { TagListPouchModel } from '../../model/pouch/tag.model';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import { CatalogService } from '../../service/rest/catalog.service';
import * as fromTagList from '../tag-list/tag-list.actions';
import { UtilCatalogService } from './../../service/util/util-catalog-filter.service';

// service
// model
@Injectable()
export class TagListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Tag List Context] Load'),
			mergeMap(action =>
				from(this.getTagListPouch()).pipe(
					map(tagList => fromTagList.update(tagList)),
					catchError(() => of({ type: '[Tag List Context] Tag List Load Error' }))
				)
			)
		)
	);

	loadTagList$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[] Load Public Tag'),
			mergeMap(action =>
				from(this.getTagListRest(action)).pipe(
					map(tagList => fromTagList.update(tagList)),
					catchError(() => of({ type: '[Tag List Context] Tag List Load Error' }))
				)
			)
		)
	);
	constructor(
		private actions$: Actions,
		private pouchUtilService: PouchUtilService,
		private utilCatalogFilterService: UtilCatalogService,
		public catalogService: CatalogService,
		private appConfig: AppConfig
	) {}

	async getTagListPouch(): Promise<any> {
		const tagList: TagListPouchModel = <TagListPouchModel>{};
		let tagDescriptionlist = [];
		const promises = [];
		const tag_graph = this.appConfig.config.couch.tag_graph;
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tagListPouch.getTagGraphList(tag_graph)
				.then(res => {
					tagList.tag_graph_list = res.tag_graph_list;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tagListPouch.getTagDescriptionList()
				.then(res => {
					tagDescriptionlist = res.tag_list;
				})
		);
		return Promise.all(promises).then(() => {
			this.utilCatalogFilterService.mergeTagDescription(tagDescriptionlist, tagList.tag_graph_list);
			return tagList;
		});
	}
	// Inserisco il parametro lang che passo nell'action
	async getTagListRest(lang: {lang: string}): Promise<any> {
		const tagList: TagListPouchModel = <TagListPouchModel>{};

		return this.catalogService.getTagList(lang.lang);
	}
}
