import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class ConfigCouchModel {
	endpoint: string = '';
	database: string = '';
	authSecret: string = '';
	authUsername: string = '';
	authRoles: string[] = [];
}
export class UrlConfigModel {
	label: string = '';
	api: string = '';
	couch: ConfigCouchModel[];
}
export class ConfigModel {
	environment: string = '';
	urlConfig: UrlConfigModel;
	company: {
		name: string;
		code: string;
		theme: string;
		logo: string;
		meta: [];
	};
	couch: {
		tag_graph: string;
	};
	recaptchaSiteKey: string = '';
	urlMediaCenter: string = '';
	constructor() {
		this.urlConfig = new UrlConfigModel();
	}
}

@Injectable()
export class AppConfig {
	private configSource = new BehaviorSubject<ConfigModel>(new ConfigModel());
	config$ = this.configSource.asObservable();

	constructor() {}

	populateConfig(configuration: ConfigModel) {
		this.configSource.next(configuration);
	}
	get config(): ConfigModel {
		return this.configSource.getValue();
	}
}
