import { Component, OnInit, HostBinding, Input, ViewChild, ElementRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaReplayService } from '../../service/util/media-replay.service';

@Component({
	selector: '[list-wrapper]',
	templateUrl: './list-wrapper.component.html',
	styleUrls: ['./list-wrapper.component.scss']
})
export class ListWrapperComponent implements OnInit {
	// @HostBinding('class') row = 'row';
	// @Input() side_title: string;

	// se true comporta la visualizzazione del sidenav aperta in init e in mode 'side'
	@Input() filterOpened: boolean;
	// se il valore viene passato
	// - l'host prende una dimensione fissa e l'overflow viene gestito dalla perfect-scroll
	// - height viene sovrascritto
	@Input() minHeight: string;
	@HostBinding('style.height') hostHeight;
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
	// permette di calcolare la distanza tra l'host e il top del device
	@ViewChild('offsetAnchor', { static: false, read: ElementRef }) offsetAnchor: ElementRef;

	mode: string;
	height: string = null;

	constructor(public mediaReplayService: MediaReplayService) {
		// this.filterOpened = document.body.clientWidth > 1200;
	}

	ngOnInit() {
		if (this.filterOpened) {
			this.mode = 'side';
		} else {
			this.mode = 'over';
		}
	}

	ngAfterViewInit() {
		// Expression has changed after it was checked...
		setTimeout(() => {
			if (this.minHeight) {
				this.hostHeight = this.minHeight;
				this.height = this.minHeight;
			} else {
				this.height = this.mediaReplayService.getRemainingDeviceHeight(this.offsetAnchor.nativeElement) + 'px';
			}
		});
	}
}
