import { Component, OnInit, Input } from '@angular/core';

export class CardTrendConfigurationModel {
	// la color palette viene usata per colorare lo sfondo con il tono 500 ed il testo con il tono 500 del relativo contrast
	// background-color e color non vengono sovrascritti lasciando la prop. null
	theme?: 'mat-primary' | 'mat-accent' | 'mat-warn';
	title: string;
	subtitle: string;
	trend: number;
	valueType?: string;
	time: string;
	value: string;
}

export class CardArrayItemModel {
	cardTrendConfiguration: CardTrendConfigurationModel;
	main: any
}

@Component({
	selector: 'card-trend',
	templateUrl: './card-trend.component.html',
	styleUrls: ['./card-trend.component.scss']
})

export class CardTrendComponent implements OnInit {

	@Input('config') config: CardTrendConfigurationModel;

	constructor() {}

	ngOnInit() {}
}
