import { createReducer, on } from '@ngrx/store';
import * as fromDestinationList from './destination-list.actions';
import { DestinationPouchModel } from '../../model/pouch/destination-pouch.model';

export interface DestinationListState {
	values: DestinationPouchModel[];
}

export const initialState: DestinationListState = null;

const _reducer = createReducer(
	initialState,
	on(fromDestinationList.load, state => state),
	on(fromDestinationList.update, (state, destinationList: DestinationListState) => destinationList),
	on(fromDestinationList.reset, state => initialState)
);

export function reducer(state, action) {
	return _reducer(state, action);
}
