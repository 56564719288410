import { FamilyCatalogFilters } from './../../page/common/catalog/catalog-family/catalog-family.component';
import { Pagination } from '../../model/structure/AbstractListModel';
import { FamilyPouchModel } from '../../model/pouch/family-pouch.model';
import { FamilyListState } from '../../state/family-list/family-list.reducer';
import { Injectable } from '@angular/core';

import { TagListPouchModel } from '../../model/pouch/tag.model';
import { UtilCatalogService } from '../util/util-catalog-filter.service';
import { BaseApiService } from './baseApi.service';
import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise, IResourceMethodPromiseStrict } from '@ngx-resource/core';
import { LoaderService } from '../structure/loader.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppConfig } from '../../app.config';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
	providedIn: 'root'
})
export class CatalogService extends BaseApiService {  
	constructor(
		private utilCatalogFilterService: UtilCatalogService,
		protected loaderService: LoaderService,
		public router: Router,
		public localStorageService: LocalStorageService,
		protected appConfig: AppConfig,
		private translate: TranslateService,
	) {
		super(loaderService, router, localStorageService, appConfig);
	}

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/family',
	})
	getCatalogFamily: IResourceMethodPromiseStrict<{ codes?: string[]; text?: string;  lang?: string}, {codes?: string[]; text?: string;  lang?: string}, any, FamilyListState>;
	//getCatalogFamily: IResourceMethodPromise<{ codes?: string[]; text?: string; lang?: string }, FamilyListState>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/product'
	})
	getProductsList: IResourceMethodPromise<{ code?: string }, any>;

	// Da sotto in poi sono da modificare
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/filter-structure'
	})
	getCatalogFilterStructure: IResourceMethodPromise<void, TagListPouchModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/filter-structure-description'
	})
	getCatalogFilterStructureDescription: IResourceMethodPromiseStrict<void, { lang: string}, void, any>;

	getTagList(lang: string): Promise<TagListPouchModel> {
		const tagList: TagListPouchModel = <TagListPouchModel>{};
		let tagDescriptionlist = [];
		const promises = [];

		promises.push(
			this.getCatalogFilterStructure().then((tags: TagListPouchModel) => {
				tagList.tag_graph_list = tags.tag_graph_list;
			})
		);

		promises.push(
			this.getCatalogFilterStructureDescription( null, {lang: lang}).then(res => {
				tagDescriptionlist = res.tag_list;
			})
		);
		return Promise.all(promises).then(() => {
			this.utilCatalogFilterService.mergeTagDescription(tagDescriptionlist, tagList.tag_graph_list);
			return tagList;
		});
	}

	getFamily(pagination?: Pagination, filters?, lang?: string): Promise<FamilyPouchModel[]> {
		let getCatalogFamily$;
		const params = {};
		getCatalogFamily$ = this.getCatalogFamily(params, {lang: lang});
		
		if (filters) {
			if (filters.families){
				params['codes'] = filters.families;
				getCatalogFamily$ = this.getCatalogFamily(params, {codes:params['codes'], lang:lang});
			}
			if (filters.description){
				params['text'] = filters.description;
				getCatalogFamily$ = this.getCatalogFamily(params, {text:params['text'], lang:lang});
			}
			if (filters.lang) {
				params['lang'] = filters.lang;
			}			
		}
		return getCatalogFamily$.then((families: FamilyListState) => {
			return families.data;
		});
	}
}
