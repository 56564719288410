import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material';
// custom date format
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Ng5SliderModule } from 'ng5-slider';

import {
	DateAdapter as SatDateAdapter,
	MAT_DATE_FORMATS as SAT_DATE_FORMATS,
	MAT_DATE_LOCALE as SAT_DATE_LOCALE,
	SatDatepickerModule,
  } from 'saturn-datepicker';

export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'x'
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};
@NgModule({
	// imports: [NgxDaterangepickerMd.forRoot()],
	declarations: [],
	exports: [
		MatInputModule,
		MatExpansionModule,
		MatTabsModule,
		MatIconModule,
		MatListModule,
		MatButtonModule,
		MatToolbarModule,
		MatDialogModule,
		MatMenuModule,
		MatGridListModule,
		MatCardModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatSliderModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatSlideToggleModule,
		MatSidenavModule,
		MatCheckboxModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatButtonToggleModule,
		// NgxDaterangepickerMd,
		MatRippleModule,
		// not materia but...
		SatDatepickerModule,
		// SatNativeDateModule,
		Ng5SliderModule,
		MatBadgeModule
	],
	providers: [
		// custom date format
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
		{
			provide: SatDateAdapter,
			useExisting: DateAdapter,
		  },
		  { provide: SAT_DATE_FORMATS, useExisting: MAT_DATE_FORMATS },
	]
})
export class MaterialComponentsModule {}
