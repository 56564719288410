import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';

import { CustomResponseLogin, User } from '../../model/user.model';
import { SimpleListItemModel } from '../../model/util.model';
import { BaseApiService } from './baseApi.service';

// model
@Injectable()
export class UserService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/authentication/recovery'
	})
	passwordRecovery: IResourceMethodPromise<string, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/authenticate'
	})
	login: IResourceMethodPromise<string, CustomResponseLogin>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/Authentication/GetUserInfos?username={!mail}'
	})
	getUserInfo: IResourceMethodPromise<{ mail: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/Authentication/client-code'
	})
	getClientCode: IResourceMethodPromise<void, SimpleListItemModel[]>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/authentication/update'
	})
	passwordUpdate: IResourceMethodPromise<string, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/user/{!id}'
	})
	getUserDetail: IResourceMethodPromise<{ id: number }, User>;
}
