import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class CompanyDashboardColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
    {
      name: "business_name",
      label: "Azienda"
    },
    {
      name: "revenue",
      label: "Revenue"
    },
    {
      name: "contribution_on_total_percentage",
      label: "% on revenue"
    },
    {
      name: "revenue_trend_percentage",
      label: "Revenue trend"
    }
	];
}
