import { createReducer, on } from '@ngrx/store';
import * as fromCompany from './company.actions';
// model
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model'

export interface CompanyState extends CompanyPouchModel {}

export const initialState: CompanyState = <CompanyState>{};

const _reducer = createReducer(initialState,
  on(fromCompany.update, (state, company) => company),
  on(fromCompany.reset, (state) => initialState),
  on(fromCompany.saveUsers, (state) => state)
);

export function reducer(state, action) {
  return _reducer(state, action);
}