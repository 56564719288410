import { CatalogService } from '../../service/rest/catalog.service';
import { ArticleListState } from './article-list.reducer';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, filter } from 'rxjs/operators';
import * as fromArticle from './article-list.actions';
import { ArticlePouchModel } from '../../model/pouch/article-pouch.model';
import { Pagination } from '../../model/structure/list-structure.model';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';
import { OrderPouchModel } from '../../model/pouch/order-pouch.model';
import { ArticleModel } from '../../model/article.model';
import { ProductPouchModel } from '../../model/pouch/order-pouch.model';
import { UtilOrderService } from '../../service/util/util-order.service';
import { DiscountTypeModel } from '../../enum/discountType.enum';
import { ArticleCodes } from '../../service/pouchdb/centroutensili/pouch-function/price.pouch';
import * as moment from 'moment';
import { Moment } from 'moment';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import { AppConfig } from '../../app.config';

@Injectable()
export class ArticleListEffects {
	readonly mapPriceNames = {
		CURRENT: {
			valueStartDate: 'data_inizio_val_cor',
			valuePrice: 'prezzo_corrente',
			valueDiscount: 'sconti_correnti'
		},
		PREVIOUS: {
			valueStartDate: 'data_inizio_val_pre',
			valuePrice: 'prezzo_precedente',
			valueDiscount: 'sconti_precedenti'
		},
		FUTURE: { valueStartDate: 'data_inizio_val_fut', valuePrice: 'prezzo_futuro', valueDiscount: 'sconti_futuri' }
	};

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Articles Context] Load'),
			mergeMap((action: { pagination?: Pagination; filters?: any } = {}) =>
				from(
					this.pouchUtilService
						.getActiveAdapter()
						.articlePouch.getArticleList(this.appConfig,action.pagination, action.filters)
						.then(res => {
							const dt = { data: [] } as ArticleListState;
							if (res) {
								dt.data = res.docs;
							}
							return dt;
						})
				).pipe(
					map((articles: ArticleListState) => {
						return fromArticle.update(articles);
					}),
					catchError(() => of({ type: '[Companies COntext] Company Load Error' }))
				)
			)
		)
	);
	loadPublic$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Articles Context] Load Public'),
			mergeMap((action: { pagination?: Pagination; filters?: any }) =>
				from(
					this.catalogService.getProductsList({ code: action.filters.family }).then(res => {
						const dt = { data: [] } as ArticleListState;
						if (res) {
							dt.data = res.data.filter(article => article.stato_articolo !== 'E');
						}
						return dt;
					})
				).pipe(
					map((articles: ArticleListState) => {
						return fromArticle.update(articles);
					}),
					catchError(() => of({ type: '[Companies COntext] Company Load Error' }))
				)
			)
		)
	);

	loadWithDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Articles Context] Load With Detail'),
			mergeMap(
				(action: {
					pagination?: Pagination;
					filters?: any;
					company?: CompanyPouchModel;
					order?: OrderPouchModel;
				}) =>
					from(
						this.getArticleWithDetail(action.pagination, action.filters, action.company, action.order)
					).pipe(
						map((articles: ArticleListState) => {
							return fromArticle.update(articles);
						}),
						catchError(() => of({ type: '[Companies we] Company Load Error' }))
					)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private utilOrderService: UtilOrderService,
		public catalogService: CatalogService,
		private pouchUtilService: PouchUtilService,
		private appConfig: AppConfig
	) {}

	getArticleFiltered(codiceCategory?: string, pagination?: Pagination): Promise<{ data: ArticlePouchModel[] }> {
		return this.pouchUtilService
			.getActiveAdapter()
			.articlePouch.getArticleListByCategory(codiceCategory, pagination)
			.then(res => {
				return { data: res['docs'] };
			});
	}

	private setArticleOrderFields(article: ArticleModel, order: OrderPouchModel) {
		const checkoutArticle = order.product_list.find(
			(orderArticle: ProductPouchModel) => orderArticle.code === article.codice
		);
		if (checkoutArticle) {
			article.note = checkoutArticle.note;
			article.qty = checkoutArticle.ordered_quantity;
			article.calculate_price = null;
			article = this.utilOrderService.returnArticleWithCalculatePrice(article);
		} else {
			article.qty = null;
			article.calculate_price = null;
		}
	}

	async getArticleWithDetail(
		pagination: Pagination,
		filter: any,
		company: CompanyPouchModel,
		order: OrderPouchModel
	): Promise<any> {
		const res = await this.pouchUtilService.getActiveAdapter().articlePouch.getArticleList(this.appConfig, pagination, filter);
		const articles = { data: [] } as ArticleListState;
		if (res) {
			articles.data = res.docs;
			const articleCodes = articles.data.map((article: ArticleModel) => article.codice).sort();
			const articleLine = articles.data[0].linea;
			const articleGroupCode = articles.data[0].gruppo_articolo;
			const articleBudgetNumber = articles.data[0].budget_number;
			await this.getDetails(company, articleCodes, articleGroupCode, articleBudgetNumber, articleLine).then(
				(detailList: any) => {
					const listPrices = detailList['list'];
					const scartPrices = detailList['scart'];
					articles.data.forEach((article: ArticleModel) => {
						let foundPrice = false;
						let foundDiscount = false;
						article.discount = [];

						const scart = this.selectCorrectScartForArticle(scartPrices, article);
						const listino = listPrices.find(el => el.codice_articolo === article.codice);

						if (scart) {
							const articleDiscounts = this.selectCorrectDiscount(scart);
							articleDiscounts.forEach((articleDiscount: any) => {
								if (articleDiscount['sconto_riga_z'] !== 'Z') {
									if (articleDiscount['sconto_riga'] !== 0) {
										foundDiscount = true;
										article.discount.push({ value: articleDiscount['sconto_riga'], type: 0 });
									}
								} else {
									foundDiscount = true;
								}
							});
							const articlePrice = this.selectCorrectPrice(scart);
							if (articlePrice) {
								foundPrice = true;
								article.article_price = +articlePrice;
							}
						}
						if (!foundPrice || !foundDiscount) {
							if (!listino) {
								console.log('Listino non trovato per article: ', article);
							} else {
								if (!foundPrice) {
									const articlePrice = this.selectCorrectPrice(listino);
									article.article_price = listino && articlePrice ? +articlePrice : 0;
								}
								if (!foundDiscount) {
									const articleDiscounts = this.selectCorrectDiscount(listino);
									article.discount =
										listino && articleDiscounts
											? [{ value: articleDiscounts[0], type: 0 }]
											: [{ value: 0, type: 0 }];
								}
							}
						}
						if (order) {
							this.setArticleOrderFields(article, order);
						}
					});
				}
			);
		}
		return new Promise(resolve => {
			resolve(articles);
		});
	}

	selectCorrectScartForArticle(scartPrices: any[], article: ArticleModel) {
		let scart = scartPrices.find(sheet => sheet.tipo_sk === 'ART' && sheet.codice_articolo === article.codice);
		if (!scart) {
			scart = scartPrices.find(
				sheet => sheet.tipo_sk === 'GAR' && sheet.codice_articolo === article.gruppo_articolo
			);
			if (!scart) {
				scart = scartPrices.find(
					sheet => sheet.tipo_sk === 'BDG' && sheet.codice_articolo === article.budget_number
				);
				if (!scart) {
					scart = scartPrices.find(
						sheet => sheet.tipo_sk === 'LIN' && sheet.codice_articolo === article.linea
					);
					if (!scart) {
						scart = scartPrices.find(sheet => sheet.tipo_sk === 'ZZZ');
					}
				}
			}
		}
		return scart;
	}

	selectCorrectPrice(priceObject) {
		const correctValue = this.selectCorrectValueStartDate(priceObject);
		const toTakeKey = this.mapPriceNames[correctValue].valuePrice;
		return priceObject[toTakeKey];
	}

	selectCorrectDiscount(priceObject) {
		const correctValue = this.selectCorrectValueStartDate(priceObject);
		const toTakeKey = this.mapPriceNames[correctValue].valueDiscount;
		let discount = [];
		if (priceObject[toTakeKey]) {
			discount = priceObject[toTakeKey];
		}
		return discount;
	}

	selectCorrectValueStartDate(priceObject): string {
		const today = moment();
		const currentValueStartDate = this.getMomentFromStringDateAndFormat(
			priceObject[this.mapPriceNames['CURRENT'].valueStartDate],
			'YYYY-MM-DD'
		);
		const previousValueStartDate = this.getMomentFromStringDateAndFormat(
			priceObject[this.mapPriceNames['PREVIOUS'].valueStartDate],
			'YYYY-MM-DD'
		);
		const futureValueStartDate = this.getMomentFromStringDateAndFormat(
			priceObject[this.mapPriceNames['FUTURE'].valueStartDate],
			'YYYY-MM-DD'
		);

		const currentValueStartDateDiff = currentValueStartDate
			? Math.abs(currentValueStartDate.diff(today, 'days'))
			: undefined;
		const previousValueStartDateDiff = previousValueStartDate
			? Math.abs(previousValueStartDate.diff(today, 'days'))
			: undefined;
		const futureValueStartDateDiff = futureValueStartDate
			? Math.abs(futureValueStartDate.diff(today, 'days'))
			: undefined;

		const daysDiffs = [currentValueStartDateDiff, previousValueStartDateDiff, futureValueStartDateDiff];

		const daysDiffsNoUndefined = daysDiffs.filter(diff => diff !== undefined);

		let toTakeValue = 'CURRENT';
		if (daysDiffsNoUndefined.length > 0) {
			const minDays = Math.min(...daysDiffsNoUndefined);
			if (minDays === previousValueStartDateDiff) {
				toTakeValue = 'PREVIOUS';
			} else if (minDays === futureValueStartDateDiff) {
				toTakeValue = 'FUTURE';
			}
		}
		return toTakeValue;
	}

	getMomentFromStringDateAndFormat(stringDate: string, format: string): Moment | undefined {
		return stringDate && stringDate !== '0' ? moment(stringDate, format) : undefined;
	}

	private async getDetails(
		company: CompanyPouchModel,
		articleCodes: string[],
		articleGroupCode: string,
		articleBudgetNumber: string,
		linea: string
	) {
		const dataPrice = await this.pouchUtilService.getActiveAdapter().pricePouch.getPrice({
			clientCode: company.codice,
			articleCode: articleCodes,
			articleGroupCode: articleGroupCode,
			articleBudgetNumber: articleBudgetNumber,
			listCode: company.codice_listino,
			lineCode: linea
		});
		return dataPrice;
	}
	public getProductListRest(action: any, { codice: string }): Promise<ArticleListState> {
		return this.catalogService.getProductsList();
	}
}
