import { Injectable } from '@angular/core';
import { ArticleModel, DiscountModel } from '../../model/article.model';
import { ProductPouchModel, OrderPouchModel } from '../../model/pouch/order-pouch.model';
import { ArticleListState } from '../../state/article-list/article-list.reducer';
import { OrderState } from '../../state/order/order.reducer';
import { UtilCompanyService } from './util-company.service';
import { UtilService } from './util.service';
import * as moment from 'moment';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';

// model
@Injectable({
	providedIn: 'root'
})
export class UtilOrderService {
	constructor(private utilService: UtilService, private utilCompanyService: UtilCompanyService) {}

	initializeDraftOrder(
		order: OrderPouchModel,
		company: CompanyPouchModel,
		payment_code,
		goods_destination_code
	): OrderPouchModel {
		order.header.status = 'DRAFT';
		order.header.client_code = company.codice;
		order.header.company = {
			business_name: company.ragione_sociale,
			piva: company.partita_iva
		};
		order.header.payment_code = payment_code;
		order.header.goods_destination_code = goods_destination_code;
		order.header.delivery_methods = company.modalita_consegna;
		order.header.division = company.divisione;
		order.header.price_list_code = company.codice_listino;
		order.header.shipping_mode = company.modalita_spedizione;
		order.header.society_code = company.codice_azienda;
		order.header.order_causal = company.causale_ordine_abituale;
		//order.header.date = moment().format('DD/MM/YYYY');
		order.header.date = +moment().format('x');
		// order.header.first_evasion_date = moment()
		// 	.add(dayToAdd, 'days')
		// 	.format('DD/MM/YYYY');
		order.header.first_evasion_date = +moment()
			.add(1, 'days')
			.format('x');
		return order;
	}

	formatOrderCode(order: any): string {
		if (order && order.as400) {
			return order.as400.order_year + '_' + order.as400.order_number;
		}
		return order._id.replace('order_', '');
	}

	returnArticleWithCalculatePrice(article: ArticleModel) {
		article.calculate_price = article.article_price;
		// sconti
		if (article.discount && article.discount.length > 0) {
			article.discount.forEach((articleDiscount: DiscountModel) => {
				article.calculate_price =
					article.calculate_price - (article.calculate_price * articleDiscount.value) / 100;
			});
		}
		// quantità & pezzi_confezione
		article.calculate_price = article.calculate_price * article.qty;
		return article;
	}

	addArticleToOrder(toAddArticle: ArticleModel, order: OrderState) {
		const orderProductIndex = order.product_list.findIndex((orderProduct: ProductPouchModel) => {
			return (
				orderProduct.code === toAddArticle.codice &&
				orderProduct.line_code === toAddArticle.linea &&
				orderProduct.class_code === toAddArticle.classe_merceologica
			);
		});

		if (orderProductIndex !== -1) {
			if (!toAddArticle.qty) {
				// delete
				order.product_list.splice(orderProductIndex, 1);
			} else {
				// update
				order.product_list[orderProductIndex] = {
					line_code: toAddArticle.linea,
					class_code: toAddArticle.classe_merceologica,
					sup_row_external: '1',
					code: toAddArticle.codice,
					order_causal_code: this.utilCompanyService.getDefaultCompanyCausal().causali_riga[0],
					ordered_quantity: toAddArticle.qty,
					qty_free: toAddArticle.qty_free,
					price: toAddArticle.article_price ? toAddArticle.article_price : null,
					description: toAddArticle.descrizione,
					descrizione_aggiuntiva: toAddArticle.descrizione_aggiuntiva,
					note: toAddArticle.note,
					discount: toAddArticle.discount,
					disponibilita: toAddArticle['stock']['code_segnalation'],
					pezzi_confezione: toAddArticle.pezzi_confezione
				};
			}
		} else if (toAddArticle.qty) {
			// add
			order.product_list.push({
				line_code: toAddArticle.linea,
				class_code: toAddArticle.classe_merceologica,
				sup_row_external: '1',
				code: toAddArticle.codice,
				order_causal_code: this.utilCompanyService.getDefaultCompanyCausal().causali_riga[0],
				ordered_quantity: toAddArticle.qty,
				price: toAddArticle.article_price ? toAddArticle.article_price : null,
				description: toAddArticle.descrizione,
				descrizione_aggiuntiva: toAddArticle.descrizione_aggiuntiva,
				qty_free: toAddArticle.qty_free,
				note: toAddArticle.note,
				discount: toAddArticle.discount,
				disponibilita: toAddArticle['stock']['code_segnalation'],
				pezzi_confezione: toAddArticle.pezzi_confezione
			});
		}
		order.header.total = this.getProductsTotalPrice(order);

		return order;
	}

	clearAllProductArticleList(articleList: ArticleListState) {
		articleList.data.forEach(i => {
			this.clearArticleList(articleList, this.utilService.getElementIndex(articleList.data, '_id', i._id));
		});
		return articleList;
	}

	clearArticleList(articleList: ArticleListState, articleIndex: number) {
		articleList.data[articleIndex].qty = 0;
		articleList.data[articleIndex].last_price = 0;

		return articleList;
	}

	calculatePriceWithTax(order: OrderState) {
		const total = order.header.total
			? (order.header.total + +(order.header.total * order.header.iva).toFixed(2)).toFixed(2)
			: 0;
		return total;
	}

	calculateDiscount(data): number {
		const price = data.article_price ? data.article_price : data.price;
		const qty = data.qty >= 0 ? data.qty : data.ordered_quantity;
		let calc_price = +(price * qty);
		if (data.discount) {
			data.discount.forEach(element => {
				// Il tipo dello sconto sarà sempre 0 perché non c'è più la divisione tra sconto decimale e percentuale
				if (element.type === 0) {
					calc_price -= (calc_price * element.value) / 100;
				}
			});
		}
		return calc_price;
	}

	getProductsTotalPrice(order: OrderState) {
		let productsTotalPrice = 0;
		order.product_list.forEach(product => {
			productsTotalPrice += +this.calculateDiscount(product).toFixed(2);
		});
		return this.utilService.roundTo2DecimalPlaces(productsTotalPrice);
	}

	getCalculatedVat(order: OrderState) {
		const calculatedVat = this.getProductsTotalPrice(order) * 0.22;
		return this.utilService.roundTo2DecimalPlaces(calculatedVat);
	}

	getOrderTotalPrice(order: OrderState) {
		const orderTotalPrice = this.getProductsTotalPrice(order) + this.getCalculatedVat(order);
		return this.utilService.roundTo2DecimalPlaces(orderTotalPrice);
	}
}
