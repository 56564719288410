import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CovalentSearchModule } from '@covalent/core/search';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
	ResourceActionReturnType,
	ResourceGlobalConfig,
	ResourceHandler,
	ResourceQueryMappingMethod
} from '@ngx-resource/core';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SortablejsModule } from 'angular-sortablejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { FrameModule } from './frame/frame.module';
import { InjectorModule } from './injector.module';
import { MediaCenterModule } from './page/media/media-center/media-center.module';
import { PageModule } from './page/page.module';
import { RoutingModule } from './router/app-routing.module';
import { AuthGuard } from './service/guard/auth.guard';
import { PouchDbCentroutensiliAdapter } from './service/pouchdb/centroutensili/pouchdb-centroutensili-adapter.service';
import { PouchDbEllizerboniAdapter } from './service/pouchdb/centroutensili/pouchdb-ellizerboni-adapter.service';
import { PouchUtilService } from './service/pouchdb/pouch-util.service';
import { PouchdbModule } from './service/pouchdb/pouchdb.module';
import { ConfigurationService } from './service/structure/configuration.service';
import { EventHandlerService } from './service/util/event-handler.service';
import { ResourceUrlEncodedHandler } from './service/util/resource-urlencoded-handler.service';
import { ArticleListStateModule } from './state/article-list/article-list-state.module';
import { ArticleListEffects } from './state/article-list/article-list.effects';
import { AuxiliaryTableListStateModule } from './state/auxiliary_table/auxiliary_table-state.module';
import { AuxiliaryTableListEffects } from './state/auxiliary_table/auxiliary_table.effects';
import { CategoryListStateModule } from './state/category-list/category-list-state.module';
import { CategoryListEffects } from './state/category-list/category-list.effects';
import { CompanyListStateModule } from './state/company-list/company-list-state.module';
import { CompanyListEffects } from './state/company-list/company-list.effects';
import { CompanyStateModule } from './state/company/company-state.module';
import { CompanyEffects } from './state/company/company.effects';
import { DestinationListStateModule } from './state/destination-list/destination-list-state.module';
import { DestinationListEffects } from './state/destination-list/destination-list.effects';
import { FamilyListStateModule } from './state/family-list/family-list-state.module';
import { FamilyListEffects } from './state/family-list/family-list.effects';
import { OrderListStateModule } from './state/order-list/order-list-state.module';
import { OrderListEffects } from './state/order-list/order-list.effects';
import { OrderStateModule } from './state/order/order-state.module';
import { OrderDetailEffects } from './state/order/order.effects';
import { TagListStateModule } from './state/tag-list/tag-list-state.module';
import { TagListEffects } from './state/tag-list/tag-list.effects';
import { UserStateModule } from './state/user/user-state.module';
import { UserEffects } from './state/user/user.effects';
import { IframeResolverModule } from './ui/iframe-resolver/iframe-resolver.module';

// import { ConnectionAppService } from "./service/util/connection-app.service";
import { PouchDbNuiAdapter } from './service/pouchdb/centroutensili/pouchdb-nui-adapter.service';
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: false
};
export function loadConfiguration(config: ConfigurationService) {
	return () => config.load();
}

export function customResourceHandlerFactory(http: HttpClient) {
	return new ResourceUrlEncodedHandler(http);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RoutingModule,
		SortablejsModule,
		PerfectScrollbarModule,
		HttpClientModule,
		ResourceModule.forRoot({
			handler: { provide: ResourceHandler, useFactory: customResourceHandlerFactory, deps: [HttpClient] }
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		NgxWebstorageModule.forRoot(),
		// NgRx - Store
		StoreModule.forRoot({}),
		UserStateModule,
		CompanyListStateModule,
		CompanyStateModule,
		ArticleListStateModule,
		OrderStateModule,
		OrderListStateModule,
		CategoryListStateModule,
		AuxiliaryTableListStateModule,
		DestinationListStateModule,
		TagListStateModule,
		FamilyListStateModule,
		PouchdbModule.forRoot([PouchDbCentroutensiliAdapter, PouchDbEllizerboniAdapter, PouchDbNuiAdapter]),
		EffectsModule.forRoot([
			UserEffects,
			OrderListEffects,
			OrderDetailEffects,
			CompanyListEffects,
			CompanyEffects,
			ArticleListEffects,
			CategoryListEffects,
			AuxiliaryTableListEffects,
			DestinationListEffects,
			TagListEffects,
			FamilyListEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		// structure modules
		InjectorModule,
		FrameModule,
		PageModule,
		CovalentSearchModule,
		MediaCenterModule,
		IframeResolverModule,

		MatBadgeModule
	],
	providers: [
		AppConfig,
		ConfigurationService,
		PouchUtilService,
		EventHandlerService,
		DeviceDetectorService,
		MatIconRegistry,
		AuthGuard,
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: APP_INITIALIZER, deps: [ConfigurationService], useFactory: loadConfiguration, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
		ResourceGlobalConfig.queryMappingMethod = ResourceQueryMappingMethod.None;
	}
}
