import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import { NavigationService } from '../../service/navigation/navigation.service';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import { AuthService } from '../../service/rest/auth.service';
import { MediaReplayService } from '../../service/util/media-replay.service';
import * as fromState from '../../state';
import * as fromTagList from '../../state/tag-list/tag-list.actions';
import { UserState } from '../../state/user/user.reducer';

// store
@Component({
	selector: 'ms-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FramePageComponent implements OnInit, OnDestroy {
	// learn about `static` here: https://angular.io/guide/static-query-migration
	@ViewChild('sidenav', { static: false }) sidenav;

	private _mediaSubscription: Subscription;
	private _routerEventsSubscription: Subscription;

	sidenavOpen = false;
	sidenavMode = 'over';
	isMobile = false;

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	user: UserState = <UserState>{};

	constructor(
		private router: Router,
		private authService: AuthService,
		public mediaReplayService: MediaReplayService,
		public navigationService: NavigationService,
		private cdr: ChangeDetectorRef,
		private store: Store<any>,
		private pouchUtilService: PouchUtilService,
		public translateService: TranslateService,
		public snackBar: MatSnackBar
	) {
		// layout & mediaquery
		this.mediaReplayService.deviceH = document.body.clientHeight;
		this.mediaReplayService.deviceW = document.body.clientWidth;

		// temporanea forzatura della rotta che previene il disallineamento tra pagina mostrata
		// ed effettivo stato del router.config
		this.router.navigate(['/']);
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media$.subscribe((change: MediaChange) => {
			const isMobile = this.mediaReplayService.deviceW < 1281;
			this.isMobile = isMobile;
			this.sidenavOpen = !isMobile;
			this.sidenavMode = isMobile ? 'over' : 'side';
		});

		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd && this.isMobile) {
				this.sidenav.close();
			}
		});

		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			// TODO Scaricare solo le traduzioni
			this.store.dispatch(fromTagList.load());
		});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
