import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { SimpleListItemModel } from '../../../model/util.model';
import { UserService } from '../../../service/rest/user.service';

@Component({
	selector: 'client-code-select',
	templateUrl: './client-code-select.component.html',
	styleUrls: ['./client-code-select.component.scss']
})
export class ClientCodeSelectComponent implements OnInit {
	form: FormGroup;
	@LocalStorage('client_code') client_code: string;

	codes: SimpleListItemModel[] = [];

	constructor(private fb: FormBuilder, private router: Router, private userService: UserService) {
		this.createForm();
		this.getClientCode();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			client_code: ['', Validators.required]
		});
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const client_code = formModel.client_code as string;
		return client_code;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.client_code = this.prepareSaveForm();
			this.router.navigate(['/']);
		}
	}

	//rest
	getClientCode() {
		this.userService
			.getClientCode()
			.then(res => {
				/** temp fix for array result. It is ngx-resource fault */
				if (res && res.length) {
					res = Array.from(res);
				}
				this.codes = res;
			})
			.catch(err => {
				console.log(err);
			});
	}
}
