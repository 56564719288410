import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import * as fromCategoryist from '../category-list/category-list.actions';

// service

@Injectable()
export class CategoryListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Category List Context] Load'),
			mergeMap(action =>
				from(this.getCategoryListPouch()).pipe(
					map(company => fromCategoryist.update(company)),
					catchError(() => of({ type: '[Category List Context] Category List Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService) {}

	getCategoryListPouch(): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().categoriesPouch.getCategoryList();
	}
}
