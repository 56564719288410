import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDestinationState from './destination-list.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('destination-list', fromDestinationState.reducer)
  ],
})
export class DestinationListStateModule { }
