import { createReducer, on } from '@ngrx/store';
import * as fromUser from './user.actions';
import { User } from '../../model/user.model';
// model

export interface UserState extends User {}

export const initialState: UserState = null;

const _reducer = createReducer(initialState,
  on(fromUser.update, (state, user) => user),
  on(fromUser.reset, (state) => initialState)
);

export function reducer(state, action) {
  return _reducer(state, action);
}