import { Injectable } from '@angular/core';
import { ResourceAction, ResourceRequestMethod, IResourceMethod, IResourceMethodPromiseStrict } from '@ngx-resource/core';
import { BaseApiService } from '../baseApi.service';

@Injectable()
export class BOConfigurationService extends BaseApiService {

	@ResourceAction({
		path: '/file/catalog/upload',
		method: ResourceRequestMethod.Post
	})
	uploadCatalogTree: IResourceMethodPromiseStrict<string, {type: string, language: string}, void, any>;

}