import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class CompanyColumnService extends BaseTableListService {
    constructor() {
        super();
    }
    columns: ITdDataTableColumn[] = [
        {
            name: "ragione_sociale",
            label: "Azienda"
        },
        {
            name: "indirizzo",
            label: "Indirizzo",
        }
    ];
}
