import { Component, OnInit } from '@angular/core';
import { AppConfig } from "../../../app.config";
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'media-center',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.scss']
})
export class MediaCenterComponent implements OnInit {

  @LocalStorage('authenticationToken') authenticationToken: string;
  mediaCenterIframeUrl: any;

  constructor(public appConfig: AppConfig) {
    this.mediaCenterIframeUrl = `${this.appConfig.config.urlMediaCenter}/#/token/${this.authenticationToken}/0123456789/path/Home`;
   }

  ngOnInit() {
  }

}
