import {Injectable} from "@angular/core";
import {BaseApiService} from "./baseApi.service";
import {ResourceAction, ResourceRequestMethod, IResourceMethod, IResourceMethodPromiseStrict} from "@ngx-resource/core";

@Injectable()
export class NewOrderService extends BaseApiService {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/new-order'
  })
  newOrder: IResourceMethod<any, string>;

  @ResourceAction({
		path: '/file/new-order/upload',
		method: ResourceRequestMethod.Post
	})
	uploadNewOrder: IResourceMethodPromiseStrict<string, {client_code: string}, void, any>;
}