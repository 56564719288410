import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanyCodeEnum } from '../../../../../enum/company-code.enum';
import { AppConfig } from '../../../../../app.config';

import { ArticleStock } from '../../../../../enum/article-stock.enum';
import { ArticleModel } from '../../../../../model/article.model';

// Debounce

export interface ArticleNewQuantity {
	article: ArticleModel;
	quantity: number;
}

@Component({
	selector: 'dialog-catalog-family-list',
	templateUrl: './dialog-catalog-family-list.component.html',
	styleUrls: ['./dialog-catalog-family-list.component.scss']
})
export class DialogCatalogFamilyListComponent implements OnInit, OnDestroy {
	@Input() columns: ITdDataTableColumn[];
	@Input() articleList: ArticleModel[];
	@Output() articleNewQuantityEmitter = new EventEmitter<ArticleNewQuantity>();

	@ViewChild('dialogArticleDataTable', { static: false }) dataTable: TdDataTableComponent;

	articleStock = ArticleStock;

	// Debounce
	private newQuantityForArticleSubject = new Subject<ArticleNewQuantity>();
	updateRow$: Observable<ArticleNewQuantity> = this.newQuantityForArticleSubject.asObservable();

	constructor(private appConfig: AppConfig) {}

	ngOnInit() {
		this.updateRow$.pipe(debounceTime(10)).subscribe((articleNewQuantity: ArticleNewQuantity) => {
			this.articleNewQuantityEmitter.emit(articleNewQuantity);
			this.dataTable.refresh();
		});

		this.sortArticleList();
	}

	sortArticleList() {
		if (this.articleList) {
			switch (this.appConfig.config.company.code) {
				case CompanyCodeEnum.NUI:
					// article list sorted by code (ASC)
					this.articleList.sort((a, b) => (a.codice > b.codice ? 1 : -1));
					break;
			}
		}
	}

	changeQuantityForArticle(newQuantity: number, articleRow: ArticleModel) {
		newQuantity = this.checkValue(newQuantity, articleRow);
		const articleNewQuantity: ArticleNewQuantity = {
			quantity: newQuantity,
			article: articleRow
		};
		if (articleNewQuantity.quantity) {
			this.newQuantityForArticleSubject.next(articleNewQuantity);
		} else {
			articleNewQuantity.quantity = null;
			this.newQuantityForArticleSubject.next(articleNewQuantity);
		}
	}

	checkValue(quantity: number, row: ArticleModel) {
		if (!quantity || row.pezzi_confezione === 0) {
			return null;
		}
		return Math.ceil(quantity / row.pezzi_confezione) * row.pezzi_confezione;
	}

	ngOnDestroy() {
		this.newQuantityForArticleSubject.unsubscribe();
	}
}
