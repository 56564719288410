import { DestinationListState } from './destination-list.reducer';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as fromDestinationList from './destination-list.actions';
import { DestinationPouchResponseModel } from '../../model/pouch/destination-pouch.model';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';

@Injectable()
export class DestinationListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Destination List Context] Load'),
			mergeMap(action =>
				from(this.getDestinationList(action)).pipe(
					map((destinationList: DestinationListState) => {
						return fromDestinationList.update(destinationList);
					}),
					catchError(() => of({ type: '[Destionation List Context] Destination List Load Error' }))
				)
			)
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Destination Context] Save'),
			mergeMap(data =>
				from(this.postDestinationPouch(data)).pipe(
					map((destinationList: DestinationListState) => {
						return fromDestinationList.update(destinationList);
					}),
					catchError(() => of({ type: '[Destination] Destination Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService) {}

	getDestinationList(data): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().destinationListPouch.getDestinationList(data.codice_cliente);
	}

	postDestinationPouch(data: DestinationPouchResponseModel) {
		data.type = 'destination';
		return this.pouchUtilService.getActiveAdapter().destinationListPouch.putDestinationList(data);
	}
}
