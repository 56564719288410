import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TableColumnToggleComponent } from './table-column-toggle/table-column-toggle.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';

// Dialog
import { DialogConfirmComponent } from './dialog/dialog-confirm/dialog-confirm.component';
import { DialogTextEditComponent } from './dialog/dialog-text-edit/dialog-text-edit.component';
import { DialogOtherDestinationComponent } from './dialog/dialog-other-destination/dialog-other-destination.component';

import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { CardTrendComponent } from './card-trend/card-trend.component';
import { CardChartConfigurableComponent } from './card-chart-configurable/card-chart-configurable.component';
import { ApexchartWrapperComponent } from './apexchart-wrapper/apexchart-wrapper.component';
import { AttachmentResolverComponent } from './attachment-resolver/attachment-resolver.component';
import { DropAreaFileComponent } from './drop-area-file/drop-area-file.component';
import { TabWrapperComponent } from './tab-wrapper/tab-wrapper.component';
import { NestedCategoryListComponent } from './nested-category-list/nested-category-list.component';
import { InputTypeSelectableComponent } from './input-type-selectable/input-type-selectable.component';
import { MatAccordionWrapperComponent } from './mat-accordion-wrapper/mat-accordion-wrapper.component';
import { MatCardHighlightValueComponent } from './mat-card-highlight-value/mat-card-highlight-value.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { NestedTagObjectComponent } from './nested-tag-object/nested-tag-object.component';
import { PouchPagingBarComponent } from './pouch-paging-bar/pouch-paging-bar.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { FormArrayUserComponent } from './form/form-array-user/form-array-user.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';

@NgModule({
	imports: [SharedModule],
	declarations: [
		TableColumnToggleComponent,
		AutocompleteComponent,
		FloatingMenuComponent,
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogOtherDestinationComponent,
		ListWrapperComponent,
		StatusBarComponent,
		CardTrendComponent,
		CardChartConfigurableComponent,
		ApexchartWrapperComponent,
		AttachmentResolverComponent,
		DropAreaFileComponent,
		TabWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		MatAccordionWrapperComponent,
		MatCardHighlightValueComponent,
		BreadcrumbComponent,
		PersonCardComponent,
		NestedTagObjectComponent,
		PouchPagingBarComponent,
		MatSnackBarWrapperComponent,
		FormArrayUserComponent,
		RecaptchaComponent
	],
	exports: [
		TableColumnToggleComponent,
		AutocompleteComponent,
		FloatingMenuComponent,
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogOtherDestinationComponent,
		ListWrapperComponent,
		StatusBarComponent,
		CardTrendComponent,
		CardChartConfigurableComponent,
		ApexchartWrapperComponent,
		AttachmentResolverComponent,
		DropAreaFileComponent,
		TabWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		MatAccordionWrapperComponent,
		MatCardHighlightValueComponent,
		BreadcrumbComponent,
		PersonCardComponent,
		NestedTagObjectComponent,
		PouchPagingBarComponent,
		FormArrayUserComponent,
		RecaptchaComponent
	],
	entryComponents: [
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogOtherDestinationComponent,
		MatSnackBarWrapperComponent
	]
})
export class WidgetModule {}
