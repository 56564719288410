import { Injectable } from '@angular/core';
import { User } from '../../model/user.model';
import { UserState } from '../../state/user/user.reducer';

@Injectable({
	providedIn: 'root'
})
export class UtilService {
	replaceAll(valueToTest: string, regExp: RegExp, replacer: string = '') {
		if (!valueToTest) {
			return;
		}
		if (replacer) {
			replacer = '';
		}

		let res: string = valueToTest;

		while (regExp.test(res)) {
			res = res.replace(regExp, replacer);
		}

		return res.trim();
	}

	getEnumValue(index: number, enumType) {
		const enumValueArray = Object.keys(enumType).map(enumKey => enumType[enumKey]);
		if (index > enumValueArray.length - 1) {
			return undefined;
		}
		const val = enumValueArray[index];
		return val;
	}

	getElementIndex(array: any[], property: string, value: string) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][property] == value) {
				return i;
			}
		}
	}

	/**
	 *
	 * Permette la selezione delle options di una mat-select nel caso in cui venga bindato nel mat-option [value] un oggetto
	 * e non un semplice ID. Le condizioni dell'if dovrebbero diventare parametriche.
	 *
	 * @param options array che genera le options
	 * @param values valori da selezionare derivanti ad esempio da una response
	 */
	compareObjectForMatSelect(options, values) {
		let a = [];
		options.forEach(o => {
			values.forEach(v => {
				if (
					o.organization === v.organization &&
					o.channel === v.channel &&
					o.group_customer === v.group_customer
				) {
					a.push(o);
				}
			});
		});
		return a;
	}

	checkUserPermission(user: User, permission: string) {
		let hasPermission: boolean;
		if (permission) {
			if (user && user.permission) {
				for (let i = 0; i < user.permission.length; i++) {
					if (user.permission[i].key === permission) {
						hasPermission = true;
						break;
					} else {
						hasPermission = false;
					}
				}
			} else {
				hasPermission = false;
			}
		} else {
			hasPermission = true;
		}
		return hasPermission;
	}

	hasAtLeastOnePermission(user: UserState, permission: string[]) {
		if (user && user.permission) {
			for (let i = 0; i < permission.length; i++) {
				if (user.permission.find(p => p.key === permission[i])) {
					return true;
				}
			}
		}
		return false;
	}

		roundTo2DecimalPlaces(number: number) {
		return Math.round((number + Number.EPSILON) * 100) / 100;
	}
}
