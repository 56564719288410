import { createReducer, on } from '@ngrx/store';

import { OrderPouchModel } from '../../model/pouch/order-pouch.model';
import * as fromOrder from './order.actions';

// model
export interface OrderState extends OrderPouchModel {}

export const initialState: OrderState = null;

const _reducer = createReducer(
	initialState,
	on(fromOrder.save, (state, order) => state),
	on(fromOrder.update, (state, order) => order),
	on(fromOrder.load, state => state),
	on(fromOrder.reset, state => initialState),
	on(fromOrder.remove, order => order)
);

export function reducer(state, action) {
	return _reducer(state, action);
}
