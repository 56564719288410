import { OrderPouchModel } from '../../../../model/pouch/order-pouch.model';
import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';
import { Pagination } from '../../../../model/structure/list-structure.model';

export class OrderPouch extends AbstractPouchdbMethod {
	putOrder(orderModel: OrderPouchModel, isGuid: boolean): Promise<any> {
		const guid = isGuid ? 'order' : orderModel._id;
		return new Promise(resolve => {
			this.put<OrderPouchModel>(orderModel, guid, isGuid)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getOrderList(pagination?: Pagination, filter?: any, sort?: string[]): Promise<any> {
		const exists = {
			$exists: true
		};
		return new Promise(resolve => {
			const params = {
				$and: [
					{
						type: 'order'
					}
				]
			} as PouchDB.Find.Selector;

			if (filter) {
				params.$and.push({ 'header.client_code': filter.client_code ? filter.client_code : exists });
				params.$and.push({
					'header.order_causal':
						filter.order_causal && filter.order_causal.length > 0 ? { $in: filter.order_causal } : exists
				});
				params.$and.push({
					'header.status':
						filter.order_states && filter.order_states.length > 0 ? { $in: filter.order_states } : exists
				});
				params.$and.push({
					'header.date': filter.date && filter.date.length > 0 ? { $gte: filter.date[0] } : exists
				});
				if (filter.date && filter.date.length > 1) {
					params.$and.push({
						'header.date': { $lte: filter.date[1] }
					});
				}
				params.$and.push({
					'header.first_evasion_date':
						filter.due_date && filter.due_date.length > 0 ? { $gte: filter.due_date[0] } : exists
				});
				if (filter.due_date && filter.due_date.length > 1) {
					params.$and.push({
						'header.first_evasion_date': { $lte: filter.due_date[1] }
					});
				}
				if (filter.date) {
					params.$and.push({
						'header.date': filter.date && filter.date.length > 0 ? { $gte: filter.date[0] } : exists
					});
					if (filter.date && filter.date.length > 1) {
						params.$and.push({
							'header.date': { $lte: filter.date[1] }
						});
					}
				}
				if (filter.due_date) {
					params.$and.push({
						'header.first_evasion_date':
							filter.due_date && filter.due_date.length > 0 ? { $gte: filter.due_date[0] } : exists
					});
					if (filter.due_date && filter.due_date.length > 1) {
						params.$and.push({
							'header.first_evasion_date': { $lte: filter.due_date[1] }
						});
					}
				}
			}
			this.getWithIndexFilter('order-list', params, pagination, sort)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getOrderDetail(order_id: string): Promise<any> {
		return new Promise(resolve => {
			this.get(order_id)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	deleteOrderDetail(order: any): Promise<any> {
		console.log(order);
		return new Promise(resolve => {
			this.delete(order.order)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
