import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import * as fromState from '../../../../../state';
import { TagListPouchModel, TagPouchModel } from './../../../../../model/pouch/tag.model';
import { SubscribeManagerService } from './../../../../../service/util/subscribe-manager.service';
import { FamilyCatalogFilters } from '../catalog-family.component';

@Component({
	selector: 'catalog-family-filter',
	templateUrl: './catalog-family-filter.component.html',
	styleUrls: ['./catalog-family-filter.component.scss'],
	providers: [SubscribeManagerService]
})
export class CatalogFamilyFilterComponent implements OnInit, OnDestroy {
	tagListData$: Observable<TagListPouchModel>;
	tagGraph: TagPouchModel[] = [];

	familyIdsToLoad: string[] = [];

	resetEventsSubject: Subject<boolean> = new Subject<boolean>();

	@Output() familyFiltersEmitter = new EventEmitter<FamilyCatalogFilters>();

	familyFilters: FamilyCatalogFilters = {
		textField: '',
		familyCodes: []
	};

	constructor(private store: Store<any>, private subscribeManagerService: SubscribeManagerService) {
		this.tagListData$ = this.store.select(fromState.tagListState);
	}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.tagListData$.subscribe(res => {
				this.tagGraph = res.tag_graph_list;
			}),
			'catalog-family-filter-tag-list'
		);
	}

	onTextFilterChange(inputText: string) {
		this.familyFilters.textField = inputText;
		this.emitFilters();
	}

	onTagsFormSubmit() {
		this.familyFilters.familyCodes = this.familyIdsToLoad;
		this.emitFilters();
	}

	private emitFilters() {
		this.familyFiltersEmitter.emit(this.familyFilters);
	}

	selectedTagsChange(selectedTags: TagPouchModel[]) {
		let familyIds: string[] = [];
		selectedTags.forEach(tag => {
			if (familyIds.length === 0) {
				familyIds = tag.family;
			} else {
				const arrayIntersection = familyIds.filter(familyId => tag.family.includes(familyId));
				const noDuplicatesArrayIntersection = [...new Set(arrayIntersection)];
				familyIds = noDuplicatesArrayIntersection;
			}
		});
		this.familyIdsToLoad = familyIds;
		this.onTagsFormSubmit();
	}

	resetSelection() {
		this.resetEventsSubject.next(true);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
