import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Ng5SliderModule } from 'ng5-slider';

import { AccessRequestService } from '../service/rest/accessRequest.service';
import { AuthService } from '../service/rest/auth.service';
import { CompanyService } from '../service/rest/company.service';
import { OrderService } from '../service/rest/order.service';
import { NewOrderService } from '../service/rest/newOrder.service';
import { SummaryService } from '../service/rest/summary.service';
import { UserService } from '../service/rest/user.service';
import { AgentOrderColumnService } from '../service/td-data-table/implementation/agent-order.service';
import { CompanyDashboardColumnService } from '../service/td-data-table/implementation/company-dashboard.service';
import { CompanyOrderColumnService } from '../service/td-data-table/implementation/company-order.service';
import { CompanyColumnService } from '../service/td-data-table/implementation/company.service';
import { OrderDashboardColumnService } from '../service/td-data-table/implementation/order-dashboard.service';
import { ProductCatalogColumnService } from '../service/td-data-table/implementation/product-catalog.service';
import { ProductCheckoutColumnService } from '../service/td-data-table/implementation/product-checkout.service';
import { SummaryTableColumnService } from '../service/td-data-table/implementation/summary.service';
import { DateMomentService } from '../service/util/date-moment.service';
import { PageDataService } from '../service/util/page-data.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { AccessRequestComponent } from './authentication/access-request/access-request.component';
import { AuthenticationWrapperComponent } from './authentication/authentication-wrapper/authentication-wrapper.component';
import { ClientCodeSelectComponent } from './authentication/client-code-select/client-code-select.component';
import { LoginComponent } from './authentication/login/login.component';
import { PasswordRecoveryComponent } from './authentication/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './authentication/password-update/password-update.component';
import { BackofficeAgentDetailUsersComponent } from './backoffice/agent/agent-detail/agent-detail-users/agent-detail-users.component';
import { BackofficeAgentDetailComponent } from './backoffice/agent/agent-detail/agent-detail.component';
import { BackofficeAgentComponent } from './backoffice/agent/agent.component';
import { BackofficeCompanyDetailUsersComponent } from './backoffice/company/company-detail/company-detail-users/company-detail-users.component';
import { BackofficeCompanyDetailComponent } from './backoffice/company/company-detail/company-detail.component';
import { BackofficeCompanyComponent } from './backoffice/company/company.component';
import { CatalogFamilyListComponent } from './common/catalog/catalog-family/catalog-family-list/catalog-family-list.component';
import { CompanyCreditComponent } from './company/company-credit/company-credit.component';
import { CompanyDestinationComponent } from './company/company-destination/company-destination.component';
import { CompanyFullDetailComponent } from './company/company-full-detail/company-full-detail.component';
import { CompanyOverviewComponent } from './company/company-overview/company-overview.component';
import { CompanyComponent } from './company/company.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MediaCenterComponent } from './media/media-center/media-center.component';
import { OrderDetailCatalogComponent } from './order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from './order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { OrderComponent } from './order/order.component';
import { PageCatalogComponent } from './catalog/page-catalog.component';
import { PublicWrapperComponent } from './public/public-wrapper/public-wrapper.component';
import { SummaryPageComponent } from './summary-page/summary-page.component';
import { UserComponent } from './user/user.component';
import { CatalogFamilyFilterComponent } from './common/catalog/catalog-family/catalog-family-filter/catalog-family-filter.component';
import { CatalogFamilyComponent } from './common/catalog/catalog-family/catalog-family.component';
import { ArticleOrderCatalogColumnService } from '../service/td-data-table/implementation/article-order-catalog.service';
import { ArticleCatalogColumnService } from '../service/td-data-table/implementation/article-catalog.service';
import { ArticlePublicCatalogColumnService } from '../service/td-data-table/implementation/article-public-catalog.service';
import { DialogCatalogFamilyHeaderComponent } from './common/catalog/dialog-catalog-family/dialog-catalog-family-header/dialog-catalog-family-header.component';
import { DialogCatalogFamilyListComponent } from './common/catalog/dialog-catalog-family/dialog-catalog-family-list/dialog-catalog-family-list.component';
import { DialogCatalogFamilyComponent } from './common/catalog/dialog-catalog-family/dialog-catalog-family.component';
import { OrderImportExcelComponent } from './order/order-import-excel/order-import-excel.component';
import { DataConfigurationComponent } from './backoffice/data-configuration/data-configuration.component';
import { BOConfigurationService } from '../service/rest/backoffice/BOconfiguration.service';
import { BackofficeDashboardComponent } from './backoffice/backoffice-dashboard/backoffice-dashboard.component';
import { BackofficeOrderColumnService } from '../service/td-data-table/implementation/backoffice-order.service';

// Page-catalog
@NgModule({
	imports: [WidgetModule, SharedModule, Ng5SliderModule],
	declarations: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		SummaryPageComponent,
		AuthenticationWrapperComponent,
		ClientCodeSelectComponent,
		UserComponent,
		DashboardComponent,
		CompanyComponent,
		CompanyOverviewComponent,
		CompanyCreditComponent,
		CompanyDestinationComponent,
		CompanyFullDetailComponent,
		OrderComponent,
		OrderDetailComponent,
		OrderDetailCheckoutComponent,
		OrderDetailCatalogComponent,
		BackofficeCompanyComponent,
		BackofficeCompanyDetailComponent,
		BackofficeCompanyDetailUsersComponent,
		BackofficeAgentComponent,
		BackofficeAgentDetailComponent,
		BackofficeAgentDetailUsersComponent,
		AccessRequestComponent,
		PageCatalogComponent,
		PublicWrapperComponent,
		CatalogFamilyListComponent,
		CatalogFamilyFilterComponent,
		CatalogFamilyComponent,
		DialogCatalogFamilyComponent,
		DialogCatalogFamilyHeaderComponent,
		DialogCatalogFamilyListComponent,
		BackofficeDashboardComponent,
		OrderImportExcelComponent,
		DataConfigurationComponent
	],
	providers: [
		AuthService,
		PageDataService,
		UserService,
		SummaryService,
		AgentOrderColumnService,
		BackofficeOrderColumnService,
		// Company
		CompanyService,
		CompanyOrderColumnService,
		CompanyDashboardColumnService,
		CompanyColumnService,
		// Order
		OrderService,
		OrderDashboardColumnService,
		NewOrderService,
		SummaryTableColumnService,
		DateMomentService,
		ProductCatalogColumnService,
		ProductCheckoutColumnService,
		ArticleOrderCatalogColumnService,
		ArticleCatalogColumnService,
		ArticlePublicCatalogColumnService,
		// Backoffice
		BOConfigurationService,
		// Access Request
		AccessRequestService
	],
	entryComponents: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		SummaryPageComponent,
		AuthenticationWrapperComponent,
		ClientCodeSelectComponent,
		UserComponent,
		DashboardComponent,
		CompanyComponent,
		CompanyOverviewComponent,
		CompanyCreditComponent,
		CompanyDestinationComponent,
		CompanyFullDetailComponent,
		OrderComponent,
		OrderDetailComponent,
		OrderDetailCheckoutComponent,
		OrderDetailCatalogComponent,
		BackofficeCompanyComponent,
		BackofficeCompanyDetailComponent,
		BackofficeCompanyDetailUsersComponent,
		BackofficeAgentComponent,
		BackofficeAgentDetailComponent,
		BackofficeAgentDetailUsersComponent,
		AccessRequestComponent,
		PageCatalogComponent,
		MediaCenterComponent,
		CatalogFamilyListComponent,
		CatalogFamilyFilterComponent,
		CatalogFamilyComponent,
		DialogCatalogFamilyComponent,
		DialogCatalogFamilyHeaderComponent,
		DialogCatalogFamilyListComponent,
		BackofficeDashboardComponent,
		OrderImportExcelComponent,
		DataConfigurationComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
