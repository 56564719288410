import { filter } from 'rxjs/operators';
import { ProductListModel } from './../../model/product.model';
import { Component, OnInit, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Routes } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { ProductPouchModel, OrderPouchModel } from './../../model/pouch/order-pouch.model';
import { Store } from '@ngrx/store';

import * as fromState from './../../state/index';
import * as b2bRouteList from '../../router/b2b-routing';

// Mat accordion service
import { tabCatalogService } from './../../service/tabCatalog/tabCatalogService.service';

export interface TabWrapperDataBadge {
	route: string;
	data: number;
}

@Component({
	selector: 'tab-wrapper',
	templateUrl: './tab-wrapper.component.html',
	styleUrls: ['./tab-wrapper.component.scss']
})
export class TabWrapperComponent implements OnInit {
	// @Input() dataBadge: TabWrapperDataBadge[] = [];
	@Input() dataBadge: TabWrapperDataBadge;
	@Input() orderState: string;
	routes: Routes = [];

	constructor(
		private route: ActivatedRoute,
		private store: Store<any>,
		private tabCatalogService: tabCatalogService
	) {}

	ngOnInit() {
	}

	/**
	 * Filtra le routes figlie escludendo i redirect, necessari per forzare il rendering di almeno una delle routes figlie
	 *
	 * @param array (ActivatedRoute) route.routeConfig.children
	 */
	getNotEmptyRoutes(array: Routes) {
		return array.filter(i => !i.redirectTo);
	}

	getRoutes() {
		for (let i = 0; i < b2bRouteList.routes.length; i++) {
			if (b2bRouteList.routes[i]['path'] == 'orders') {
				for (let j = 0; j < b2bRouteList.routes[i].children.length; j++) {
					if ('children' in b2bRouteList.routes[i].children[j]) {
						this.routes = b2bRouteList.routes[i].children[j].children;
						// console.log(this.orderState);
						if (this.orderState != 'draft') {
							this.routes = this.routes.filter(e => e.path !== 'catalogo');
						}
						return this.getNotEmptyRoutes(this.routes);
					}
				}
			}
		}
	}

}
