import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// model
import { Pagination } from '../../model/structure/list-structure.model';
import { ListStructureModel } from '../../model/structure/list-structure.model';

enum PaginationActionType {
	FIRST = 'FIRST',
	PREVIOUS = 'PREVIOUS',
	NEXT = 'NEXT',
	LAST = 'LAST'
}

@Component({
	selector: 'pouch-paging-bar',
	templateUrl: './pouch-paging-bar.component.html',
	styleUrls: ['./pouch-paging-bar.component.scss']
})
export class PouchPagingBarComponent implements OnInit {
	// @Input() pagination: Pagination;

	@Input() listStructure: ListStructureModel<any>;

	@Output() change = new EventEmitter<Pagination>();

	constructor() {}

	ngOnInit() {}

	changePageSize(size: number) {
		this.listStructure.pagination.page_size = size;
		this.changePageNumber(PaginationActionType.FIRST);
		this.change.emit(this.listStructure.pagination);
	}

	changePageNumber(actionType: string) {
		switch (actionType) {
			case PaginationActionType.FIRST:
				this.listStructure.pagination.page_current = 1;
				break;
			case PaginationActionType.PREVIOUS:
				this.listStructure.pagination.page_current--;
				break;
			case PaginationActionType.NEXT:
				this.listStructure.pagination.page_current++;
				break;
			case PaginationActionType.LAST:
				this.listStructure.pagination.page_current = -1;
				break;
		}
		// al momento non è possibile navigare fino all'ultima pagina, manca il total count
		this.change.emit(this.listStructure.pagination);
	}
}
