import { createAction, props } from '@ngrx/store';
// model
import { CompanyState } from './company.reducer';

export const update = createAction(
  '[Company Context] Update',
  props<CompanyState>()
);
export const load = createAction(
  '[Company Context] Load',
  props<{code: string}>()
);
export const reset = createAction(
  '[Company Context] Reset'
);
export const saveUsers = createAction(
  '[Company Context] Save Users',
  props<CompanyState>()
);