import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
// model
import { User } from '../../../model/user.model';
// widget & utility
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormValidatorCustom } from '../../../shared/misc/form-validator';

@Component({
	selector: 'form-array-user',
	templateUrl: './form-array-user.component.html',
	styleUrls: ['./form-array-user.component.scss']
})
export class FormArrayUserComponent implements OnInit {

	form: FormGroup;
	_userList: User[] = [];

	@Input() multiple: boolean;

	@Input() set userList(userList: User[]) {
		if (userList && userList.length > 0) {
			this._userList = userList;
			this.setValueFromModel();
		}
	}

	@Output() onUserListChange: EventEmitter<User[]> = new EventEmitter();

	constructor(
		private fb: FormBuilder,
		private snackBar: MatSnackBar
	) {
		this.createForm();
		this.addNewUser();
	}

	ngOnInit() {}

	// form

	createForm() {
		this.form = this.fb.group({
			users: this.fb.array([])
		});
	}

	setValueFromModel() {
		if (this.users.length > 0) {
			while (this.users.length !== 0) {
				this.users.removeAt(0);
			}
		}
		this._userList.forEach(i => {
			this.users.push(this.fb.group({
				id: i.id,
				is_active: i.is_active,
				username: [i.username, [Validators.required, FormValidatorCustom.email]],
				client_code: i.client_code,
				permission: i.permission,
				avatar: i.avatar,
				first_name: i.first_name,
				last_name: i.last_name
			}));
		});
	}

	get users() {
		return this.form.get('users') as FormArray;
	}

	addNewUser() {
		this.users.push(this.fb.group({
			id: null,
			is_active: false,
			username: ['', [Validators.required, FormValidatorCustom.email]],
			client_code: null,
			permission: [[]],
			avatar: null,
			first_name: null,
			last_name: null,
		}));
	}

	removeUser(i) {
		if (this.users.controls.length === 1 ) {
			this.snackBar.open(
				"Eliminando tutti gli Users la Company non sarà più disponibile in elenco",
				"HO CAPITO",
				{
					duration: null
				}
			);
		}
		this.users.removeAt(i);
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm: User[] = [];
		(formModel.users as User[]).forEach(i => {
			const user: User = i;
			user.username = i.username.toLocaleLowerCase();
			saveForm.push(user);
		});
		return saveForm;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this._userList = this.prepareSaveForm();
			this.onUserListChange.emit(this._userList);
		}
	}
}
