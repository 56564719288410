import { NavigationService } from '../../service/navigation/navigation.service';
import { Directive, HostBinding, HostListener, OnInit, OnDestroy } from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { MediaReplayService } from '../../service/util/media-replay.service';
import { NavItem } from '../../model/nav-item.model';

@Directive({
	selector: '[msIconSidenav]'
})
export class IconSidenavDirective implements OnInit, OnDestroy {
	private _mediaSubscription: Subscription;
	isMobile = false;

	@HostBinding('class.icon-sidenav')
	get isIconSidenav(): boolean {
		if (this.navigationService.isIconSidenav !== undefined) {
			this.navigationService.isIconSidenav ? this.closeSideBar() : this.openSideBar();
		}
		return this.navigationService.isIconSidenav;
	}

	@HostBinding('class.collapsed')
	collapsed: boolean;

	currentlyOpen: NavItem[];

	@HostListener('mouseenter')
	onMouseEnter() {
		if (this.isIconSidenav && !this.isMobile) {
			this.collapsed = false;
			this.navigationService.nextCurrentlyOpen(this.navigationService.currentlyOpen);
		}
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.isIconSidenav && !this.isMobile) {
			this.collapsed = true;
			this.currentlyOpen = this.navigationService.currentlyOpen;
			this.navigationService.nextCurrentlyOpen([]);
		}
	}

	constructor(private navigationService: NavigationService, private mediaReplayService: MediaReplayService) {}

	ngOnInit() {
		this._mediaSubscription = this.mediaReplayService.media$.subscribe((change: MediaChange) => {
			this.isMobile = change.mqAlias == 'xs' || change.mqAlias == 'sm';
		});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}

	closeSideBar() {
		this.collapsed = true;
		this.currentlyOpen = this.navigationService.currentlyOpen;
		this.navigationService.nextCurrentlyOpen([]);
	}

	openSideBar() {
		this.collapsed = false;
		this.navigationService.nextCurrentlyOpen(this.navigationService.currentlyOpen);
	}
}
