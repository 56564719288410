import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AuxiliaryTableListPouchModel } from '../../model/pouch/auxiliary-table.model';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import * as fromAuxiliaryTableList from '../auxiliary_table/auxiliary_table.actions';

// service
@Injectable()
export class AuxiliaryTableListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Auxiliary Table List Context] Load'),
			mergeMap(action =>
				from(this.getAuxiliaryTableListPouch()).pipe(
					map(tables => {
						return fromAuxiliaryTableList.update(tables);
					}),
					catchError(() => of({ type: '[Auxiliary Table List] Auxiliary Table List Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService) {}

	async getAuxiliaryTableListPouch(): Promise<any> {
		const auxiliaryTable: AuxiliaryTableListPouchModel = <AuxiliaryTableListPouchModel>{};
		const promises = [];
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getModalitaDiConsegna()
				.then(res => {
					auxiliaryTable.modalita_di_consegna = res.values;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getZoneCommerciali()
				.then(res => {
					auxiliaryTable.zone_commerciali = res.values;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getCausaliTestateOdv()
				.then(res => {
					auxiliaryTable.causali_testate_odv = res.values;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getModalitaDiSpedizione()
				.then(res => {
					auxiliaryTable.modalita_di_spedizione = res.values;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getPagamenti()
				.then(res => {
					auxiliaryTable.pagamenti = res.values;
				})
		);
		promises.push(
			this.pouchUtilService
				.getActiveAdapter()
				.tabelleAusiliariPouch.getCausaliSospensioneOrdini()
				.then(res => {
					auxiliaryTable.causali_sospensione_ordini = res.values;
				})
		);
		const res_5 = await Promise.all(promises);
		return auxiliaryTable;
	}
}
