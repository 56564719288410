import { AbstractPouchdbMethod } from '../structure/abstract-pouchdb-method';

export class OrderChart extends AbstractPouchdbMethod {
	activeDb: PouchDB.Database;
	constructor(activeDB: PouchDB.Database) {
		super(activeDB);
		this.activeDb = activeDB;
	}

	findOrdersByDates(from: number, to: number): Promise<any> {
		const idx = 'orderViews/order-index';
		const startkey = from;
		const endkey = to;
		return this._db
			.query(idx, { startkey: startkey, endkey: endkey })
			.then(res => {
				// console.log("findOrdersByClientCodeAndDates: ", res);
				return res;
			})
			.catch(err => {
				return err;
			});
	}

	findOrdersByClientCodeAndDates(clientCode: string, from: number, to: number): Promise<any> {
		const idx = 'orderViews/date-client-order-index';
		const startkey = [clientCode, from];
		const endkey = [clientCode + 'ufff0', to];
		return this._db
			.query(idx, { startkey: startkey, endkey: endkey })
			.then(res => {
				// console.log("findOrdersByClientCodeAndDates: ", res);
				return res;
			})
			.catch(err => {
				return err;
			});
	}
}
