export const environment = {
	production: false,
	test: false,
	test_centroutensili: true,
	prod_centroutensili: false,
	test_ellizerboni: false,
	prod_ellizerboni: false,
	local: false,
	test_nui: false,
	prod_nui: false,
	mockup: false
};
