import { FilterDateRange } from "../filter.model";

export abstract class AbstractListModel<D> {
  pagination?: Pagination;
  filters?: Filters
  data: D;
}

export class Pagination {
  page_current: number;
  page_size: number;
  total_element?: number
}

export class Filters {
  text?: string;
  types?: string[];
  order_states?: string[];
  company_states?: string[];
  date_creation?: FilterDateRange;
  date_delivery?: FilterDateRange;
  status?: string;
  amount?: AmountModel;
}

export class AmountModel {
  minValue?: number;
	maxValue?: number;
}
