import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfig } from '../../../../../app.config';

import { FamilyPouchModel } from '../../../../../model/pouch/family-pouch.model';
import { ProductCatalogColumnService } from '../../../../../service/td-data-table/implementation/product-catalog.service';
import { UtilCatalogService } from '../../../../../service/util/util-catalog-filter.service';
import { MediaReplayService } from './../../../../../service/util/media-replay.service';
import { SortGroup } from './../catalog-family.component';

@Component({
	selector: 'catalog-family-list',
	templateUrl: './catalog-family-list.component.html',
	styleUrls: ['./catalog-family-list.component.scss']
})
export class CatalogFamilyListComponent implements OnInit {
	@Input() familySortedbyGroup: SortGroup[];

	@Output() articleFamilyRowEmitter = new EventEmitter<FamilyPouchModel>();

	constructor(
		private mediaReplayService: MediaReplayService,
		private utilCatalogService: UtilCatalogService,
		private productCatalogColumnService: ProductCatalogColumnService,
		private appConfig: AppConfig
	) {}

	ngOnInit() {}

	getTableColumns() {
		return this.productCatalogColumnService.columns;
	}

	getImagePathByFamilyGroup(groupName: string) {
		let company = this.appConfig.config.company;
		return this.utilCatalogService.getFamilyGroupImagePath(company, groupName);
	}

	// Emit output
	onClickFamily(row: FamilyPouchModel) {
		this.articleFamilyRowEmitter.emit(row);
	}

	getDeviceHeight() {
		return this.mediaReplayService.deviceH;
	}
}
