import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, skipWhile, map } from 'rxjs/operators';
import { UtilAddressService } from '../../../service/util/util-address.service';

import { PaymentModel, PropertyModel } from '../../../model/pouch/auxiliary-table.model';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import * as fromState from '../../../state';
import { CompanyState } from '../../../state/company/company.reducer';
import * as fromDestinationList from '../../../state/destination-list/destination-list.actions';
import { DestinationListState } from '../../../state/destination-list/destination-list.reducer';
import { DestinationPouchModel } from '../../../model/pouch/destination-pouch.model';

// Service
// Model
@Component({
	selector: 'company-full-detail',
	templateUrl: './company-full-detail.component.html',
	styleUrls: ['./company-full-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class CompanyFullDetailComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@ViewChild('dataTableDestinations', { static: false }) dataTableDestinations: TdDataTableComponent;

	// columnsContacts: ITdDataTableColumn[] = [
	//   {
	//     name: "description",
	//     label: "Nominativo"
	//   },
	//   {
	//     name: "email",
	//     label: "Email"
	//   },
	//   {
	//     name: "phone",
	//     label: "Telefono"
	//   },
	// ];

	columnsPayment: ITdDataTableColumn[] = [
		{
			name: 'descrizione',
			label: 'Descrizione'
		}
	];
	columnsDestinations: ITdDataTableColumn[] = [
		{
			name: 'ragione_sociale',
			label: 'Ragione sociale'
		},
		{
			name: 'indirizzo.indirizzo',
			label: 'Indirizzo'
		},
		{
			name: 'indirizzo.localita',
			label: 'Città'
		},
		{
			name: 'indirizzo.provincia',
			label: 'Provincia',
			width: 100
		},
		{
			name: 'indirizzo.nazione',
			label: 'Nazione',
			width: 100
		}
	];

	form: FormGroup;
	id: string;
	text_search: string;

	// State
	companyData$: Observable<CompanyState>;
	company: CompanyState;
	destinationListData$: Observable<DestinationListState>;
	destinationResponse: DestinationListState;
	paymentListData$: Observable<PaymentModel[]>;
	paymentList: PaymentModel[];
	causaliSospensioniListData$: Observable<PropertyModel[]>;
	causaliSospensioniList: PropertyModel[];

	constructor(
		private fb: FormBuilder,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilAddressService: UtilAddressService
	) {
		this.createForm();
		this.companyData$ = this.store.select(fromState.getCompanyState);
		this.destinationListData$ = this.store.select(fromState.getDestinationListState);
		this.paymentListData$ = this.store.select(fromState.getAuxiliaryPagamenti);
		this.causaliSospensioniListData$ = this.store.select(fromState.getAuxiliaryCausaliSospensioneOrdini);

		this.subscribeManagerService.populate(this.initMandatoryData().subscribe(res => console.log(4)), 'init');
		this.form.disable();
	}

	initMandatoryData() {
		return this.companyData$.pipe(
			skipWhile((company: CompanyState) => !(company && company.codice)),
			mergeMap((company: CompanyState) => {
				this.company = company;
				this.store.dispatch(fromDestinationList.load({ codice_cliente: this.company.codice }));
				return this.destinationListData$;
			}),
			skipWhile(
				(destionations: DestinationListState) =>
					!(destionations && destionations.values && destionations.values.length > 0)
			),
			mergeMap((destinations: DestinationListState) => {
				this.destinationResponse = destinations;
				return this.paymentListData$;
			}),
			skipWhile((payments: PaymentModel[]) => !(payments && payments.length > 0)),
			mergeMap((payments: PaymentModel[]) => {
				this.paymentList = payments.filter(i => i.codice_elemento === this.company.condizione_pagamento);
				return this.causaliSospensioniListData$;
			}),
			skipWhile((causali: PropertyModel[]) => !(causali && causali.length > 0)),
			map((causali: PropertyModel[]) => {
				this.causaliSospensioniList = causali;
				this.setValueFromModelForm();
			})
		);
	}

	createForm() {
		this.form = this.fb.group({
			client_code: [{ value: '' }],
			state: [{ value: '' }],
			piva: [{ value: '' }],
			fiscal_code: [{ value: '' }],
			business_name: [{ value: '' }],
			payment: [{ value: '' }],
			sdi: [{ value: '' }],
			// paymentListheadquarters: [{ value: '' }],
			headquarters: [{ value: '' }],
			location: [{ value: '' }]
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	setValueFromModelForm() {
		this.form.patchValue({
			client_code: this.company.codice ? this.company.codice : '',
			state: this.company.codice_blocco
				? this.causaliSospensioniList.find(
						(causale: PropertyModel) => causale.codice_elemento === this.company.codice_blocco
				  ).descrizione
				: 'Attivo',
			piva: this.company.partita_iva ? this.company.partita_iva : '',
			fiscal_code: this.company.codice_fiscale ? this.company.codice_fiscale : '-',
			business_name: this.company.ragione_sociale ? this.company.ragione_sociale : '-',
			payment: '-',
			sdi: this.company.sdi ? this.company.sdi : null,
			headquarters: this.getHeadquarterAddress(),
			location: this.company.indirizzo ? this.utilAddressService.getFormattedAddress(this.company.indirizzo) : '-'
		});
	}

	columnsChangeDestinations(e) {
		for (let i = 0; i < this.columnsDestinations.length; i++) {
			if (this.columnsDestinations[i].name === e.name) {
				this.columnsDestinations[i].hidden = e.hidden;
			}
		}
		this.dataTableDestinations.refresh();
	}

	getHeadquarterAddress() {
		const destination: DestinationPouchModel = this.destinationResponse.values.find(
			destination => destination.codice === this.company.destinazione_merce_abituale
		);
		return destination ? this.utilAddressService.getFormattedAddress(destination.indirizzo) : '-';
	}
}
