import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IframeResolverModule } from '../../../ui/iframe-resolver/iframe-resolver.module';
import { MediaCenterComponent } from './media-center.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IframeResolverModule
  ],
  declarations: [
    MediaCenterComponent
  ],exports:[
    MediaCenterComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})

export class MediaCenterModule {}