import { Component, OnInit, Input } from '@angular/core';
import { AppConfig } from '../../../../../app.config';
import { FamilyPouchModel } from '../../../../../model/pouch/family-pouch.model';
import { UtilCatalogService } from '../../../../../service/util/util-catalog-filter.service';

@Component({
  selector: 'dialog-catalog-family-header',
  templateUrl: './dialog-catalog-family-header.component.html',
  styleUrls: ['./dialog-catalog-family-header.component.scss']
})
export class DialogCatalogFamilyHeaderComponent implements OnInit {

  @Input() family: FamilyPouchModel;

  modalTitle: string;

  constructor(
    private utilCatalogFilterService: UtilCatalogService,
    private appConfig: AppConfig
  ) {}

  ngOnInit() {
    this.modalTitle = this.family.description.slice(
			0,
			this.family.description.indexOf('-')
		).concat("- " + this.family.code);
  }

  getFamilyImagePath(articleCode: string) {
    let company = this.appConfig.config.company;
    return this.utilCatalogFilterService.getFamilyImagePath(company, articleCode);
  }

}
