import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'drop-area-file',
  templateUrl: './drop-area-file.component.html',
  styleUrls: ['./drop-area-file.component.scss']
})
export class DropAreaFileComponent implements OnInit {

  @ViewChild('fileInputContainer', {static: true}) inputUpload: ElementRef;
  @Output() private filesEmitter: EventEmitter<FileList> = new EventEmitter();
  @Input() multiple: boolean;
  @Input() acceptedType: string;

  constructor() { }

  ngOnInit() {
  }

  onFilesChange(fileList: FileList){
    this.filesEventEmitter(fileList);
  }

  onFilesFromSelectChange(event) {
    this.filesEventEmitter(event.target.files);
  }

  filesEventEmitter(files: FileList){
    this.filesEmitter.emit(files);
  }

  eventWrapperInput() {
    this.inputUpload.nativeElement.click();
  }

}
