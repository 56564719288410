import { Injectable } from '@angular/core';
import { Indirizzo } from '../../model/pouch/company-pouch.model';

@Injectable({
	providedIn: 'root'
})
export class UtilAddressService {
	constructor() {}

	getFormattedAddress(address: Indirizzo): string {
		let res: string[] = [];

		if (address.indirizzo) {
			res.push(address.indirizzo);
			res.push('-');
		}

		if (address.cap) {
			res.push(address.cap);
		}

		if (address.localita) {
			res.push(address.localita);
		}

		if (address.provincia) {
			res.push('(' + address.provincia + ')');
		}

		return res.join(' ');
	}
}
