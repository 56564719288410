import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// widget
import { FloatinMenu } from '../../widget/floating-menu/floating-menu.component';
import { MediaReplayService } from '../../service/util/media-replay.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '../../service/navigation/navigation.service';
import { Subscription, Observable } from 'rxjs';
import { NavItem } from '../../model/nav-item.model';
import * as _ from 'lodash';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';

import * as fromState from '../../state/index';
import { Store } from '@ngrx/store';
import { UtilService } from '../../service/util/util.service';
import { UserState } from '../../state/user/user.reducer';

import { version } from '../../../../package.json';

@Component({
	selector: 'ms-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy {
	items: NavItem[];

	currentDate = new Date();
	version = version;

	private _itemsSubscription: Subscription;
	private _routerEventsSubscription: Subscription;

	companyData$: Observable<CompanyPouchModel>;
	company: CompanyPouchModel;

	menuItems: FloatinMenu = {
		icon: 'add',
		emitName: 'main',
		toolTip: 'New',
		color: 'accent',
		items: [
			{
				icon: 'assignment',
				emitName: 'new-order',
				toolTip: 'New Order',
				color: 'accent'
			},
			{
				icon: 'place',
				emitName: 'new-address',
				toolTip: 'New Address',
				color: 'accent'
			}
		]
	};

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	user: UserState;

	constructor(
		private navigationService: NavigationService,
		private router: Router,
		public dialog: MatDialog,
		public mediaReplayService: MediaReplayService,
		private store: Store<any>,
		public utilService: UtilService
	) {
		this.companyData$ = this.store.select(fromState.getCompanyState);
		this.companyData$.subscribe((res: any) => {
			this.company = res;
		});
		this._user = this.user$.subscribe(res => {
			if (res) {
				this.user = res;
			}
		});
	}

	ngOnInit() {
		this._itemsSubscription = this.navigationService.items$.subscribe((items: NavItem[]) => {
			this.items = this.sortRecursive(items, 'position');
		});

		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.navigationService.nextCurrentlyOpenByRoute(event.url);
				// riesco a recuperare nell'elemento zero il master senza passare da una funzione ricorsiva che recupera subItem e slaveItem
				// console.log(this.navigationService.currentlyOpen);
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 400);
			}
		});
	}

	toggleIconSidenav() {
		this.navigationService.isIconSidenav = !this.navigationService.isIconSidenav;
	}

	isIconSidenav(): boolean {
		return this.navigationService.isIconSidenav;
	}

	sortRecursive(array: NavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});
		return _.sortBy(array, propertyName);
	}

	ngOnDestroy() {
		if (this._itemsSubscription && this._routerEventsSubscription) {
			this._itemsSubscription.unsubscribe();
			this._routerEventsSubscription.unsubscribe();
		}
		this._user.unsubscribe();
	}
}
