import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Options } from 'ng5-slider';
import { Observable, from } from 'rxjs';

// model
import { CardTrendConfigurationModel } from '../../../widget/card-trend/card-trend.component';
// widget
import { ApexOptions } from 'ng-apexcharts';
import { ITdDataTableColumn, IPageChangeEvent, TdDataTableComponent } from '@covalent/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ListWrapperComponent } from '../../../widget/list-wrapper/list-wrapper.component';

@Component({
	selector: 'company-credit',
	templateUrl: './company-credit.component.html',
	styleUrls: ['./company-credit.component.scss']
})
export class CompanyCreditComponent implements OnInit {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@ViewChild('listWrapper', { static: false }) listWrapper: ListWrapperComponent;
	companyData$;

	//mappare i filtri nell'oggetto della request
	filters: string = '';
	columns: ITdDataTableColumn[] = [
		{
			name: 'number',
			label: 'Numero',
			width: 150
		},
		{
			name: 'date_creation',
			label: this.translate.instant('company.date_creation'),
			width: 150,
			numeric: true
		},
		{
			name: 'date_expiry',
			label: this.translate.instant('company.date_expiry'),
			width: 150,
			numeric: true
		},
		{
			name: 'amount',
			label: this.translate.instant('company.amount'),
			numeric: true
		},
		{
			name: 'download',
			label: this.translate.instant('company.download'),
			width: 100,
			numeric: true
		}
	];
	data: any[] = [
		{
			number: '0005',
			date_creation: '12/09/2019',
			date_expiry: '21/10/2019',
			amount: '3400,00€'
		},
		{
			number: '0004',
			date_creation: '16/07/2018',
			date_expiry: '12/06/2019',
			amount: '1000,00€'
		},
		{
			number: '0003',
			date_creation: '12/12/2018',
			date_expiry: '12/06/2019',
			amount: '1100,00€'
		},
		{
			number: '0002',
			date_creation: '12/12/2018',
			date_expiry: '12/06/2019',
			amount: '300,00€'
		},
		{
			number: '0001',
			date_creation: '12/12/2018',
			date_expiry: '12/06/2019',
			amount: '1000,00€'
		}
	];
	pageCurrent: number = 1;
	pageSize: number = 10;
	form: FormGroup;
	statusList: string[] = [this.translate.instant('company.not_expired'), this.translate.instant('company.expired')];

	sliderMin: number = 0;
	sliderMax: number = 10000;
	sliderOptions: Options = {
		floor: 0,
		ceil: 100000
	};

	/**
	 * CardTrendComponent
	 */
	cardTrendConfiguration1: CardTrendConfigurationModel = {
		title: 'company.due',
		subtitle: 'company.invoices',
		trend: 7.2,
		valueType: '€',
		time: 'company.weeks',
		value: '1200,00'
	};
	cardTrendConfiguration2: CardTrendConfigurationModel = {
		theme: 'mat-accent',
		title: 'company.overdue',
		subtitle: 'company.invoices',
		trend: -17.2,
		valueType: '€',
		time: 'company.weeks',
		value: '450,00'
	};
	cardTrendConfigurationChart: ApexOptions = {
		chart: {
			type: 'line',
			toolbar: {
				show: false
			}
		},
		colors: ['#1664af'],
		stroke: {
			curve: 'smooth',
			width: 3,
			dashArray: 0
		},
		xaxis: {
			labels: {
				show: false
			},
			axisBorder: {
				show: false
			}
		},
		yaxis: {
			labels: {
				show: false
			}
		},
		tooltip: {
			followCursor: true,
			x: {
				show: false
			},
			fixed: {
				enabled: true,
				position: 'topLeft'
			}
		},
		grid: {
			show: false
		},
		series: [
			{
				name: '2019',
				data: [
					{
						x: '10 mag 2019',
						y: 78
					},
					{
						x: '11 mag 2019',
						y: 94
					},
					{
						x: '12 mag 2019',
						y: 101
					},
					{
						x: '13 mag 2019',
						y: 102
					}
				]
			}
		]
	};

	cardTrendConfigurationChart2: ApexOptions = {
		chart: {
			type: 'line',
			toolbar: {
				show: false
			}
		},
		colors: ['#FFFFFF'],
		stroke: {
			curve: 'smooth',
			width: 3,
			dashArray: 0
		},
		xaxis: {
			labels: {
				show: false
			},
			axisBorder: {
				show: false
			}
		},
		yaxis: {
			labels: {
				show: false
			}
		},
		tooltip: {
			followCursor: true,
			x: {
				show: false
			},
			fixed: {
				enabled: true,
				position: 'topLeft'
			}
		},
		grid: {
			show: false
		},
		series: [
			{
				name: '2019',
				data: [
					{
						x: '10 mag 2019',
						y: 78
					},
					{
						x: '11 mag 2019',
						y: 94
					},
					{
						x: '12 mag 2019',
						y: 101
					},
					{
						x: '13 mag 2019',
						y: 102
					}
				]
			}
		]
	};

	constructor(private fb: FormBuilder, private translate: TranslateService) {
		this.companyData$ = from([
			{
				addebito_spese_trasporto: 'xxx',
				codice: 'xxx',
				codice_agente: 'xxx',
				codice_azienda: 'xxx',
				codice_fiscale: 'xxx',
				codice_listino: 'xxx',
				// condizione_pagamento?: string;
				destinazioni: [],
				divisione: 'xxx',
				evasione_stralci: 'xxx',
				fido: 30,
				indirizzo: {}, // indirizzo
				interno_estero: 'xxx',
				invio_conferma_ordine: 'xxx',
				modalita_consegna: 'xxx',
				modalita_spedizione: 'xxx',
				nazione_cliente: 'xxx',
				partita_iva: 'xxx',
				raggrup_bolle_fattura: 'xxx',
				raggrup_ordini_bolla: 'xxx',
				ragione_sociale: 'Azienda 1',
				ragione_sociale_estesa: 'xxx',
				recapiti: {}, //recapito
				riferimento_interno: 'xxx',
				stampa_conferma_ordine: 'xxx',
				tipo_fornitura_cliente_b: 'xxx',
				type: 'xxx',
				// users?: User[];
				visibile_agenti: 'xxx',
				visibile_web: 'xxx',
				zona_commerciale: 'xxx',
				_id: 'xxx',
				_rev: 'xxx',

				// nuove aggiunte per il modello dati
				orders_state: [],
				average_purchase: 30,
				electronic_invoicing_ref: 'xxx',
				referent: {}, //PersonModel;
				status: 'xxx',
				due: 3,
				due_expired: 4,
				condizione_pagamento: [], //Pagamento[];
				users: [] // PersonModel[],
			}
		]);
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.columns = [
				{
					name: 'number',
					label: 'Numero',
					width: 150
				},
				{
					name: 'date_creation',
					label: this.translate.instant('company.date_creation'),
					width: 150,
					numeric: true
				},
				{
					name: 'date_expiry',
					label: this.translate.instant('company.date_expiry'),
					width: 150,
					numeric: true
				},
				{
					name: 'amount',
					label: this.translate.instant('company.amount'),
					numeric: true
				},
				{
					name: 'download',
					label: this.translate.instant('company.download'),
					width: 100,
					numeric: true
				}
			];
		});
	}

	ngOnInit() {
		this.createForm();
	}

	//form
	createForm() {
		this.form = this.fb.group({
			date_creation: [''],
			date_expiry: [''],
			status: [''],
			amount: ['']
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			console.log('search');
		}
	}

	// form filter
	onFormFiltersSubmit() {
		this.getFilteredList();
	}

	getFilteredList() {}

	// table
	columnsChange(e) {
		for (let i = 0; i < this.columns.length; i++) {
			if (this.columns[i].name == e.name) {
				this.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	// paginator
	pageAttributeChange(e: IPageChangeEvent): void {
		this.pageCurrent = e.page;
		this.pageSize = e.pageSize;
	}

	sortChange(e) {}

	goToDetail(e) {}
}
