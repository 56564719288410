import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile, switchMap } from 'rxjs/operators';

import { ArticleModel } from '../../../../model/article.model';
import { ArticleOrderCatalogColumnService } from '../../../../service/td-data-table/implementation/article-order-catalog.service';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import * as fromState from '../../../../state';
import * as fromArticleList from '../../../../state/article-list/article-list.actions';
import { ArticleListState } from '../../../../state/article-list/article-list.reducer';
import { CompanyState } from '../../../../state/company/company.reducer';
import * as fromFamilyList from '../../../../state/family-list/family-list.actions';
import { FamilyListState } from '../../../../state/family-list/family-list.reducer';
import * as fromOrder from '../../../../state/order/order.actions';
import { OrderState } from '../../../../state/order/order.reducer';
import {
	ArticleFamilyCatalogFilters,
	FamilyCatalogFilters
} from '../../../common/catalog/catalog-family/catalog-family.component';
import { ArticleNewQuantity } from '../../../common/catalog/dialog-catalog-family/dialog-catalog-family-list/dialog-catalog-family-list.component';

@Component({
	selector: 'order-detail-catalog',
	templateUrl: './order-detail-catalog.component.html',
	styleUrls: ['./order-detail-catalog.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailCatalogComponent implements OnInit, OnDestroy {
	company$: Observable<CompanyState>;
	company: CompanyState;

	order$: Observable<OrderState>;
	order: OrderState;

	family$: Observable<FamilyListState>;
	family: FamilyListState = {
		filters: {}
	};

	articleList$: Observable<ArticleListState>;
	articleList: ArticleListState = {
		data: [],
		filters: {
			excludes: {
				stato_articolo: 'E'
			}
		}
	};

	onLangChange$: Subscription;

	constructor(
		private store: Store<any>,
		private articleOrderCatalogColumnService: ArticleOrderCatalogColumnService,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		private translateService: TranslateService,
		private snackbar: MatSnackBar
	) {
		this.company$ = this.store.select(fromState.getCompanyState);
		this.order$ = this.store.select(fromState.getOrderState);
		this.family$ = this.store.select(fromState.getFamilyListState);
		this.articleList$ = this.store.select(fromState.getArticleListState);

		this.subscribeManagerService.populate(this.initMandatoryData().subscribe(() => {}), 'order-catalog-init');
		this.loadFamilyList();
		this.onLangChange$ = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.loadFamilyList();
		});
		this.subscribeManagerService.populate(
			this.updateArticleList().subscribe(() => {}),
			'order-catalog-article-list'
		);
	}

	ngOnInit() {}

	initMandatoryData(): Observable<OrderState> {
		return this.company$.pipe(
			skipWhile((company: CompanyState) => (!(company && company.codice) ? true : false)),
			switchMap((company: CompanyState) => {
				this.company = company;
				return this.order$;
			}),
			skipWhile((order: OrderState) => (!(order && order.header.status) ? true : false)),
			map((order: OrderState) => {
				if (this.order && order.header.order_causal !== this.order.header.order_causal) {
					this.articleList = this.utilOrderService.clearAllProductArticleList(this.articleList);
					this.store.dispatch(fromArticleList.reset());
				}
				this.order = order;
				return this.order;
			})
		);
	}

	updateArticleList() {
		return this.articleList$.pipe(
			skipWhile((articleList: ArticleListState) => !(articleList && articleList.data)),
			map((articleList: ArticleListState) => {
				this.articleList.data = articleList.data;
			})
		);
	}

	loadArticleList() {
		this.store.dispatch(
			fromArticleList.loadWithDetail({
				filters: this.articleList.filters,
				company: this.company,
				order: this.order
			})
		);
	}

	loadFamilyList() {
		this.store.dispatch(fromFamilyList.load({ pagination: this.family.pagination, filters: this.family.filters }));
	}

	getArticlesTableColumns() {
		return this.articleOrderCatalogColumnService.columns;
	}

	catalogFamilyFiltersChange(filters: FamilyCatalogFilters) {
		this.family.filters.description = filters.textField;
		this.family.filters.families = filters.familyCodes;
		this.loadFamilyList();
	}

	articleFamilyFiltersChange(filters: ArticleFamilyCatalogFilters) {
		this.articleList.filters['family'] = filters.familyCode;
		this.loadArticleList();
	}

	articleQuantityChange(articleNewQuantity: ArticleNewQuantity) {
		const newArticle: ArticleModel = articleNewQuantity.article;
		const newQuantity = articleNewQuantity.quantity;
		const foundArticle = this.articleList.data.find(article => article.codice === newArticle.codice);

		if (foundArticle) {
			if (newQuantity !== foundArticle.qty) {
				const description = `${foundArticle.descrizione}-${foundArticle.descrizione_aggiuntiva}`;

				foundArticle.qty = !foundArticle.qty ? 0 : foundArticle.qty;
				let action: string;
				action = newQuantity > foundArticle.qty ? 'Aggiunto' : 'Tolto';

				this.showSnackbar(description, action);
			}
			foundArticle.qty = newQuantity;
			this.store.dispatch(
				fromOrder.save(
					this.utilOrderService.addArticleToOrder(
						this.utilOrderService.returnArticleWithCalculatePrice(foundArticle),
						this.order
					)
				)
			);
		}
	}

	showSnackbar(message: string, action: string) {
		this.snackbar.open(message, action, {
			duration: 2000
		});
	}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
		this.subscribeManagerService.destroy();
	}
}
