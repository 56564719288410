import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UtilOrderService } from './../../service/util/util-order.service';
import { Router } from '@angular/router';
import { ITdDataTableColumn, ITdDataTableRowClickEvent, TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ApexOptions } from 'ng-apexcharts';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap, skipWhile, take } from 'rxjs/operators';

import { StateOrderModel } from '../../enum/stateOrder.enum';
import { Pagination } from '../../model/structure/list-structure.model';
import { ChartService } from '../../service/chart/chart.service';
import { OrderService } from '../../service/rest/order.service';
import { AgentOrderColumnService } from '../../service/td-data-table/implementation/agent-order.service';
import { OrderDashboardColumnService } from '../../service/td-data-table/implementation/order-dashboard.service';
import { SubscribeManagerService } from '../../service/util/subscribe-manager.service';
import * as fromState from '../../state';
import { CompanyState } from '../../state/company/company.reducer';
import * as fromOrderList from '../../state/order-list/order-list.actions';
import {
	CardChartConfigurableConfigurationModel,
	CardChartConfigurableConfigurationSelected
} from '../../widget/card-chart-configurable/card-chart-configurable.component';
import { CardArrayItemModel } from '../../widget/card-trend/card-trend.component';

// Translate
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { OrderPouchModel, OrderPouchModelList } from '../../model/pouch/order-pouch.model';
// import { Store } from '@ngrx/store';
// model
// data
// import { CompanyService } from '../../service/rest/company.service';
// widget
// import { CompanyDashboardColumnService } from '../../service/td-data-table/implementation/company-dashboard.service';
// services
// import * as fromState from '../../state';
// import * as fromOrderList from '../../state/order-list/order-list.actions';
export interface DashboardChart {
	monthlyOrders?: Promise<void>;
	twoMonthlyOrders?: Promise<void>;
	weeklyOrders?: Promise<void>;
}

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [SubscribeManagerService]
})
export class DashboardComponent implements OnInit, OnDestroy {
	@ViewChild('ordersTable', { static: false }) dataTable: TdDataTableComponent;

	readonly hierarchy: { value: string; description: string }[] = [
		{ value: 'this_week', description: 'Questa settimana' }
	];
	onLangChange$: Subscription;

	ordersData;
	totalOrders;

	orders: any = {
		data: [],
		pagination: <Pagination>{
			page_current: 1,
			page_size: 20,
			total_element: 0
		},
		filters: {
			order_states: [
				StateOrderModel.DRAFT.toString(),
				StateOrderModel.READY_TO_SEND.toString(),
				StateOrderModel.PROCESSING.toString(),
				StateOrderModel.CONSOLIDATED.toString()
			],
			company_states: ['']
		}
	};

	fromRow = 1;

	draftCardTrendArray: CardArrayItemModel = {
		cardTrendConfiguration: {
			// theme: "mat-accent",
			title: this.translate.instant('dashboardPage.drafts'),
			subtitle: this.translate.instant('dashboardPage.orders'),
			trend: -7.2,
			time: this.hierarchy.find(e => e.value === 'this_week').description,
			value: '6'
		},

		main: {
			chart: {
				type: 'bar',
				height: 200,
				toolbar: {
					show: false
				}
			},
			stroke: {
				curve: 'smooth',
				width: 0,
				dashArray: 0
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: 'top' // top, center, bottom
					}
				}
			},
			dataLabels: {
				enabled: false, // true per i valori sugli assi
				offsetY: -25,
				style: {
					fontSize: '12px',
					colors: ['#304758']
				}
			},
			colors: ['#fdc202'],

			xaxis: {
				categories: ['L', 'M', 'M', 'G', 'V', 'S', 'D'],
				position: 'bottom',
				labels: {
					offsetY: 0
				},
				axisBorder: {
					show: true
				},
				axisTicks: {
					show: true
				}
			},

			yaxis: {
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				labels: {
					show: true
				}
			},
			tooltip: {
				enabled: true,
				offsetY: -35
			},
			grid: {
				show: true
			},
			series: [
				{
					name: ['Ordini in bozza']
					// data: [0, 0, 1, 1, 4, 0, 0]
				}
			]
		}
	};

	processingCardTrendArray: CardArrayItemModel = {
		cardTrendConfiguration: {
			title: this.translate.instant('dashboardPage.in_progress'),
			subtitle: this.translate.instant('dashboardPage.orders'),
			trend: +20.0,
			time: this.translate.instant('dashboardPage.this_week'),
			value: '5'
		},
		main: {
			chart: {
				type: 'bar',
				height: 200,
				toolbar: {
					show: false
				}
			},
			stroke: {
				curve: 'smooth',
				width: 0,
				dashArray: 0
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: 'top' // top, center, bottom
					}
				}
			},
			dataLabels: {
				enabled: false, // true per i valori sugli assi
				offsetY: -25,
				style: {
					fontSize: '12px',
					colors: ['#304758']
				}
			},
			colors: ['#fdc202'],

			xaxis: {
				categories: ['L', 'M', 'M', 'G', 'V', 'S', 'D'],
				position: 'bottom',
				labels: {
					offsetY: 0
				},
				axisBorder: {
					show: true
				},
				axisTicks: {
					show: true
				}
			},

			yaxis: {
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				labels: {
					show: true
				}
			},
			tooltip: {
				enabled: true,
				offsetY: -35
			},
			grid: {
				show: true
			},
			series: [
				{
					name: ['Ordini in lavorazione']
					// data: [0, 0, 1, 1, 3, 0, 0]
				}
			]
		}
	};

	archivedCardTrendArray: CardArrayItemModel = {
		cardTrendConfiguration: {
			title: this.translate.instant('dashboardPage.fulfilled'),
			subtitle: this.translate.instant('dashboardPage.orders'),
			trend: +1.2,
			time: this.translate.instant('dashboardPage.this_week'),
			value: '1'
		},
		main: {
			chart: {
				type: 'bar',
				height: 200,
				toolbar: {
					show: false
				}
			},
			stroke: {
				curve: 'smooth',
				width: 0,
				dashArray: 0
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: 'top' // top, center, bottom
					}
				}
			},
			dataLabels: {
				enabled: false, // true per i valori sugli assi
				offsetY: -25,
				style: {
					fontSize: '12px',
					colors: ['#304758']
				}
			},
			colors: ['#fdc202'],

			xaxis: {
				categories: ['L', 'M', 'M', 'G', 'V', 'S', 'D'],
				position: 'bottom',
				labels: {
					offsetY: 0
				},
				axisBorder: {
					show: true
				},
				axisTicks: {
					show: true
				}
			},

			yaxis: {
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				labels: {
					show: true
				}
			},
			tooltip: {
				enabled: true,
				offsetY: -35
			},
			grid: {
				show: true
			},
			series: [
				{
					name: 'Ordini Archiviati'
					// data: [0, 0, 0, 0, 1, 0, 0]
				}
			]
		}
	};
	// row 2
	companyMostActiveSemesterChart: ApexOptions = {
		chart: {
			type: 'donut'
		},
		legend: {
			position: 'bottom',
			offsetY: -10
		},
		colors: ['#1664af', '#888888', '#0068b9', '#ff9800', '#078BBD'],
		fill: {
			colors: ['#1664af', '#888888', '#0068b9', '#ff9800', '#078BBD']
		},
		series: [1, 2, 3, 5, 8],
		labels: ['Company 1', 'Company 2', 'Company 3', 'Company 4', 'Company 5']
	};

	// row 3 : Vendite totali
	salesCardChartConfigurableConfiguration: CardChartConfigurableConfigurationModel = {
		title: this.translate.instant('dashboardPage.total_sales'),
		apexChart: {
			chart: {
				type: 'line',
				toolbar: {
					show: false
				}
			},
			colors: ['#0068b9', '#888888'],
			stroke: {
				curve: 'smooth',
				width: 3,
				dashArray: 0
			},
			xaxis: {
				categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
				labels: {
					show: true
				},
				axisBorder: {
					show: true
				}
			},
			yaxis: {
				labels: {
					show: false
				},
				axisBorder: {
					show: true
				}
			},
			tooltip: {
				followCursor: true,
				x: {
					show: true
				},
				fixed: {
					enabled: true,
					position: 'topLeft'
				}
			},
			grid: {
				show: true
			},
			series: [],
			noData: {
				text: this.translate.instant('dashboardPage.noDataFound'),
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 100,
				style: {
					fontSize: '16px',
					color: 'rgba(0, 0, 0, 0.4)',
					fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
				}
			}
		},
		datasetPeriod: [
			{
				label: this.translate.instant('dashboardPage.last_3_months'),
				start: +moment()
					.subtract(3, 'months')
					.format('x'),
				end: +moment().format('x'),
				selected: true
			},
			{
				label: this.translate.instant('dashboardPage.last_6_months'),
				start: +moment()
					.subtract(6, 'months')
					.format('x'),
				end: +moment().format('x')
			},
			{
				label: this.translate.instant('dashboardPage.last_12_months'),
				start: +moment()
					.subtract(12, 'months')
					.format('x'),
				end: +moment().format('x')
			}
		]
	};

	/* Migliori Categorie Vendute */
	cardChartConfigurableConfiguration: CardChartConfigurableConfigurationModel = {
		title: this.translate.instant('dashboardPage.top_sales_category'),
		apexChart: {
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				}
			},

			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '80%'
				}
			},

			dataLabels: {
				enabled: true
			},
			colors: ['#1664af', '#888888'],
			series: [],
			noData: {
				text: this.translate.instant('dashboardPage.noDataFound'),
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 100,
				style: {
					fontSize: '16px',
					color: 'rgba(0, 0, 0, 0.4)',
					fontFamily: 'Roboto, "Helvetica Neue", sans-serif'
				}
			},
			xaxis: {
				categories: ['Cat1', 'Cat2', 'Cat3', 'Cat4', 'Cat5']
			},
			fill: {
				opacity: 1
			}
		},
		datasetType: [
			{
				label: this.translate.instant('dashboardPage.number_of_pieces'),
				id: 'pieces',
				icon: 'details',
				selected: true
			},
			{
				label: this.translate.instant('dashboardPage.economic_value'),
				icon: 'attach_money',
				id: 'value'
			}
		],
		datasetPeriod: [
			{
				label: this.translate.instant('dashboardPage.last_3_months'),
				start: +moment()
					.subtract(3, 'months')
					.format('x'),
				end: +moment().format('x'),
				selected: true
			},
			{
				label: this.translate.instant('dashboardPage.last_6_months'),
				start: +moment()
					.subtract(6, 'months')
					.format('x'),
				end: +moment().format('x')
			},
			{
				label: this.translate.instant('dashboardPage.last_12_months'),
				start: +moment()
					.subtract(12, 'months')
					.format('x'),
				end: +moment().format('x')
			}
		]
	};
	// MOCKUP END

	misure: string;

	ordersData$: Observable<any>;
	companyData$: Observable<any>;
	companySub: Subscription;
	company: CompanyState;

	valoreMedioOrdini: number;
	percentualeMediaOrdini: number;

	reloadSubscription: Subscription;
	ordersDataSubscription: Subscription;

	monthlyOrders: any[] = [];
	weeklyOrders: any[] = [];

	constructor(
		private router: Router,
		public utilOrderService: UtilOrderService,
		public orderDashboardColumnService: OrderDashboardColumnService,
		public orderService: OrderService,
		public agentOrderColumnService: AgentOrderColumnService,
		private store: Store<any>,
		private chartService: ChartService,
		private subscribeManagerService: SubscribeManagerService,
		private translate: TranslateService
	) {
		const today = moment().endOf('day');
		const lastWeek = moment()
			.subtract(6, 'days')
			.startOf('day');
		const lastMonth = moment()
			.subtract(30, 'days')
			.startOf('day');
		const to = +today.format('x');
		const monthfrom = +lastMonth.format('x');
		const weekfrom = +lastWeek.format('x');

		this.ordersData$ = this.store.select(fromState.getOrdersState);
		this.companyData$ = this.store.select(fromState.getCompanyState);

		this.subscribeManagerService.populate(
			this.initMandatoryData(monthfrom, weekfrom, to).subscribe((data: DashboardChart) => {
				const promiseList = [];
				Object.keys(data).forEach(key => {
					promiseList.push(data[key]);
				});
				Promise.all(promiseList).then(() => {
					this.setDataChart(lastMonth, weekfrom, today);
				});
			}),
			'init-dashboard'
		);
		this.onLangChange$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			// draft card translate
			this.draftCardTrendArray.cardTrendConfiguration['title'] = this.translate.instant('dashboardPage.drafts');
			this.draftCardTrendArray.cardTrendConfiguration['subtitle'] = this.translate.instant(
				'dashboardPage.orders'
			);
			this.draftCardTrendArray.cardTrendConfiguration['time'] = this.translate.instant('dashboardPage.this_week');
			// processing card translate
			this.processingCardTrendArray.cardTrendConfiguration['title'] = this.translate.instant(
				'dashboardPage.ready_to_send'
			);
			this.processingCardTrendArray.cardTrendConfiguration['subtitle'] = this.translate.instant(
				'dashboardPage.orders'
			);
			this.processingCardTrendArray.cardTrendConfiguration['time'] = this.translate.instant(
				'dashboardPage.this_week'
			);
			// archived card translate
			this.archivedCardTrendArray.cardTrendConfiguration['title'] = this.translate.instant(
				'dashboardPage.fulfilled'
			);
			this.archivedCardTrendArray.cardTrendConfiguration['subtitle'] = this.translate.instant(
				'dashboardPage.orders'
			);
			this.archivedCardTrendArray.cardTrendConfiguration['time'] = this.translate.instant(
				'dashboardPage.this_week'
			);
		});
	}

	ngOnInit() {}

	initMandatoryData(monthfrom: number, weekfrom: number, to: number): Observable<DashboardChart> {
		const promiseList: DashboardChart = {};
		return this.companyData$.pipe(
			skipWhile((company: CompanyState) => (!(company && company.codice) ? true : false)),
			take(1),
			mergeMap((company: CompanyState) => {
				this.company = company;
				this.store.dispatch(fromOrderList.reset());
				// Load orders
				this.store.dispatch(
					fromOrderList.load({
						pagination: this.orders.pagination,
						filters: {
							client_code: this.company.codice
						},
						sort: [{ 'header.date': 'desc' }]
					})
				);
				return this.ordersData$;
			}),
			skipWhile((orderList: any) => (orderList && orderList.docs ? false : true)),
			map((orderList: any) => {
				this.orders.data = orderList.docs;
				// Riporta il totale con gli sconti senza l'IVA
				if (this.orders.data) {
					this.orders.data.forEach(element => {
						element.header.total = this.calculateDiscount(element);
					});
				}
			}),
			map(() => {
				const monthpr = this.chartService
					.getOrdersByClientAndDates(this.company.codice, monthfrom, to)
					.then(order => {
						if (order.rows !== undefined && order.rows.length > 0) {
							order.rows.forEach(row => {
								this.monthlyOrders.push(row.value);
							});
						}
					});
				// get orders by client, now - 1 week, now
				const weekpr = this.chartService
					.getOrdersByClientAndDates(this.company.codice, weekfrom, to)
					.then(order => {
						if (order.rows !== undefined && order.rows.length > 0) {
							order.rows.forEach(row => {
								this.weeklyOrders.push(row.value);
							});
						}
					});
				promiseList.monthlyOrders = monthpr;
				promiseList.weeklyOrders = weekpr;
				return promiseList;
			})
		);
	}

	setDataChart(lastMonth: moment.Moment, weekfrom: number, today: moment.Moment) {
		// Aggrega per draft, processing, archived (status)
		const monthStatus = this.chartService.groupBy(this.monthlyOrders, 'status');
		const weekStatus = this.chartService.groupBy(this.weeklyOrders, 'status');

		// Conta gli ordini di questa settimana/mese

		// BOZZE
		const weekDraftOrders: any[] =
			weekStatus[StateOrderModel.DRAFT] === undefined ? [] : weekStatus[StateOrderModel.DRAFT];
		const monthDraftOrders: any[] =
			monthStatus[StateOrderModel.DRAFT] === undefined ? [] : monthStatus[StateOrderModel.DRAFT];

		// IN LAVORAZIONE
		const weekReadyToSendOrders: any[] =
			weekStatus[StateOrderModel.READY_TO_SEND] === undefined ? [] : weekStatus[StateOrderModel.READY_TO_SEND];
		const monthReadyToSendOrders: any[] =
			monthStatus[StateOrderModel.READY_TO_SEND] === undefined ? [] : monthStatus[StateOrderModel.READY_TO_SEND];

		const weekSendingOrders: any[] =
			weekStatus[StateOrderModel.SENDING] === undefined ? [] : weekStatus[StateOrderModel.SENDING];
		const monthSendingOrders: any[] =
			monthStatus[StateOrderModel.SENDING] === undefined ? [] : monthStatus[StateOrderModel.SENDING];

		const weekProcessingOrders: any[] =
			weekStatus[StateOrderModel.PROCESSING] === undefined ? [] : weekStatus[StateOrderModel.PROCESSING];
		const monthProcessingOrders: any[] =
			monthStatus[StateOrderModel.PROCESSING] === undefined ? [] : monthStatus[StateOrderModel.PROCESSING];

		const weekPartiallyFulfilledOrders: any[] =
			weekStatus[StateOrderModel.PARTIALLY_FULFILLED] === undefined
				? []
				: weekStatus[StateOrderModel.PARTIALLY_FULFILLED];
		const monthPartiallyFulfilledOrders: any[] =
			monthStatus[StateOrderModel.PARTIALLY_FULFILLED] === undefined
				? []
				: monthStatus[StateOrderModel.PARTIALLY_FULFILLED];

		const weekConsolidatedOrders: any[] =
			weekStatus[StateOrderModel.CONSOLIDATED] === undefined ? [] : weekStatus[StateOrderModel.CONSOLIDATED];
		const monthConsolidatedOrders: any[] =
			monthStatus[StateOrderModel.CONSOLIDATED] === undefined ? [] : monthStatus[StateOrderModel.CONSOLIDATED];

		// ARCHIVIATI
		const weekArchivedOrders: any[] =
			weekStatus[StateOrderModel.FULFILLED] === undefined ? [] : weekStatus[StateOrderModel.FULFILLED];
		const monthArchivedOrders: any[] =
			monthStatus[StateOrderModel.FULFILLED] === undefined ? [] : monthStatus[StateOrderModel.FULFILLED];

		// console.log('weekDraftOrders: ', weekDraftOrders);
		// console.log('weekProcessingOrders: ', weekProcessingOrders);
		// console.log('weekArchivedOrders: ', weekArchivedOrders);
		// console.log('weekConsolidatedOrders: ', weekConsolidatedOrders);

		const twoWeeksAgo = +moment(weekfrom, 'x')
			.subtract(7, 'days')
			.format('x');
		const twoWeeksAgoDraftOrders = this.chartService.getOrdersFromTo(monthDraftOrders, twoWeeksAgo, weekfrom);
		const twoWeeksAgoProcessingOrders = this.chartService.getOrdersFromTo(
			monthReadyToSendOrders.concat(
				monthSendingOrders,
				monthProcessingOrders,
				monthPartiallyFulfilledOrders,
				monthConsolidatedOrders
			),
			twoWeeksAgo,
			weekfrom
		);
		const twoWeeksAgoArchivedOrders = this.chartService.getOrdersFromTo(monthArchivedOrders, twoWeeksAgo, weekfrom);

		this.draftCardTrendArray = this.chartService.calculateOrderChart(
			this.draftCardTrendArray,
			weekDraftOrders,
			twoWeeksAgoDraftOrders
		);
		this.processingCardTrendArray = this.chartService.calculateOrderChart(
			this.processingCardTrendArray,
			weekReadyToSendOrders.concat(
				weekSendingOrders,
				weekProcessingOrders,
				weekPartiallyFulfilledOrders,
				weekConsolidatedOrders
			),
			twoWeeksAgoProcessingOrders
		);
		this.archivedCardTrendArray = this.chartService.calculateOrderChart(
			this.archivedCardTrendArray,
			weekArchivedOrders,
			twoWeeksAgoArchivedOrders
		);

		// calcola valore medio su tutti gli ordini archiviati della settimana
		this.valoreMedioOrdini = this.chartService.calculateAverageOnField(
			weekStatus[StateOrderModel.FULFILLED],
			'total'
		);
		const valoreMedioOrdiniMonth = this.chartService.calculateAverageOnField(twoWeeksAgoArchivedOrders, 'total');
		this.percentualeMediaOrdini = this.chartService.calculatePercentage(
			valoreMedioOrdiniMonth,
			this.valoreMedioOrdini
		);
	}

	// td-datra-table
	goToOrderDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([`orders/${e.row.status}/${e.row.id}`]);
	}

	goToCompanyDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([`companies/${e.row.id}/riepilogo`]);
	}

	// card-chart-configurable
	onCardChartConfigurableConfigurationChange(e: CardChartConfigurableConfigurationSelected) {
		console.log(e);
	}

	sumProduct(id: string): string {
		// let totProducts: number = 0;
		// const ordine = this.orders.data.find(dt => dt._id === id);
		// ordine.row_list.forEach(r => {
		//   r.product_class.forEach(pc => {
		//     pc.product_list.forEach(pl => {
		//       totProducts += pl.price;
		//     })
		//   })
		// })
		// return totProducts.toString();
		return '200';
	}

	goToDetail(e: ITdDataTableRowClickEvent) {
		// this.store.dispatch(fromCompany.load({ code: `${e.row.header.client_code}` }));
		// this.store.dispatch(fromOrderDetail.load({ id: e.row._id }));
		// this.store.dispatch(fromArticleList.load(null));
		this.router.navigate([
			`/orders/${StateOrderModel[e.row.header.status].toLowerCase()}/${e.row.header.client_code}/${
				e.row._id
			}/carrello`
		]);
	}

	// Riporta il totale con sconto senza IVA
	calculateDiscount(data) {
		let total = 0;
		data.product_list.forEach(product_list_element => {
			let price = 0;
			if (product_list_element.ordered_quantity) {
				price = product_list_element.price * product_list_element.ordered_quantity;
			}
			if (product_list_element.discount && product_list_element.discount.length > 0) {
				const totale = parseFloat((price - (price * product_list_element.discount[0].value) / 100).toFixed(2));
				total += totale;
			} else {
				const totale = parseFloat(price.toFixed(2));
				total += totale;
			}
		});
		return parseFloat(total.toFixed(2));
	}

	ngOnDestroy() {
		this.store.dispatch(fromOrderList.reset());
		this.subscribeManagerService.destroy();
		this.onLangChange$.unsubscribe();
	}
}
