import { AccordionStateService } from './../../../../service/util/accordion-state.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { OrderListModel } from '../../../../model/order.model';
import { CausalHeaderOdvModel } from '../../../../model/pouch/auxiliary-table.model';
import { CompanyPouchModel } from '../../../../model/pouch/company-pouch.model';
import { ProductModel } from '../../../../model/product.model';
import { ProductCheckoutColumnService } from '../../../../service/td-data-table/implementation/product-checkout.service';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import * as fromState from '../../../../state';
import * as fromOrder from '../../../../state/order/order.actions';
import { OrderState } from '../../../../state/order/order.reducer';
import { DialogConfirmComponent } from '../../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { DialogTextEditComponent } from '../../../../widget/dialog/dialog-text-edit/dialog-text-edit.component';
import { InputTypeSelectablesTypeModel } from '../../../../widget/input-type-selectable/input-type-selectable.component';
import { OrderPouchModel, ProductPouchModel } from './../../../../model/pouch/order-pouch.model';
import { NewOrderService } from '../../../../service/rest/newOrder.service';
import { UtilService } from '../../../../service/util/util.service';
import { UserState } from '../../../../state/user/user.reducer';

// model
// data
// widget
@Component({
	selector: 'order-detail-checkout',
	templateUrl: './order-detail-checkout.component.html',
	styleUrls: ['./order-detail-checkout.component.scss']
})
export class OrderDetailCheckoutComponent implements OnInit, OnDestroy {
	// Mat accordion state
	expandedState = false;

	order: OrderPouchModel = new OrderPouchModel();
	orders: OrderListModel;
	order$: Observable<OrderPouchModel>;
	orderSubscription: Subscription;

	company$: Observable<CompanyPouchModel>;
	companySub: Subscription;

	causali_testate_odv$: Observable<CausalHeaderOdvModel[]>;
	causali_testate_odvSub: Subscription;

	causal_code: string;

	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	inputTypeSelectablesTypes: InputTypeSelectablesTypeModel[] = [
		{
			type: 'currency',
			icon: 'euro_symbol'
		},
		{
			type: 'percentage',
			description: '%'
		}
	];
	text_search: string;
	totalArticles: ProductModel[];
	fromRow = 0;

	productsAreNotAvailable: boolean = false;

	// DEBOUNCE
	private updateRowSource = new Subject<any>();
	updateRow$: Observable<any> = this.updateRowSource.asObservable();

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	user: UserState;

	constructor(
		public productCheckoutColumnService: ProductCheckoutColumnService,
		private dialog: MatDialog,
		private store: Store<any>,
		private router: Router,
		public utilOrderService: UtilOrderService,
		private accordionStateService: AccordionStateService,
		private newOrderService: NewOrderService,
		private utilService: UtilService
	) {
		this.order$ = this.store.select(fromState.getOrderState);
		this.orderSubscription = this.order$.subscribe((res: OrderState) => {
			this.order = res;
		});
		this.causali_testate_odv$ = this.store.select(fromState.getAuxiliaryCausaliTestateOdv);
		this.causali_testate_odvSub = this.causali_testate_odv$.subscribe(causali => {
			this.company$ = this.store.select(fromState.getCompanyState);
			this.companySub = this.company$.subscribe(comp => {
				if (comp._id && causali && causali.length !== 0) {
					this.causal_code = causali.find(
						c => c.codice_elemento === comp.causale_ordine_abituale
					).causali_riga[0];
				}
			});
		});
		this.user$.pipe(take(1)).subscribe(user => (this.user = user));
		this.showAvailabilityAlerts();
	}

	ngOnInit() {
		this.updateRow$.pipe(debounceTime(500)).subscribe((res: any) => {
			this.changeQuantity(res.$event, res.row);
			this.dataTable.refresh();
		});
	}

	showAvailabilityAlerts() {
		this.productsAreNotAvailable = this.order.product_list.findIndex(prod => prod.disponibilita === 'ND') !== -1;
	}

	canEditOrder() {
		return this.order.header.status == 'DRAFT' && !this.utilService.checkUserPermission(this.user, 'bo-dashboard');
	}

	openDialogTextEdit(e: ProductPouchModel) {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: `Nota Prodotto ${e.code}`,
				text: e.note,
				disabled: this.canEditOrder() ? false : true
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (typeof res === 'string') {
				if (res === '' || !res.replace(/\s/g, '').length) {
					delete e.note;
				} else {
					e.note = res;
				}
				this.store.dispatch(fromOrder.save(this.addArticleToOrder(e)));
			}
		});
	}

	updatedRowSelection($event, row: ProductPouchModel) {
		this.updateRowSource.next({ $event, row });
	}

	// data
	changeQuantity(newQuantity, articleRow: ProductPouchModel) {
		newQuantity = this.checkValue(newQuantity, articleRow);
		articleRow.ordered_quantity = newQuantity;
		this.store.dispatch(fromOrder.save(this.addArticleToOrder(articleRow)));
		this.showAvailabilityAlerts();
	}

	checkValue(quantity: number, row: ProductPouchModel) {
		if (!quantity || row.pezzi_confezione === 0) {
			return null;
		}
		return Math.ceil(quantity / row.pezzi_confezione) * row.pezzi_confezione;
	}

	addArticleToOrder(data: ProductPouchModel) {
		const productArticle = this.order.product_list.findIndex((article: ProductPouchModel) => {
			return (
				article.code === data.code &&
				article.line_code === data.line_code &&
				article.class_code === data.class_code
			);
		});
		if (productArticle !== -1) {
			if (data.ordered_quantity === 0 || data.ordered_quantity === null || data.ordered_quantity === undefined) {
				this.order.product_list.splice(productArticle, 1);
			} else {
				this.order.product_list[productArticle] = data;
			}
		} else if (data.ordered_quantity) {
			this.order.product_list.push({
				line_code: data.line_code,
				class_code: data.class_code,
				description: data.description,
				descrizione_aggiuntiva: data.descrizione_aggiuntiva,
				sup_row_external: '1',
				code: data.code,
				order_causal_code: this.causal_code,
				ordered_quantity: data.ordered_quantity,
				price: data.price,
				qty_free: data.qty_free,
				note: data.note,
				discount: data.discount,
				disponibilita: data.disponibilita,
				pezzi_confezione: data.pezzi_confezione
			});
		}
		this.order.header.total = this.utilOrderService.getProductsTotalPrice(this.order);
		return this.order;
	}

	sendOrder() {
		this.newOrderService.newOrder({ order_id: this.order._id });
		this.order.header.status = 'READY_TO_SEND';
		this.store.dispatch(fromOrder.save(this.order));
	}

	calculatePrice(data) {
		return this.utilOrderService.calculateDiscount(data).toFixed(2);
	}

	// widget
	openDialogConfirmSendOrder(event) {
		this.accordionStateService.accordionState = true;

		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Invio Ordine',
				text: "Vuoi davvero inviare l'ordine?"
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				this.accordionStateService.accordionState = false;
				this.sendOrder();
				this.router.navigate(['/orders/ready_to_send']);
			} else {
				this.accordionStateService.accordionState = false;
			}
		});
		event.stopPropagation();
	}

	ngOnDestroy() {
		if (this.orderSubscription) {
			this.orderSubscription.unsubscribe();
		}
		if (this.causali_testate_odvSub) {
			this.causali_testate_odvSub.unsubscribe();
		}
		if (this.companySub) {
			this.companySub.unsubscribe();
		}
		this.updateRowSource.unsubscribe();
	}
}
