import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../service/rest/auth.service";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { UserState } from "../../../state/user/user.reducer";
import * as fromState from '../../../state';
import { Store } from "@ngrx/store";
import { LocalStorage } from "ngx-webstorage";

@Component({
  selector: "ms-toolbar-user-button",
  templateUrl: "./toolbar-user-button.component.html",
  styleUrls: ["./toolbar-user-button.component.scss"]
})
export class ToolbarUserButtonComponent implements OnInit {
  @LocalStorage('language') language: string;
  languages = this.translate.getLangs();
  
  panelOpenState = false;
  isOpen: boolean;
  checkModel = false;

  user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	user: UserState = <UserState>{};

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<any>
  ) {
    this._user = this.user$.subscribe((res) => {
			if (res) {
				this.user = res;
			}
		});
  }

  ngOnInit() {

  }
  
  ngOnDestroy() {
    this._user.unsubscribe();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  langChange(e: MatSelectChange) {
    this.language = e.value;
    this.translate.use(this.language);
  }
}
