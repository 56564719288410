import { Injectable } from "@angular/core";
import { BaseApiService } from "./baseApi.service";
// model
import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise, IResourceMethodPromiseStrict } from "@ngx-resource/core";
import { CompanyState } from "../../state/company/company.reducer";
import { UserSaveResponse } from "../../model/user.model";

@Injectable()
export class CompanyService extends BaseApiService {

  @ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/company/{!clientCode}'
	})
	save: IResourceMethodPromiseStrict<CompanyState, void, {clientCode: string}, UserSaveResponse>;

}
