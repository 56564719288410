import { createAction, props } from '@ngrx/store';
import { Pagination } from '../../model/structure/list-structure.model';
import { ArticleListState } from './article-list.reducer';
import { OrderPouchModel } from '../../model/pouch/order-pouch.model';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';

export const update = createAction('[Articles Context] Update', props<ArticleListState>());
export const load = createAction('[Articles Context] Load', props<{ pagination?: Pagination; filters?: any }>());

export const loadPublic = createAction(
	'[Articles Context] Load Public',
	props<{ pagination?: Pagination; filters?: any }>()
);
export const loadWithDetail = createAction(
	'[Articles Context] Load With Detail',
	props<{ pagination?: Pagination; filters?: any; company?: CompanyPouchModel; order?: OrderPouchModel }>()
);

export const reset = createAction('[Articles Context] Reset');
