import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
// model
import { CompanyState } from '../../../../state/company/company.reducer';
import { CompanyListState } from '../../../../state/company-list/company-list.reducer';
// store
import { Store } from '@ngrx/store';
import * as fromState from '../../../../state/index';
import * as fromCompany from '../../../../state/company/company.actions';
import * as fromCompanyList from '../../../../state/company-list/company-list.actions';
import * as fromDestinationList from '../../../../state/destination-list/destination-list.actions';
// utility & widget
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyColumnService } from '../../../../service/td-data-table/implementation/company.service';
import { ITdDataTableRowClickEvent } from '@covalent/core';
import { DestinationPouchModel, DestinationPouchResponseModel } from '../../../../model/pouch/destination-pouch.model';
import { UtilAddressService } from '../../../../service/util/util-address.service';

@Component({
	selector: 'company-detail',
	templateUrl: './company-detail.component.html',
	styleUrls: ['./company-detail.component.scss']
})
export class BackofficeCompanyDetailComponent implements OnInit {
	// Mat accordion state
	expandedState = true;

	idCompany =
		this.route.snapshot.paramMap.get('idCompany') != 'new' ? this.route.snapshot.paramMap.get('idCompany') : null;

	company$: Observable<CompanyState> = this.store.select(fromState.getCompanyState);
	_company: Subscription;
	company: CompanyState = <CompanyState>{};

	companyList$: Observable<CompanyListState> = this.store.select(fromState.getCompanyListState);
	_companyList: Subscription;
	companyList: CompanyListState = {
		filters: {
			excludes: ['users']
		}
	};

	destinationResponse: DestinationPouchResponseModel;

	constructor(
		private route: ActivatedRoute,
		private store: Store<any>,
		public companyColumnService: CompanyColumnService,
		private snackBar: MatSnackBar,
		public location: Location,
		public utilAddressService: UtilAddressService
	) {
		this._company = this.company$.subscribe((res: CompanyState) => {
			this.company = res;
		});
		this._companyList = this.companyList$.subscribe((res: CompanyListState) => {
			if (res) {
				this.companyList = res;
			}
		});
	}

	ngOnInit() {
		if (this.idCompany) {
			this.getCompanyDetail();
			this.getDestinationList(this.idCompany);
		}
	}

	ngOnDestroy() {
		this._company.unsubscribe();
		this._companyList.unsubscribe();
		this.store.dispatch(fromCompany.reset());
		this.store.dispatch(fromCompanyList.reset());
		this.store.dispatch(fromDestinationList.reset());
	}

	// data

	getCompanyDetail() {
		this.store.dispatch(fromCompany.load({ code: this.idCompany }));
	}

	getCompanyFilteredList() {
		this.store.dispatch(fromCompany.reset());
		if (/^ *$/.test(this.companyList.filters.description)) {
			this.snackBar.open('Immettere una Codice Cliente oppure una Ragione Sociale', 'HO CAPITO', {
				duration: 5000,
				verticalPosition: 'bottom'
			});
		} else {
			this.companyList.filters.description = this.companyList.filters.description.trim();
			this.store.dispatch(fromCompanyList.load(this.companyList));
		}
	}

	getDestinationList(codice_cliente: string) {
		if (codice_cliente) {
			this.store.dispatch(fromDestinationList.load({ codice_cliente: codice_cliente }));
			this.store.select(fromState.getDestinationListState).subscribe((res: any) => {
				this.destinationResponse = res;
			});
		}
	}

	// events
	selectCompany(e: ITdDataTableRowClickEvent) {
		this.store.dispatch(fromCompany.update(e.row));
		this.store.dispatch(fromCompanyList.update(this.companyList));
	}

	getHeadquarterAddress() {
		if (this.destinationResponse) {
			const destination: DestinationPouchModel = this.destinationResponse.values.find(
				destination => destination.codice === this.company.destinazione_merce_abituale
			);
			return destination ? this.utilAddressService.getFormattedAddress(destination.indirizzo) : '-';
		}
	}
}
