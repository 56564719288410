import { createReducer, on } from '@ngrx/store';
import * as fromOrder from './order-list.actions';
// model
import { OrderPouchModelList } from '../../model/pouch/order-pouch.model';

export interface OrderListState extends OrderPouchModelList {}

export const initialState: OrderListState = {
  pagination: {
    page_current: 0,
    page_size: 10
  },
  data: []
};

const _reducer = createReducer(initialState,
  on(fromOrder.update, (state, orsers) => orsers),
  on(fromOrder.load, (state) => state),
  on(fromOrder.reset, state => {
    return initialState;
  })
);

export function reducer(state, action) {
  return _reducer(state, action);
}
