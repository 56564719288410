import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'catalog-public-wrapper',
	templateUrl: './public-wrapper.component.html',
	styleUrls: ['./public-wrapper.component.scss']
})
export class PublicWrapperComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
