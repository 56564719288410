import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'agent',
	templateUrl: './agent.component.html',
	styleUrls: ['./agent.component.scss']
})
export class BackofficeAgentComponent implements OnInit {
	constructor(private router: Router) {}
	/**
	 * START : mockup
	 */
	agentList: any = {
		data: [
			{
				id: '0001',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			},
			{
				id: '0002',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			},
			{
				id: '0003',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			}
		],
		columns: [
			{
				name: 'cod_gestionale',
				label: 'Codice agente gestionale'
			},
			{
				name: 'descrizione',
				label: 'Descrizione'
			}
		]
	};
	/**
	 * END : mockup
	 */

	ngOnInit() {}

	newAgent() {
		this.router.navigate(['backoffice/agent/' + 'new']);
	}

	detailAgent(idAgent) {
		this.router.navigate(['backoffice/agent/' + idAgent]);
	}
}
