import {Injectable} from "@angular/core";
import {BaseApiService} from "./baseApi.service";
import {ResourceAction, ResourceRequestMethod, IResourceMethod, IResourceMethodPromise} from "@ngx-resource/core";
import {AccessRequestModel} from "../../model/access-request.model";

@Injectable()
export class AccessRequestService extends BaseApiService {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/authentication/access-request'
  })
  accessRequest: IResourceMethod<any, AccessRequestModel>;
}