import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfig } from '../../../app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessRequestService } from '../../../service/rest/accessRequest.service';
import { FormValidatorCustom } from '../../../shared/misc/form-validator';
// widget
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AccessRequestModel } from '../../../model/access-request.model';

@Component({
	selector: 'access-request',
	templateUrl: './access-request.component.html',
	styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit {
	@ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

	form: FormGroup;
	logo = '../logo/SPIN8/v2/logo-SPIN8-esales-business.svg';

	constructor(
		private appConfig: AppConfig,
		private fb: FormBuilder,
		private router: Router,
		private accessRequestService: AccessRequestService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService // private http: HttpClient
	) {
		if (this.appConfig.config.company && this.appConfig.config.company.logo) {
			this.logo = this.appConfig.config.company.logo;
		}

		this.createForm();
	}

	ngOnInit() {
	}

	//form
	createForm() {
		this.form = this.fb.group({
			piva: ['', Validators.required],
			business_name: ['', Validators.required],
			address: ['', Validators.required],
			reference: ['', Validators.required],
			email: ['', [FormValidatorCustom.email, Validators.required]],
			privacyCheck: [null, Validators.required],
			captcha: ['']
		});
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm: AccessRequestModel = {
			piva: formModel.piva as string,
			business_name: formModel.business_name as string,
			address: formModel.address as string,
			reference: formModel.reference as string,
			email: formModel.email as string,
			privacyCheck: formModel.privacyCheck as boolean,
			captcha: formModel.captcha as string,
		};
		return saveForm;
	}

	recaptchaResponse(recaptchaResponse: string) {
		this.form.controls['captcha'].setValue(recaptchaResponse);
    }

	sendAccessRequest() {
		if (this.form.valid) {
			let saveRequest: AccessRequestModel = this.prepareSaveForm();
			this.accessRequestService
				.accessRequest(saveRequest)
				.then(res => {
					this.snackbar.open('Richiesta inviata con successo!', 'OK', { duration: 3000 });
					this.router.navigate(['/authentication/login']);
				})
				.catch(err => {
					this.snackbar.open(err.body.message, this.translateService.instant('general.close').toUpperCase(), { duration: 3000 });
				});
		}
	}

}