import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../../../service/rest/user.service';

// rest
// widget
//localizzazione
@Component({
	selector: 'password-update',
	templateUrl: './password-update.component.html',
	styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent implements OnInit {
	form: FormGroup;
	hide = true;
	passwordMinLength = 6;

	constructor(
		private router: Router,
		private snackBar: MatSnackBar,
		private fb: FormBuilder,
		private userService: UserService,
		public translate: TranslateService
	) {
		this.createForm();
	}

	ngOnInit() {}

	// form
	createForm() {
		this.form = this.fb.group({
			password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]]
		});
	}

	getFormStructure() {
		const formModel = this.form.value;
		const saveForm = {
			password: formModel.password as string
		};
		return saveForm;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.saveDetail();
		}
	}

	// widget
	openSnackBar(message: string, action = 'Ok', duration = 3000) {
		this.snackBar.open(message, action, {
			duration: duration
		});
	}

	// rest
	saveDetail() {
		const saveData = this.getFormStructure();
		this.userService.passwordUpdate(saveData.password).then(
			() => {
				this.translate.get('login').subscribe((login: any) => {
					console.log(login);
					this.openSnackBar(login.password_update.success_message);
				});
				this.router.navigate(['/']);
			},
			() => {
				this.translate.get('login').subscribe((login: any) => {
					console.log(login);
					this.openSnackBar(login.password_update.error_message);
				});
			}
		);
	}
}
