import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class tabCatalogService {
	stateCatalog: boolean = true;

	constructor() {}
}
