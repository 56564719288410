import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import * as fromCompany from './company-list.actions';
import { CompanyListState } from './company-list.reducer';

// service
@Injectable()
export class CompanyListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Company List Context] Load'),
			mergeMap(action =>
				from(this.getCompanyFilteredList(action)).pipe(
					map(companyListState => fromCompany.update(companyListState)),
					catchError(err => of({ err, type: '[Company List Context] Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService, private snackBar: MatSnackBar) {}

	getCompanyFilteredList(data: CompanyListState): Promise<CompanyListState> {
		return this.pouchUtilService
			.getActiveAdapter()
			.codiceClientePouch.getClientList(data.filters, data.pagination)
			.then(res => {
				const companyListState: CompanyListState = {
					data: res.docs,
					pagination: data.pagination,
					filters: data.filters
				};
				if (companyListState.data.length === 0) {
					this.snackBar.open('La ricerca non ha prodotto risultati', 'OK', {
						duration: 5000,
						verticalPosition: 'bottom'
					});
				}
				return companyListState;
			});
	}
}
