import { createReducer, on } from '@ngrx/store';
import * as fromArticleList from './article-list.actions';
import { ArticleListModel } from '../../model/article.model';

export interface ArticleListState extends ArticleListModel {}

export const initialState: ArticleListState = { data: [] };

const _reducer = createReducer(
	initialState,
	on(fromArticleList.update, (state, articles: ArticleListState) => articles),
	on(fromArticleList.reset, (state) => initialState),
);

export function reducer(state, action) {
	return _reducer(state, action);
}
