import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class SummaryTableColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: "id",
			label: "general.id"
		},
		{
			name: "name",
			label: "general.name"
		}
	];
}
