import { Component, Input, OnInit } from "@angular/core";
import * as screenfull from "screenfull";
import { NavigationService } from "../../service/navigation/navigation.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConfig } from '../../app.config';


@Component({
  selector: "ms-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent implements OnInit {
  @Input("sidenav") sidenav: any;
  isFullscreen: boolean = false;
  title: string = '';
	logo: string = '../logo/SPIN8/v2/logo-SPIN8-esales-business.svg';


  constructor(
    public navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
		private appConfig: AppConfig

    // public connectionAppService: ConnectionAppService
  ) {
    if (this.appConfig.config.company && this.appConfig.config.company.logo) {
      this.logo = this.appConfig.config.company.logo;
		}
  }

  ngOnInit() {
    this.title = this.activatedRoute.snapshot.data.title;
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  toggleIconSidenav() {
    this.navigationService.isIconSidenav = !this.navigationService.isIconSidenav;
  }

  isIconSidenav(): boolean {
    return this.navigationService.isIconSidenav;
  }
}
