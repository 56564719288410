import { Component, OnInit, ViewChild } from '@angular/core';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core';
import { Observable, from, Subscription } from 'rxjs';
// import { CompanyPouchModel } from '../../../../model/pouch/company-pouch.model';
// import { Store } from '@ngrx/store';
// import * as fromState from '../../../../state/index';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
// data
import { Store } from '@ngrx/store';
import * as fromState from '../../../state/index';
import * as fromDestinationList from "../../../state/destination-list/destination-list.actions";
import { DestinationPouchResponseModel } from "../../../model/pouch/destination-pouch.model";
import { CompanyPouchModel } from '../../../model/pouch/company-pouch.model';


@Component({
  selector: 'company-destination',
  templateUrl: './company-destination.component.html',
  styleUrls: ['./company-destination.component.scss']
})
export class CompanyDestinationComponent implements OnInit {

  @ViewChild("dataTable", {static: false}) dataTable: TdDataTableComponent;

  company: CompanyPouchModel;
  companyData$: Observable<CompanyPouchModel>;
  destinationResponse: DestinationPouchResponseModel;
  _companyData: Subscription;
  
  filters: string = '';
  columns: ITdDataTableColumn[] = [
    {
      name: 'indirizzo.provincia',
      label: 'Provincia',
      width: 70
    },
    {
      name: 'indirizzo.localita',
      label: 'Città'
    },
    {
      name: 'indirizzo.indirizzo',
      label: 'Indirizzo'
    }
  ];

  constructor(private store: Store<any>) {
    this.companyData$ = this.store.select(fromState.getCompanyState);
    this._companyData = this.companyData$.subscribe((res: any) => {
      this.company = res;
      this.getDestinationList(res.codice);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._companyData.unsubscribe();
  }

  // data
  getDestinationList(codice_cliente: string) {
    if (codice_cliente) {
      this.store.dispatch(fromDestinationList.load({codice_cliente: codice_cliente}));
      this.store.select(fromState.getDestinationListState).subscribe((res: any) => {
        this.destinationResponse = res;
      });
    }
  }

  // widget
  columnsChange(e) {
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].name == e.name) {
        this.columns[i].hidden = e.hidden;
      }
    }
    this.dataTable.refresh();
   }

  getFilteredList() { }

  newDestination(){
  }
}
