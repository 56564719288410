import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
// model
import { CustomResponseLogin, LoginMessageError } from '../../../model/user.model';
// widget & utility
import { AuthService } from '../../../service/rest/auth.service';
import { UserService } from '../../../service/rest/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarWrapperComponent } from '../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { FormValidatorCustom } from '../../../shared/misc/form-validator';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  hide = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
      this.createForm();
  }

  ngOnInit() { }

  // form

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, FormValidatorCustom.email]],
      password: ['', Validators.required]
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.login();
    }
  }

  login() {
    this.userService.login(this.form.value)
      .then((res: CustomResponseLogin) => {
        this.authService.authenticationToken = res.accessToken;
        this.router.navigate(['/']);
      })
      .catch((err: LoginMessageError) => {
        let message = 'Accesso non riuscito.';
        err.body.non_field_errors.forEach(i => {
          message = `${message} <br> ${i} `;
        });
        this.snackBar.openFromComponent(
          MatSnackBarWrapperComponent, {
          duration: 5000,
          data: {
            message: message
          }
        });
      });
  }

}
