import { InjectorModule } from '../../../injector.module';
import { IStringDictionary } from '../../../interface/iDictionary';
import { Pagination } from '../../../model/structure/list-structure.model';
import { LoaderService } from '../../structure/loader.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class AbstractPouchdbMethod {
	_db: PouchDB.Database;
	loaderService: LoaderService;
	translateService: TranslateService;

	combinationOperators = ["$and", "$or", "$not", "$nor"];
	conditionOperators = ["$lt", "$gt", "$lte", "$gte", "$eq", "$ne", "$exists", "$type", "$in", "$nin", "$size", "$mod", "$regex", "$all", "$elemMatch"];

	constructor(activeDB: PouchDB.Database) {
		this.loaderService = InjectorModule.injector.get(LoaderService);
		this.translateService = InjectorModule.injector.get(TranslateService);
		this.setActiveDB(activeDB);
	}

	getCurrentLang() {
		return this.translateService.currentLang;
	}

	getDefaultLang() {
		return this.translateService.getDefaultLang();
	}

	setActiveDB(db: PouchDB.Database) {
		this._db = db;
	}

	get(some: string) {
		const guid = this.loaderService.populateLoader(some);
		return new Promise(resolve => {
			this._db
				.get(some)
				.then(res => {
					this.loaderService.changeSingleLoader(guid);
					resolve(res);
				})
				.catch(error => {
					this.loaderService.changeSingleLoader(guid);
					// console.log(error);
				});
		});
	}

	async getWithIndexFilter(
		some: string,
		filters: PouchDB.Find.Selector,
		pagination?: Pagination,
		sort?: IStringDictionary<'asc' | 'desc'>[] | string[]
	) {
		const guid = this.loaderService.populateLoader(some);
		if (!pagination) {
			pagination = {
				"page_current": 1,
				"page_size": 999
			};
		}

		const indexId: string = await this.findIndex(filters);
		const indexName = indexId.replace('_design/', '');
		return new Promise(resolve => {
			let filterFromIndex: PouchDB.Find.FindRequest<{}> = {
				selector: filters,
				sort: sort
			};
			if (indexName) {
				filterFromIndex = {
					...filterFromIndex,
					use_index: indexName
				};
			}

			filterFromIndex = {
				...filterFromIndex,
				limit: pagination.page_size,
				skip: pagination.page_size * (pagination.page_current - 1)
			};

			this._db
				.find(filterFromIndex)
				.then(res => {
					this.loaderService.changeSingleLoader(guid);
					resolve(res);
				})
				.catch(err => {
					this.loaderService.changeSingleLoader(guid);
					console.log('error find' + err);
				});
		});
	}

	put<T>(body: T, some: string, useGuid: boolean = false): Promise<any> {
		const guid = this.loaderService.populateLoader(some);
		body['_id'] = useGuid ? some + '_' + guid : some;
		return new Promise(resolve => {
			this._db
				.put(body)
				.then(response => {
					this.loaderService.changeSingleLoader(guid);
					this.get(response.id).then(res => {
						resolve(res);
					});
				})
				.catch(error => {
					this.loaderService.changeSingleLoader(guid);
					// console.log(error);
				});
		});
	}

	delete(target: any): Promise<any> {
		return new Promise(resolve => {
			this._db
				.remove(target)
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					// console.log(error);
				});
		});
	}

	findBestIndexName(allIndexes: PouchDB.Find.Index[], flatIndex: IStringDictionary<string>[]) {
		let bestIndexName: string;
		let bestIndexScore = 0.0;
		allIndexes.some(index => {
			const indexFields = index.def.fields;
			let matchingKeys = 0;
			for (let i = 0; i < flatIndex.length && i < indexFields.length; i++) {
				const indexFieldKey = Object.keys(indexFields[i])[0];
				const flatIndexKey = Object.keys(flatIndex[i])[0];
				if (indexFieldKey === flatIndexKey) {
					matchingKeys += 1;
				} else {
					break;
				}
			}
			const score = matchingKeys / flatIndex.length;

			if (score > bestIndexScore) {
				bestIndexScore = score;
				bestIndexName = index.ddoc;
			}
			return bestIndexScore === 1;
		});
		return bestIndexName;
	}

	async findIndex(filter: PouchDB.Find.Selector) {
		const indexName = '';
		return this.explainSelector(filter)
		.then(res => {
			const explainIndex = res;
			const flatIndex: IStringDictionary<string>[] = this.explainIndexToFlatIndex(explainIndex);

			return this._db
			.getIndexes()
			.then(indexesRes => {
				return this.findBestIndexName(indexesRes.indexes, flatIndex);
			})
			.catch(function(err) {
				console.log(err);
				return indexName;
			});
		});
	}

	explainIndexToFlatIndex(explainIndex): IStringDictionary<string>[] {
		const  normalizedSelector = this.getExplainIndexSelector(explainIndex);
		const flatIndex = this.findIndexKeysFromNormalizedSelector(normalizedSelector);
		const flatIndexNoDups = this.removeDuplicatesKeys(flatIndex);
		return flatIndexNoDups;
	}

	getExplainIndexSelector(explainIndex): PouchDB.Find.Selector {
		return explainIndex.selector;
	}

	isCombinationOperator(operator: string) {
		return this.combinationOperators.includes(operator);
	}

	isConditionOperator(operator: string) {
		return this.conditionOperators.includes(operator);
	}

	isKey(key: string) {
		return !this.isCombinationOperator(key) && !this.isConditionOperator(key);
	}

	findIndexKeysFromNormalizedSelector(selector: PouchDB.Find.Selector, parent: string = '') {
		let flatIndex: any[] = [];

		Object.keys(selector).forEach(key => {
			if (this.isCombinationOperator(key)) {
				// Go down
				selector[key].forEach(innerSelector => {
					flatIndex = flatIndex.concat(this.findIndexKeysFromNormalizedSelector(innerSelector));
				});
			} else if (key === '$elemMatch') {
				// Append .[] and go down
				parent = parent.concat('.[]');
				flatIndex = flatIndex.concat(this.findIndexKeysFromNormalizedSelector(selector[key], parent));
			} else if (this.isKey(key)) {
				const parentKey = parent.concat('.' + key);
				const childFlatIndex = this.findIndexKeysFromNormalizedSelector(selector[key], parentKey);
				flatIndex = flatIndex.concat(childFlatIndex);
				if (childFlatIndex.length === 0) {
					flatIndex.push({});
					flatIndex[flatIndex.length - 1][parent + (parent ? '.' : '') + key] = 'asc';
				}
			}
		});
		return flatIndex;
	}

	removeDuplicatesKeys(anArray: IStringDictionary<string>[]): IStringDictionary<string>[] {
		const result: IStringDictionary<string>[] = [];
		anArray.forEach(element => {
			if (!result.find(toInsert => Object.keys(toInsert)[0] === Object.keys(element)[0])) {
				result.push(element);
			}
		});
		return result;
	}

	filterToFlatIndexKey(filter: PouchDB.Find.Selector, parent: string = ''): IStringDictionary<string>[] {
		let flatIndex: any[] = [];
		Object.keys(filter).forEach(key => {
			// control if the key start with the $ char
			const reg = key.match(/^\$/);
			if (typeof filter[key] === 'object') {
				if (!reg && isNaN(+key)) {
					parent = key;
				}
				if (key === '$elemMatch') {
					parent = parent.concat('.[]');
				}
				const newFoundFlatIndexes: any[] = this.filterToFlatIndexKey(filter[key], parent);
				// Removes duplicated keys
				const toAddFlatIndexes: any[] = [];
				newFoundFlatIndexes.forEach(newFoundObj => {
					const alreadyIn = flatIndex.find(obj => Object.keys(obj)[0] === Object.keys(newFoundObj)[0]);
					if (!alreadyIn) {
						toAddFlatIndexes.push(newFoundObj);
					}
				});
				flatIndex = flatIndex.concat(toAddFlatIndexes);
			} else {
				if (!reg) {
					flatIndex.push({});
					flatIndex[flatIndex.length - 1][parent + (parent ? '.' : '') + key] = 'asc';
				}
			}
		});
		if (flatIndex.length === 0) {
			flatIndex.push({});
			flatIndex[flatIndex.length - 1][parent] = 'asc';
		}
		return flatIndex;
	}

	explainSelector(selector: PouchDB.Find.Selector): Promise<any> {
		const findRequest: PouchDB.Find.FindRequest<{}> = {
			selector: selector
		};
		return new Promise(resolve => {
			this._db
			.explain(findRequest)
			.then(res => {
				resolve(res);
			});
		});
	}
}
