import { NgModule } from "@angular/core";
import { IframeResolverComponent } from "./iframe-resolver.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  declarations: [
    IframeResolverComponent
  ],exports:[
    IframeResolverComponent
  ]
})

export class IframeResolverModule {}
