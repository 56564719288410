import { createReducer, on } from '@ngrx/store';
import * as fromCompanyList from './company-list.actions';
// model
import { CompanyPouchListModel } from "../../model/pouch/company-pouch.model";

export interface CompanyListState extends CompanyPouchListModel {}

export const initialState: CompanyListState = null;

const _reducer = createReducer(initialState,
  on(fromCompanyList.update, (state, companyListState) => companyListState),
  on(fromCompanyList.load, (state, companyListState) => companyListState),
  on(fromCompanyList.reset, (state) => initialState)
);

export function reducer(state, action) {
  return _reducer(state, action);
}
