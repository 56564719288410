import { createAction, props } from '@ngrx/store';
// model
import { UserState } from './user.reducer';

export const update = createAction(
  '[User] Update',
  props<UserState>()
);
export const load = createAction(
  '[User] Load',
  props<UserState>()
);
export const reset = createAction(
  '[User] Reset'
);
