import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { TokenPayload } from '../../model/login.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromUser from '../../state/user/user.actions';

@Injectable()
export class AuthService {

  @LocalStorage('authenticationToken')
  authenticationToken: string;

  private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
  tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

  @LocalStorage('payload')
  private _tokenPayload: TokenPayload;

  byPassAuthGuard: boolean = false;

  constructor(
    public http: HttpClient,
    private store: Store<any>,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  logout() {
    this.router.navigate(['/authentication/login']);
    this.localStorageService.clear('authenticationToken');
    this.localStorageService.clear('payload');
    this.store.dispatch(fromUser.reset());

    // cancellazione info relative alle routes navigabili
    const routes = this.router.config;
    routes[1].children = [];
    this.router.resetConfig(routes);
    this.byPassAuthGuard = false;
  }

  get tokenPayload(): TokenPayload {
    if (!this._tokenPayload) {
      return undefined;
    }
    return new TokenPayload(this._tokenPayload);

  }

  set tokenPayload(tk: TokenPayload) {
    if (tk) {
      this._tokenPayload = new TokenPayload(tk);
    } else {
      this._tokenPayload = null;
    }
    this.tokenPayloadChange.next(this._tokenPayload);
  }
}
