import { OnDestroy } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { BaseTableListService } from '../base-table-list.service';

export class ProductCatalogColumnService extends BaseTableListService implements OnDestroy {
	onLangChange$: Subscription;
	constructor(private translate: TranslateService) {
		super();
		this.onLangChange$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.columns = [
				{
					name: 'code',
					label: this.translate.instant('columnsData.ref'),
					width: 110
				},
				{
					name: 'description',
					label: this.translate.instant('columnsData.description')
				},
				{
					name: 'norma',
					label: this.translate.instant('columnsData.standard'),
					width: 110
				},
				{
					name: 'material',
					label: this.translate.instant('columnsData.material'),
					width: 110
				},
				{
					name: 'finish_coating',
					label: this.translate.instant('columnsData.coating'),
					width: 110
				},
				{
					name: 'range',
					label: this.translate.instant('columnsData.dimension'),
					width: 110
				}
			];
		});
	}

	columns: ITdDataTableColumn[] = [
		{
			name: 'code',
			label: this.translate.instant('columnsData.ref'),
			width: 110
		},
		{
			name: 'description',
			label: this.translate.instant('columnsData.description')
		},
		{
			name: 'norma',
			label: this.translate.instant('columnsData.standard'),
			width: 110
		},
		{
			name: 'material',
			label: this.translate.instant('columnsData.material'),
			width: 110
		},
		{
			name: 'finish_coating',
			label: this.translate.instant('columnsData.coating'),
			width: 110
		},
		{
			name: 'range',
			label: this.translate.instant('columnsData.dimension'),
			width: 110
		}
	];

	ngOnInit(): void {}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
	}
}
