import { createReducer, on } from '@ngrx/store';
import * as fromTagList from './tag-list.actions';
// model
import { TagListPouchModel } from '../../model/pouch/tag.model';

export interface TagListState extends TagListPouchModel {}

export const initialState: TagListState = {}

const _reducer = createReducer(initialState,
  on(fromTagList.load, (state) => state),
  on(fromTagList.update, (state, tags) => {
    return tags;
 })
);

export function reducer(state, action) {
  return _reducer(state, action);
}
