import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import PouchDB from 'pouchdb';
import Find from 'pouchdb-find';
import { BehaviorSubject } from 'rxjs';

import { AppConfig, ConfigCouchModel } from '../../../app.config';
import { InjectorModule } from '../../../injector.module';
import { AuthService } from '../../rest/auth.service';
import { TokenPayload } from '../../../model/login.model';

declare function require(name: string);

PouchDB.plugin(require('pouchdb-upsert'));
PouchDB.plugin(Find);

/** Classe per gestire la comunicazione e gli stati della stessa con il remoto e l'eventuale replica locale */

export abstract class PouchDbAdapter {
	protected deviceService: DeviceDetectorService;
	protected authService: AuthService;
	protected appConfig: ConfigCouchModel;

	protected _pouchDB: PouchDB.Database;
	protected _couchDB: PouchDB.Database;
	private _activeDB: PouchDB.Database;

	protected _pouchDbName: string;
	protected _couchConfig: ConfigCouchModel;

	abstract database: string;

	// rxjs behaviour subjects to expose stats flags
	syncStatus = new BehaviorSubject<boolean>(false);
	couchDbUp = new BehaviorSubject<boolean>(false);

	constructor() {
		// this.initLocalPouch();
		// this.initRemoteCouch();
		// this.syncLocalWithRemote();
	}

	protected async initConnection(couchConfig: ConfigCouchModel) {
		this._couchConfig = couchConfig;
		this._pouchDbName = this._couchConfig.database;

		this.deviceService = InjectorModule.injector.get(DeviceDetectorService);
		this.authService = InjectorModule.injector.get(AuthService);

		await this.initRemoteCouch();
	}

	async initDb(config: ConfigCouchModel) {
		this.appConfig = config;
		this.initConnection(config);
		await this.setDB();
		this.initFunctions();
	}

	abstract initFunctions(): void;

	/**
	 * Get complete url of db
	 *
	 * @return string
	 */
	protected get remoteCouchDBUrl(): string {
		if (!this._couchConfig.endpoint || !this._pouchDbName) {
			return null;
		}
		return this._couchConfig.endpoint + '/' + this._pouchDbName;
	}

	/**
	 * Get auth token by username and key secret
	 *
	 * @return string
	 */
	protected get authToken(): string {
		if (!this._couchConfig.authSecret || !this._couchConfig.authUsername) {
			return null;
		}

		return CryptoJS.HmacSHA1(this._couchConfig.authUsername, this._couchConfig.authSecret).toString();
	}

	initLocalPouch() {
		// new PouchDB(this._pouchDbName).destroy().then(function () {
		//     console.log('Local DB is Distroyed!!!!!!');
		// }).catch(function (err) {
		//     // error occurred
		// });

		let options = {};
		if (this.deviceService.browser === 'safari') {
			options = { size: 50, adapter: 'websql' };
		}
		this._pouchDB = new PouchDB(this._pouchDbName, options);
	}

	async initRemoteCouch() {
		this._couchDB = new PouchDB(this.remoteCouchDBUrl, {
			skip_setup: true, // Disable the accidental DB creation
			fetch: (url, opts: any) => {
				opts.headers.set('x-auth-couchdb-username', this.authService.tokenPayload.username);
				opts.headers.set('x-auth-couchdb-roles', '');
				opts.headers.set('x-auth-couchdb-token', this.authService.tokenPayload.signature);
				return PouchDB.fetch(url, opts);
			}
		});
	}

	syncLocalWithRemote() {
		this._pouchDB
			.sync(this._couchDB, {
				live: true,
				retry: true
			})
			.on('change', info => {
				console.log('SYNC CHANGE: ', info);
				this.syncStatusUpdate();
			})
			.on('paused', err => {
				this.syncStatusUpdate();
			})
			.on('error', err => {
				// TODO: Write error handling and display message to user
				console.error('SYNC Error: ', err);
			})
			.on('active', () => {
				// TODO: Write code when sync is resume after pause/error
				console.log('C2P Resume');
			});
	}

	watchAllRemoteChanges() {
		const changes = this._couchDB
			.changes({
				since: 'now',
				live: true,
				include_docs: true
			})
			.on('change', change => {
				console.log('syncro');
				console.log(change);
				// this.LocalDb.put(change.doc).then( response => console.log(response));
				// handle change
			})
			.on('complete', info => {
				console.log('complete', info);
				// changes() was canceled
			})
			.on('error', err => {
				console.log('error', err);
			});
	}

	deleteRemote() {
		return this._couchDB.destroy();
	}

	// function to call the below functions
	// then update the rxjs BehaviourSubjects with the
	// results
	private syncStatusUpdate(): void {
		this.checkPouchCouchSync().then(result => {
			this.syncStatus.next(result);
		});
		this.checkCouchUp().then(result => {
			this.couchDbUp.next(result);
		});
	}

	// part of the JSON returned by PouchDB from the info() method
	// is 'update_seq'. When these numbers are equal then the databases
	// are in sync. The way its buried in the JSON means some string
	// functions are required to extract it
	private checkPouchCouchSync(): Promise<boolean> {
		// if both objects exist then make a Promise from both their
		// info() methods
		if (this._pouchDB && this._couchDB) {
			return (
				Promise.all([this._pouchDB.info(), this._couchDB.info()])
					// using the 0 and 1 items in the array of two
					// that is produced by the Promise
					// Do some string trickery to get a number for update_seq
					// and return 'true' if the numbers are equal.
					.then((results: any[]) => {
						return (
							Number(String(results[0].update_seq).split('-')[0]) ===
							Number(String(results[1].update_seq).split('-')[0])
						);
					})
					// on error just resolve as false
					.catch(error => false)
			);
		} else {
			// if one of the PouchDB or CouchDB objects doesn't exist yet
			// return resolve false
			return Promise.resolve(false);
		}
	}

	// fairly self explanatory function to make a
	// GET http request to the URL and return false
	// if an error status or a timeout occurs, true if
	// successful.
	private checkCouchUp(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', this.remoteCouchDBUrl, true);
			xhr.setRequestHeader('x-auth-couchdb-token', this.authService.tokenPayload.signature);
			xhr.setRequestHeader('x-auth-couchdb-username', this.authService.tokenPayload.username);
			xhr.onload = () => {
				if (xhr.status >= 200 && xhr.status < 300) {
					resolve(true);
				} else {
					resolve(false);
				}
			};
			xhr.onerror = () => {
				resolve(false);
			};
			xhr.send();
		});
	}

	/**
	 * use it to explicit set the database target
	 */
	protected setExplicitDB(local: boolean) {
		this._activeDB = local ? this._pouchDB : this._couchDB;
	}

	/**
	 * If the remote db isn't reachable it returns local db
	 * else it returns the activeDB.
	 * If also the activeDB doesn't exist it returns the remote DB
	 */
	protected setDB(): Promise<PouchDB.Database> {
		return new Promise(resolve => {
			this.checkCouchUp()
				.then(result => {
					this.couchDbUp.next(result);
					if (!result) {
						resolve((this._activeDB = this._pouchDB));
					} else {
						resolve((this._activeDB = this._activeDB ? this._activeDB : this._couchDB));
					}
				})
				.catch(() => {
					resolve((this._activeDB = this._pouchDB));
				});
		});
	}

	protected getDB() {
		return this._activeDB;
	}
}
