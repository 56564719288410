import { Component, OnInit } from '@angular/core';
import { CardTrendConfigurationModel, CardArrayItemModel } from '../../../widget/card-trend/card-trend.component';
import { ApexOptions } from 'ng-apexcharts';
import { CardChartConfigurableConfigurationModel, CardChartConfigurableConfigurationSelected } from '../../../widget/card-chart-configurable/card-chart-configurable.component';
import moment from 'moment';

@Component({
  selector: 'company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
// MOCKUP
  // row 1
  cardTrendArray: CardArrayItemModel[] = [
    {
      cardTrendConfiguration: {
        //theme: "mat-accent",
		title: "Ordini",
        subtitle: "Bozza",
        trend: -7.20,
        time: "Questa settimana",
        value: '6',
      },

      main: {
        chart: {
		  type: 'bar',
          height: 200,
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: "smooth",
          width: 0,
          dashArray: 0,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false, // true per i valori sugli assi
            offsetY: -25,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        colors: ['#1664af'],

        xaxis: {
          categories: ["L", "M", "M", "G", "V", "S", "D"],
          position: 'bottom',
          labels: {
            offsetY: 0,
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
              show: true
          },
        },

        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
               show: true
            }
        },
        tooltip: {
            enabled: true,
            offsetY: -35,

        },
        grid: {
          show: true
        },
        series: [
          {
            name: 'Ordini in bozza',
            data: [0, 0, 1, 1, 4, 0, 0]
          }
        ]
      }
    },
    {
      cardTrendConfiguration: {
        title: "Ordini",
        subtitle: "Lavorazione",
        trend: +20.00,
        time: "Questa settimana",
        value: '5',
      },
      main: {
        chart: {
          type: 'bar',
          height: 200,
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: "smooth",
          width: 0,
          dashArray: 0,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false, // true per i valori sugli assi
            offsetY: -25,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        colors: ['#1664af'],

        xaxis: {
          categories: ["L", "M", "M", "G", "V", "S", "D"],
          position: 'bottom',
          labels: {
            offsetY: 0,
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
              show: true
          },
        },

        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
               show: true
            }
        },
        tooltip: {
            enabled: true,
            offsetY: -35,

        },
        grid: {
          show: true
        },
        series: [
          {
            name: ['Ordini in lavorazione'],
            data: [0, 0, 1, 1, 3, 0, 0]
          }
        ]
      }
    },
    {
      cardTrendConfiguration: {
        title: "Ordini",
        subtitle: "Bloccati",
        trend: +1.20,
        time: "Questa settimana",
        value: '1',
      },
      main: {
        chart: {
          type: 'bar',
          height: 200,
          toolbar: {
            show: false
          },
        },
        stroke: {
          curve: "smooth",
          width: 0,
          dashArray: 0,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false, // true per i valori sugli assi
            offsetY: -25,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        colors: ['#1664af'],

        xaxis: {
          categories: ["L", "M", "M", "G", "V", "S", "D"],
          position: 'bottom',
          labels: {
            offsetY: 0,
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
              show: true
          },
        },

        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
               show: true
            }
        },
        tooltip: {
            enabled: true,
            offsetY: -35,

        },
        grid: {
          show: true
        },
        series: [
          {
            name: 'Ordini Bloccati',
            data: [0, 0, 0, 0, 1, 0, 0]
          }
        ]
      }
    },
    {
      cardTrendConfiguration: {
        title: "Clienti attivi",
        subtitle: "Almeno un ordine negli ultimi 30 giorni",
        trend: 7.2,
        time: "",
        value: '120',
      },
      main: {
        chart: {
		  type: 'line',
		  height: 200,
          toolbar: {
            show: false
          }
        },
        colors: ["#1664af"],
        stroke: {
          curve: 'smooth',
          width: 3,
          dashArray: 0,
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          followCursor: true,
          x: {
            show: false
          },
          fixed: {
            enabled: false,
            position: 'topLeft'
          },
        },
        grid: {
          show: false
        },
        series: [
          {
            name: 'Clienti Attivi',
            data: [50, 20, 50, 30, 5, 8, 13, 21, 34]
          }
        ]
      }
    },
    {
      cardTrendConfiguration: {
        title: "Clienti inattivi",
        subtitle: "Nessun ordine negli ultimi 90 giorni",
        trend: 7.2,
        time: "",
        value: '55',
        theme: "mat-accent",
      },
      main: {
        chart: {
		  type: 'line',
		  height: 200,
          toolbar: {
            show: false
          }
        },
        colors: ['#FAFAFA'],
        stroke: {
          curve: 'smooth',
          width: 3,
          dashArray: 0,
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          followCursor: true,
          x: {
            show: false
          },
          fixed: {
            enabled: false,
            position: 'topLeft'
          },
        },
        grid: {
          show: false
        },
        series: [
          {
            name: 'Clienti Inattivi',
            data: [8, 1, 2, 3, 5, 8, 13, 21, 34]
          }
        ]
      }
    }
  ];
  // row 2
//   companyMostActiveSemesterChart: ApexOptions = {
//     chart: {
//       type: "donut",
//     },
//     legend: {
//       position: 'bottom',
//       offsetY: -10,
//     },
//     colors: ["#1664af", "#888888", "#0068b9", "#ff9800", "#078BBD"],
//     fill: {
//       colors: ["#1664af", "#888888", "#0068b9", "#ff9800", "#078BBD"],
//     },
//     series: [1, 2, 3, 5, 8],
//     labels: ["Company 1", "Company 2", "Company 3", "Company 4", "Company 5"],
//   }

  // row 3
  salesCardChartConfigurableConfiguration: CardChartConfigurableConfigurationModel = {
		title: "Vendite totali",
		apexChart: {
			chart: {
				type: "line",
				toolbar: {
					show: false
        }
			},
			colors: ["#0068b9", "#888888"],
			stroke: {
				curve: "smooth",
				width: 3,
				dashArray: 0,
			},
			xaxis: {
        categories: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
				labels: {
					show: true
				},
				axisBorder: {
					show: true
				}
			},
			yaxis: {
				labels: {
					show: false
				},
				axisBorder: {
					show: true
				}
			},
			tooltip: {
				followCursor: true,
				x: {
					show: true
				},
				fixed: {
					enabled: true,
					position: "topLeft"
				},
			},
			grid: {
				show: true
			},
			series: [{
        name: "2018",
        data: [29, 33, 36, 32, 32, 33, 28, 29, 33, 36, 32, 33]
    },
    {
        name: "2019",
        data: [33, 11, 14, 50, 66, 70, 66, 33, 11, 14]
    }
],
    },
    datasetPeriod: [
			{
				label: "Ultimi 3 mesi",
				start: +moment().subtract(3, "months").format("x"),
				end: +moment().format("x"),
				selected: true
			},
			{
				label: "Ultimi 6 mesi",
				start: +moment().subtract(6, "months").format("x"),
				end: +moment().format("x")
			},
			{
				label: "Ultimi 12 mesi",
				start: +moment().subtract(12, "months").format("x"),
				end: +moment().format("x")
			}
		]
  };

  cardChartConfigurableConfiguration: CardChartConfigurableConfigurationModel = {
		title: "Migliori Categorie Vendute",
		apexChart: {
			chart: {
		type: "bar",
				toolbar: {
					show: false
        }
      },

      plotOptions: {
          bar: {
              horizontal: false,
              columnWidth: '80%',
          },
      },

      dataLabels: {
        enabled: true
      },
      colors: ["#1664af", "#888888"],
/*
			xaxis: {
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: false
        },

			},
			tooltip: {
				followCursor: true,
				x: {
					show: false
				},
			},
			grid: {
				show: true,
			},*/
			series: [{
        name: '2018',
        data: [44, 55, 57, 56, 61]
        }, {
            name: '2019',
            data: [76, 85, 101, 98, 87]
      }],
      xaxis: {
        categories: ['Cat1', 'Cat2', 'Cat3', 'Cat4', 'Cat5'],
      },
      fill: {
        opacity: 1
      },
		},
		datasetType: [
			{
				label: "Numero di Pezzi",
				id: "pieces",
				icon: "details",
				selected: true
			},
			{
				label: "Valore Economico",
				icon: "attach_money",
				id: "value"
			}
		],
		datasetPeriod: [
			{
				label: "Ultimi 3 mesi",
				start: +moment().subtract(3, "months").format("x"),
				end: +moment().format("x"),
				selected: true
			},
			{
				label: "Ultimi 6 mesi",
				start: +moment().subtract(6, "months").format("x"),
				end: +moment().format("x")
			},
			{
				label: "Ultimi 12 mesi",
				start: +moment().subtract(12, "months").format("x"),
				end: +moment().format("x")
			}
		]
	}
  // MOCKUP END

  constructor() { }

  ngOnInit() {
  }

  /**
	 * CardChartConfigurableConfiguration: metodo da invocare dopo la get dei dati
	 *
	 */
	onCardChartConfigurableConfigurationChange(e: CardChartConfigurableConfigurationSelected) {
		console.log(e);
	}

}
