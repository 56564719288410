import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FileTypeEnum } from '../../../enum/fileType.enum';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { UtilService } from '../../../service/util/util.service';
import * as fromState from '../../../state/index';
import { UserState } from '../../../state/user/user.reducer';
import { skipWhile, map } from 'rxjs/operators';
import { BOConfigurationService } from '../../../service/rest/backoffice/BOconfiguration.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

export interface CardItem {
	type?: string; // query param per la chiamata
	title: string;
	icon: string;
	button: {
		disabled: boolean;
		label: string;
		action?: (value: any) => any;
	};
	upload?: {
		multiple: boolean;
		acceptedType: FileTypeEnum;
		data: string;
	};
	permission: string[];
}

@Component({
	selector: 'data-configuration',
	templateUrl: './data-configuration.component.html',
	styleUrls: ['./data-configuration.component.scss'],
	providers: [SubscribeManagerService]
})
export class DataConfigurationComponent implements OnInit, OnDestroy {
	@LocalStorage('language') language: string;
	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	user: UserState;

	languages = ['it', 'en'];
	selectedLang: string;

	cardList: CardItem[];

	constructor(
		private boConfigurationService: BOConfigurationService,
		public utilService: UtilService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private translate: TranslateService,
	) {
		this.subscribeManagerService.populate(this.subscribeUser().subscribe(), 'user');
		// Subscribe del queryparams lang da passare alle chiamate per il recupero della linga corretta delle famiglie
		// priorità lang: 1 queryparam - 2 localStorage - 3 Browser - 4 default (en)
		this.route.queryParams.subscribe(params => {
			// default
			if (this.languages.includes(this.languages[1])) {
				this.selectedLang = this.languages[1];
			}
			// browser
			if (this.languages.includes(this.translate.getBrowserLang())) {
				this.selectedLang = this.translate.getBrowserLang();
			}
			// salvata nel local storage
			if (this.languages.includes(this.language)) {
				this.selectedLang = this.language;
			}
			// query param
			if (params.lang && this.languages.includes(params.lang)) {
				this.selectedLang = params.lang;
			}
			this.translate.use(this.selectedLang);
			this.cardList = [
				{
					title: 'Carica Catalogo',
					icon: 'storefront',
					type: 'catalog-form',
					upload: {
						multiple: false,
						acceptedType: FileTypeEnum.EXCEL,
						data: ''
					},
					button: {
						disabled: true,
						label: 'Conferma caricamento',
						action: (index: number) => this.uploadCatalogTree(index, this.selectedLang)
					},
					permission: [
						'bo-configuration'
					]
				}
			];
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	subscribeUser() {
		return this.user$.pipe(
			skipWhile((user: UserState) => !user),
			map((user: UserState) => {
				this.user = user;
			})
		);
	}

	uploadCatalogTree(index, lang) {
		this.boConfigurationService.uploadCatalogTree(
			this.cardList[index].upload.data[0],
			{type: this.cardList[index].type, 
			language: lang},
			res => {
				this.snackBar.open(
					res.message, this.translateService.instant('general.close').toUpperCase(),
					{ duration: 10000 }
				);
			},
			err => {
				this.snackBar.open(
					err.body.message, this.translateService.instant('general.close').toUpperCase(), 
					{ duration: 10000 }
				);
			}
		);
	}

	// EVENTS

	onAttachmentResolverFileChange(e: string, index: number) {
		this.cardList[index].button.disabled = e.length === 0;
		this.cardList[index].upload.data = e;
	}

}