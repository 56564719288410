import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';

// NgRX - Store
// import { Store } from '@ngrx/store';
// import * as fromState from '../../../state/index';
// import * as fromCompany from '../../../state/company/company.actions';
// import { CompanyPouchModel } from '../../../model/pouch/company-pouch.model';
@Component({
  selector: 'company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  // companyData$: Observable<CompanyPouchModel>;
  companyCode: string;
  companyData$;

  constructor(
    private route: ActivatedRoute,
    // private store: Store<any>
  ) {
    this.companyCode = this.route.snapshot.paramMap.get('idCompany');
    // this.companyData$ = this.store.select(fromState.getCompanyState);
    this.companyData$ = from([
      {
      addebito_spese_trasporto: 'xxx',
      codice: 'xxx',
      codice_agente: 'xxx',
      codice_azienda: 'xxx',
      codice_fiscale: 'xxx',
      codice_listino: 'xxx',
      // condizione_pagamento?: string;
      destinazioni: [],
      divisione: 'xxx',
      evasione_stralci: 'xxx',
      fido: 30,
      indirizzo: {}, // indirizzo
      interno_estero: 'xxx',
      invio_conferma_ordine: 'xxx',
      modalita_consegna: 'xxx',
      modalita_spedizione: 'xxx',
      nazione_cliente: 'xxx',
      partita_iva: 'xxx',
      raggrup_bolle_fattura: 'xxx',
      raggrup_ordini_bolla: 'xxx',
      ragione_sociale: 'Azienda 1',
      ragione_sociale_estesa: 'xxx',
      recapiti: {}, //recapito
      riferimento_interno : 'xxx',
      stampa_conferma_ordine: 'xxx',
      tipo_fornitura_cliente_b: 'xxx',
      type: 'xxx',
      // users?: User[];
      visibile_agenti: 'xxx',
      visibile_web: 'xxx',
      zona_commerciale: 'xxx',
      _id: 'xxx',
      _rev: 'xxx',
  
      // nuove aggiunte per il modello dati
      orders_state: [],
      average_purchase: 30,
      electronic_invoicing_ref: 'xxx',
      referent: {}, //PersonModel;
      status: 'xxx',
      due: 3,
      due_expired: 4,
      condizione_pagamento: [], //Pagamento[];
      users: [], // PersonModel[],
    }]);
    // this.companyData$.subscribe((res: CompanyPouchModel) => {
    //   if(!res.codice && this.companyCode) this.store.dispatch(fromCompany.load({code: this.companyCode}));
    // });
  }

  ngOnInit() {
  }

  // ngOnDestroy() {
    // this.store.dispatch(fromCompany.update(null));
    // this.store.dispatch(fromCompany.reset());
  // }

}
