import { Router } from '@angular/router';
import { MediaReplayService } from './../../../../service/util/media-replay.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITdDataTableColumn } from '@covalent/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { FamilyPouchModel } from '../../../../model/pouch/family-pouch.model';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { UtilCatalogService } from '../../../../service/util/util-catalog-filter.service';
import * as fromState from '../../../../state';
import * as fromArticleList from '../../../../state/article-list/article-list.actions';
import { ArticleListState } from '../../../../state/article-list/article-list.reducer';
import { ArticleNewQuantity } from '../dialog-catalog-family/dialog-catalog-family-list/dialog-catalog-family-list.component';
import { DialogCatalogFamilyComponent } from '../dialog-catalog-family/dialog-catalog-family.component';
import { FamilyListState } from './../../../../state/family-list/family-list.reducer';

export interface SortGroup {
	sort_group: number;
	list: FamilyListState;
}

export interface FamilyCatalogFilters {
	textField?: string;
	familyCodes?: string[];
}

export interface ArticleFamilyCatalogFilters {
	familyCode?: string;
}

@Component({
	selector: 'catalog-family',
	templateUrl: './catalog-family.component.html',
	styleUrls: ['./catalog-family.component.scss'],
	providers: [SubscribeManagerService]
})
export class CatalogFamilyComponent implements OnInit, OnDestroy {
	@Input() articlesTableColumns: ITdDataTableColumn[];

	@Output() familyFiltersEmitter = new EventEmitter<FamilyCatalogFilters>();
	@Output() articleFamilyFiltersEmitter = new EventEmitter<ArticleFamilyCatalogFilters>();

	@Output() articleQuantityChangeEmitter = new EventEmitter<ArticleNewQuantity>();

	family$: Observable<FamilyListState>;
	family: FamilyListState = {};
	selectedFamily: FamilyPouchModel;

	articleList$: Observable<ArticleListState>;
	articleList: ArticleListState = {};

	sortGroup: SortGroup[] = [];

	get isPublic(): boolean {
		return this.router.url.split('?')[0] === '/public/catalog';
	}

	constructor(
		public mediaReplayService: MediaReplayService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilCatalogService: UtilCatalogService,
		private dialog: MatDialog,
		public router: Router,
	) {
		this.family$ = this.store.select(fromState.getFamilyListState);
		this.articleList$ = this.store.select(fromState.getArticleListState);

		this.subscribeManagerService.populate(this.initMandatoryData().subscribe(), 'catalog-family-init');
		this.subscribeManagerService.populate(this.retrieveArticleList().subscribe(), 'catalog-family-article-list');
	}

	ngOnInit() {}

	initMandatoryData(): Observable<FamilyListState> {
		return this.family$.pipe(
			skipWhile((families: FamilyListState) => !(families && families.data)),
			map((families: FamilyListState) => {
				this.family.data = families.data;
				this.sortGroup = this.utilCatalogService.divideFamiliesBySortGroup(families.data);
				return families;
			})
		);
	}

	retrieveArticleList(): Observable<ArticleListState> {
		return this.articleList$.pipe(
			skipWhile((articles: ArticleListState) => !(articles && articles.data)),
			map((articles: ArticleListState) => {
				this.articleList.data = articles.data;
				if (this.articleList.data.length > 0) {
					this.openModalArticle();
				}
				return articles;
			})
		);
	}

	getCatalogFamilySortedbyGroup() {
		return this.sortGroup;
	}

	catalogFamilyFiltersChange(filters: FamilyCatalogFilters) {
		this.familyFiltersEmitter.emit(filters);
	}

	articleFamilyRowSelected(articleFamily: FamilyPouchModel) {
		const selectedArticleFilter: ArticleFamilyCatalogFilters = {};
		selectedArticleFilter.familyCode = articleFamily.code;
		this.selectedFamily = articleFamily;
		this.articleFamilyFiltersEmitter.emit(selectedArticleFilter);
	}

	// TASK 190
	openModalArticle() {
		if (this.selectedFamily) {
			const dialog = this.dialog.open(DialogCatalogFamilyComponent, {
				data: {
					family: this.selectedFamily,
					articleList: this.articleList.data,
					columns: this.articlesTableColumns
				},
				panelClass: 'dialog-fullscreen-95'
				// disableClose: false
			});
			const dialogSub$ = dialog.componentInstance.onArticleQuantityChange.subscribe(
				(articleNewQuantity: ArticleNewQuantity) => {
					this.articleQuantityChangeEmitter.emit(articleNewQuantity);
				}
			);
			dialog.afterClosed().subscribe(res => {
				this.store.dispatch(fromArticleList.reset());
				dialogSub$.unsubscribe();
			});
		}
	}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
