import { Injectable } from '@angular/core';

import { OrderChart } from '../chart/order.chart';
import { PouchDbAdapter } from '../structure/pouchdb-adapter';
import { ArticlePouch } from './pouch-function/article.pouch';
import { TabelleAusiliariePouch } from './pouch-function/auxiliary-tables.pouch';
import { CategoriesPouch } from './pouch-function/categories.pouch';
import { CodiceClientePouch } from './pouch-function/codice-cliente.pouch';
import { ContactPouch } from './pouch-function/contact.pouch';
import { DestinationListPouch } from './pouch-function/destination.pouch';
import { FamilyPouch } from './pouch-function/family.pouch';
import { OrderPouch } from './pouch-function/order.pouch';
import { PricePouch } from './pouch-function/price.pouch';
import { TagListPouch } from './pouch-function/tag.pouch';

@Injectable()
export abstract class PouchDbBaseAdapter extends PouchDbAdapter {
	orderPouch: OrderPouch;
	tabelleAusiliariPouch: TabelleAusiliariePouch;
	codiceClientePouch: CodiceClientePouch;
	articlePouch: ArticlePouch;
	categoriesPouch: CategoriesPouch;
	destinationListPouch: DestinationListPouch;
	countactPouch: ContactPouch;
	tagListPouch: TagListPouch;
	familyPouch: FamilyPouch;
	pricePouch: PricePouch;
	orderChart: OrderChart;

	constructor() {
		super();
	}

	async initFunctions() {
		this.orderPouch = new OrderPouch(this.getDB());
		this.tabelleAusiliariPouch = new TabelleAusiliariePouch(this.getDB());
		this.codiceClientePouch = new CodiceClientePouch(this.getDB());
		this.articlePouch = new ArticlePouch(this.getDB());
		this.categoriesPouch = new CategoriesPouch(this.getDB());
		this.destinationListPouch = new DestinationListPouch(this.getDB());
		this.countactPouch = new ContactPouch(this.getDB());
		this.tagListPouch = new TagListPouch(this.getDB());
		this.familyPouch = new FamilyPouch(this.getDB());
		this.pricePouch = new PricePouch(this.getDB());
		this.orderChart = new OrderChart(this.getDB());
	}
}
