import { createReducer, on } from '@ngrx/store';
import * as fromAuxiliaryTable from './auxiliary_table.actions';
// model
import { AuxiliaryTableListPouchModel } from '../../model/pouch/auxiliary-table.model';

export interface AuxiliaryTableState extends AuxiliaryTableListPouchModel {}

export const initialState: AuxiliaryTableState = <AuxiliaryTableState>{};

const _reducer = createReducer(initialState,
  on(fromAuxiliaryTable.update, (state, tables) => {
    return tables;
 })
);

export function reducer(state, action) {
  return _reducer(state, action);
}
