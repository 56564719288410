import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';

import { PaymentModel, PropertyModel } from '../../../model/pouch/auxiliary-table.model';
import { CompanyPouchModel } from '../../../model/pouch/company-pouch.model';
import { DestinationPouchModel, DestinationPouchResponseModel } from '../../../model/pouch/destination-pouch.model';
import { DestinationNew, OrderPouchModel } from '../../../model/pouch/order-pouch.model';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { getCompanyState } from '../../../state';
import * as fromState from '../../../state';
import { CompanyState } from '../../../state/company/company.reducer';
import * as fromDestinationList from '../../../state/destination-list/destination-list.actions';
import * as fromOrder from '../../../state/order/order.actions';
import * as fromOrderDetail from '../../../state/order/order.actions';
import { OrderState } from '../../../state/order/order.reducer';
import { DialogConfirmComponent } from '../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { DialogOtherDestinationComponent } from '../../../widget/dialog/dialog-other-destination/dialog-other-destination.component';
import { DialogTextEditComponent } from '../../../widget/dialog/dialog-text-edit/dialog-text-edit.component';
import { AccordionStateService } from './../../../service/util/accordion-state.service';
import { TabWrapperDataBadge } from './../../../widget/tab-wrapper/tab-wrapper.component';
import { UserState } from '../../../state/user/user.reducer';
import { UtilService } from '../../../service/util/util.service';

// Model
// Actions
// Translate
@Component({
	selector: 'order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailComponent implements OnInit, OnDestroy {
	// Mat accordion state
	// expandedState: boolean;

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	user: UserState;

	orderedQty: TabWrapperDataBadge;
	orderState: string;
	idCompany: string;
	idOrder: string;
	form: FormGroup;
	order: OrderPouchModel;
	company: CompanyPouchModel;
	isChecked = false;
	deliveryMethodList: PropertyModel[];
	shippingMethodList: PropertyModel[];

	changeFormSubscribe: Subscription;

	today: Date = new Date();
	minDate_first_evasion_date: Date = new Date(new Date().setDate(this.today.getDate() + 1));
	setTodayDate = false;

	modalitaConsegna;
	modalitaSpedizione;

	// StatusBarConfig
	statusBarConfig = {
		condensed: true,
		theme: 'gray', // da settare anche in order-detail.component.html
		steps: [
			{
				status: 'DRAFT',
				label: this.translate.instant('navigation.draft').toUpperCase(),
				icon: 'create',
				selected: false
			},
			{
				status: 'PROCESSING',
				label: this.translate.instant('navigation.ready_to_send').toUpperCase(),
				icon: 'rotate_right',
				selected: false
			},
			{
				status: 'FULFILLED',
				label: this.translate.instant('navigation.fulfilled').toUpperCase(),
				icon: 'archive',
				selected: false
			}
		]
	};

	payments: PaymentModel[] = [];
	shipmentAddress: DestinationPouchModel[];

	order$: Observable<OrderPouchModel>;
	company$: Observable<CompanyPouchModel>;
	destinationList$: Observable<any>;
	payment$: Observable<PaymentModel[]>;
	deliveryMethods$: Observable<PropertyModel[]>;
	shippingMethods$: Observable<PropertyModel[]>;

	titleOrder: string;

	myFilter = (d: Moment | null): boolean => {
		const day: number = d.day();
		// esclude il sabato e la domenica
		return day !== 0 && day !== 6;
	};

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private location: Location,
		private store: Store<any>,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public translate: TranslateService,
		public accordionStateService: AccordionStateService,
		private utilOrderService: UtilOrderService,
		private utilService: UtilService
	) {
		this.orderState = this.route.snapshot.paramMap.get('statusOrder');
		this.idCompany = this.route.snapshot.paramMap.get('idCompany');
		this.idOrder = this.route.snapshot.paramMap.get('idOrder');

		this.user$.pipe(take(1)).subscribe(user => (this.user = user));

		this.createStatusBarOrderConfig();
		this.createForm();

		// store state
		this.order$ = this.store.select(fromState.getOrderState);
		this.company$ = this.store.select(getCompanyState);
		this.destinationList$ = this.store.select(fromState.getDestinationListState);
		this.payment$ = this.store.select(fromState.getAuxiliaryPagamenti);
		this.deliveryMethods$ = this.store.select(fromState.getAuxiliaryModalitaDiConsegna);
		this.shippingMethods$ = this.store.select(fromState.getAuxiliaryModalitaDiSpedizione);

		this.subscribeManagerService.populate(this.initMandatoryData().subscribe(), 'order-detail-init');
		this.route.params.subscribe(params => {
			const id = params['idOrder'];
			this.idOrder = id;
			// Se passo tra nuovo ordine e bozza, riparte l'initMandatoryData
			if (this.idOrder !== 'new') {
				this.initMandatoryData().subscribe(res => {});
			} else {
				this.initMandatoryData().subscribe(res => {});
			}
		});
	}

	ngOnInit() {
		this.accordionStateService.accordionState = true;
		// Se la route è 'orders/draft/codice company/new/catalogo' l'accordion è aperto
		if (this.company) {
			if (this.router.url === `/orders/draft/${this.company.codice}/new/catalogo`) {
				this.accordionStateService.accordionState = true;
			} else {
				this.accordionStateService.accordionState = false;
			}
		}
		this.matAccordionStateonClick();
	}

	matAccordionStateonClick() {
		if (this.idOrder && this.idCompany) {
			if (this.router.url === `/orders/draft/${this.idCompany}/${this.idOrder}/carrello`) {
				this.accordionStateService.accordionState = false;
			} else {
				this.accordionStateService.accordionState = false;
			}
		}
	}

	canEditOrder() {
		return (
			(this.order.header.status == 'DRAFT' || this.order.header.status == 'ERROR_PREPARING') &&
			!this.utilService.checkUserPermission(this.user, 'bo-dashboard')
		);
	}

	initMandatoryData() {
		return this.company$.pipe(
			skipWhile((company: CompanyState) => !(company && company.codice)),
			mergeMap((company: CompanyState) => {
				this.company = company;
				this.store.dispatch(fromDestinationList.load({ codice_cliente: this.company.codice }));
				return forkJoin([
					this.retrieveDeliveryMethodList(),
					this.retrieveShippingMethodList(),
					this.retrieveDestinationList(),
					this.retrievePaymentDivision()
				]);
			}),
			mergeMap(list => {
				if (this.idOrder !== 'new') {
					this.store.dispatch(fromOrderDetail.load({ id: this.idOrder }));
				} else {
					this.store.dispatch(fromOrderDetail.update(new OrderPouchModel()));
				}
				return this.order$;
			}),
			filter((order: OrderState) => (order ? true : false)),
			map((order: OrderState) => {
				this.setOrder(order);
				this.setValueFromModelForm();
				return order;
			})
		);
	}

	retrieveDeliveryMethodList() {
		return this.deliveryMethods$.pipe(
			skipWhile((deliveryMethodList: PropertyModel[]) => !(deliveryMethodList && deliveryMethodList.values)),
			take(1),
			tap((deliveryMethodList: PropertyModel[]) => {
				this.deliveryMethodList = deliveryMethodList;
				this.modalitaConsegna = this.deliveryMethodList.find(
					item => item.codice_elemento === this.company.modalita_consegna
				).descrizione;
			})
		);
	}

	retrieveShippingMethodList() {
		return this.shippingMethods$.pipe(
			skipWhile(
				(destinationMethodList: PropertyModel[]) => !(destinationMethodList && destinationMethodList.values)
			),
			take(1),
			tap((destinationMethodList: PropertyModel[]) => {
				this.shippingMethodList = destinationMethodList;
				this.modalitaSpedizione = this.shippingMethodList.find(
					item => item.codice_elemento === this.company.modalita_spedizione
				).descrizione;
			})
		);
	}

	retrieveDestinationList() {
		return this.destinationList$.pipe(
			skipWhile((destinationList: DestinationPouchResponseModel) => !(destinationList && destinationList.values)),
			take(1),
			tap((destinationList: DestinationPouchResponseModel) => {
				// console.log(destinationList.values)
				this.shipmentAddress = destinationList.values;
			})
		);
	}

	returnDefaultDestinationCode() {
		let defaultDestination: DestinationPouchModel;
		// Destinazione merce abituale
		if (this.company.destinazione_merce_abituale) {
			defaultDestination = this.shipmentAddress.find(
				destination => destination.codice === this.company.destinazione_merce_abituale
			);
		}
		if (!defaultDestination) {
			// Destinazione 000
			defaultDestination = this.shipmentAddress.find(destination => destination.codice === '000');
		}
		if (!defaultDestination) {
			// Prima destinazione della lista
			defaultDestination = this.shipmentAddress[0];
		}
		return defaultDestination.codice;
	}

	retrievePaymentDivision() {
		return this.payment$.pipe(
			skipWhile((payments: PaymentModel[]) => !(payments && payments.length > 0)),
			take(1),
			tap((payments: PaymentModel[]) => {
				this.payments.push(
					payments.find(payment => payment.codice_elemento === this.company.condizione_pagamento)
				);
			})
		);
	}

	setOrder(order: OrderState) {
		this.order = order;
		if (!this.order._id) {
			/**
			 * TODO effettuare un redirect al primo salvataggio dell'ordine
			 * per aggiornare l'id nell'url
			 */
			// this.idOrder = this.order._id;

			// this.router.navigate([
			// 	`/orders/${StateOrderModel[this.order.header.status].toLowerCase()}/${
			// 		this.order.header.client_code
			// 	}/${this.order._id}/`
			// ]);

			this.titleOrder = 'new';
			this.orderState = 'draft';
			this.statusBarConfig = {
				condensed: true,
				theme: 'gray', // da settare anche in order-detail.component.html
				steps: [
					{
						status: 'DRAFT',
						label: this.translate.instant('navigation.draft').toUpperCase(),
						icon: 'create',
						selected: true
					}
				]
			};

			this.order = this.utilOrderService.initializeDraftOrder(
				this.order,
				this.company,
				this.payments[0].codice_elemento,
				this.returnDefaultDestinationCode()
			);

			this.orderedQty = {
				route: 'carrello',
				data: null
			};
		} else {
			if (order.header.status === 'DRAFT') {
				let qtySum = 0;
				order.product_list.forEach(el => {
					qtySum += el.ordered_quantity;
				});
				this.orderedQty = {
					route: 'carrello',
					data: qtySum
				};
				if ((this.idOrder !== 'new' && this.order._id) || this.idOrder === 'new') {
					this.titleOrder = ` ${this.order._id ? this.order._id.slice(6, this.order._id.length) : ''}`;
				}
				if (this.order.header.status === 'DRAFT') {
					//this.order.header.date = moment().format('DD/MM/YYYY');
					this.order.header.date = +moment().format('x');
					if (moment(this.order.header.first_evasion_date, 'x').isBefore(moment(new Date()).add(1, 'days'))) {
						// this.order.header.first_evasion_date = moment(new Date())
						// 	.add(dayToAdd, 'days')
						// 	.format('DD/MM/YYYY');
						this.order.header.first_evasion_date = +moment(new Date())
							.add(1, 'days')
							.format('x');
					}
				}
			} else {
				if (this.form) {
					this.form.disable();
				}
			}
		}
	}

	createStatusBarOrderConfig() {
		switch (this.orderState.toUpperCase()) {
			case 'DRAFT':
				this.statusBarConfig.steps.find(s => s.status === 'DRAFT').selected = true;
				break;
			case 'PROCESSING':
			case 'SENDING':
				this.statusBarConfig.steps.find(s => s.status === 'PROCESSING').selected = true;
				break;
			case 'FULFILLED':
				this.statusBarConfig.steps.find(s => s.status === 'FULFILLED').selected = true;
				break;
		}
	}

	createForm() {
		this.form = this.fb.group({
			goods_destination_code: [{ value: '', disabled: this.orderState !== 'draft' }, Validators.required],
			goods_destination_new: [{ value: '', disabled: this.orderState !== 'draft' }],
			first_evasion_date: [
				{
					value: '',
					disabled:
						this.orderState !== 'draft' || this.utilService.checkUserPermission(this.user, 'bo-dashboard')
				},
				Validators.required
			],
			note: [
				{
					value: '',
					disabled:
						this.orderState !== 'draft' || this.utilService.checkUserPermission(this.user, 'bo-dashboard')
				},
				Validators.required
			]
		});
	}

	setValueFromModelForm() {
		if (this.changeFormSubscribe) {
			this.changeFormSubscribe.unsubscribe();
		}

		this.form.patchValue({
			goods_destination_code: this.order.header.goods_destination_code,
			first_evasion_date: moment(this.order.header.first_evasion_date, 'x'),
			note: this.order.header.note
		});

		this.onChangeForm();
	}

	onChangeForm() {
		this.changeFormSubscribe = this.form.valueChanges.pipe(debounceTime(1000)).subscribe(formChange => {
			this.order.header.goods_destination_code = formChange['goods_destination_code'];
			//this.order.header.first_evasion_date = moment(formChange['first_evasion_date']).format('DD/MM/YYYY');
			this.order.header.first_evasion_date = +moment(formChange['first_evasion_date']).format('x');
			this.order.header.note = formChange['note'];
			this.store.dispatch(fromOrder.save(this.order));
		});
	}

	// widget
	openDialogTextEdit() {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: "Aggiungi una Nota all'Ordine",
				text: ''
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
			}
		});
	}

	// navigation
	goBack(e) {
		e.stopPropagation();
		this.location.back();
	}

	// widget
	openDialogConfirm(event) {
		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Elimina Ordine',
				text: "Vuoi davvero eliminare l'ordine in Bozza?"
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				if (this.order._id) {
					this.store.dispatch(fromOrder.remove({ order: this.order }));
				}
				this.router.navigate(['/orders/draft']);
			}
		});
		event.stopPropagation();
	}

	onTogglePrezzi() {
		this.isChecked = !this.isChecked;
	}

	showAlert(type: string, event: MatDatepickerInputEvent<Moment>) {
		if (event.value.diff(moment(), 'd') < 1) {
			this.setTodayDate = true;
		} else {
			this.setTodayDate = false;
		}
	}

	addOtherDestination(e: MatSlideToggleChange) {
		if (e.checked) {
			this.openDialogDestination();
		} else {
			this.enableDestinationCode();
		}
	}

	openDialogDestination() {
		if (this.order.header.goods_destination_new === null) {
			this.order.header.goods_destination_new = {
				business_name: null,
				contact_person: null,
				address: {
					address: null,
					locality: null,
					country: null,
					zip_code: null,
					province: null
				}
			};
		}

		const dialog = this.dialog.open(DialogOtherDestinationComponent, {
			data: {
				title: "Aggiungi una nuova destinazione all'ordine",
				otherDestination: this.order.header.goods_destination_new
			},
			disableClose: true
		});
		dialog.afterClosed().subscribe((res: DestinationNew) => {
			if (res) {
				this.form.controls['goods_destination_code'].disable();
				this.order.header.goods_destination_new = res;
				this.order.header.goods_destination_code = null;
			} else {
				this.enableDestinationCode();
			}
			this.store.dispatch(fromOrder.save(this.order));
		});
	}

	enableDestinationCode() {
		this.form.controls['goods_destination_code'].enable();
		this.order.header.goods_destination_new = null;
		this.order.header.goods_destination_code = this.returnDefaultDestinationCode();
		this.store.dispatch(fromOrder.save(this.order));
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
		if (this.changeFormSubscribe) {
			this.changeFormSubscribe.unsubscribe();
		}
		this.store.dispatch(fromOrder.reset());
	}
}
