import { Injectable, ɵCompiler_compileModuleAsync__POST_R3__ } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as fromCompany from './company.actions';
// service
import { CompanyState } from './company.reducer';
import { CompanyService } from '../../service/rest/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';
import { UserSaveResponse } from '../../model/user.model';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';

@Injectable()
export class CompanyEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Company Context] Load'),
			mergeMap(action =>
				from(this.getCompanyPouch(action['code'])).pipe(
					map((company: CompanyPouchModel) => fromCompany.update(company)),
					catchError(() => of({ type: '[Company Context] Company Load Error' }))
				)
			)
		)
	);

	saveUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Company Context] Save Users'),
			mergeMap(action =>
				from(this.saveCompanyRest(action)).pipe(
					map((company: CompanyPouchModel) => fromCompany.update(company)),
					catchError(() => of({ type: '[Company Context] Company Load Error' }))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private companyService: CompanyService,
		private snackbar: MatSnackBar,
		private pouchUtilService: PouchUtilService
	) {}

	getCompanyPouch(companyCode: string): Promise<CompanyPouchModel> {
		return this.pouchUtilService.getActiveAdapter().codiceClientePouch.getClient(companyCode);
	}

	saveCompanyRest(company: CompanyState): Promise<any> {
		return this.companyService
			.save(company, null, {
				clientCode: company.codice
			})
			.then(res => {
				const userSaveResponse: UserSaveResponse = res;
				company.users = [];

				let message = `<h2>Company ${company.codice} aggiornata</h2>`;

				if (userSaveResponse.updated_users && userSaveResponse.updated_users.length > 0) {
					message = message.concat(
						'<strong>I seguenti utenti sono stati salvati correttamente:</strong><br><br>'
					);

					userSaveResponse.updated_users.forEach(i => {
						company.users.push(i);
						message = message.concat(`&rsaquo; ${i.username}<br>`);
					});
				}

				if (userSaveResponse.error_users && userSaveResponse.error_users.length > 0) {
					message = message.concat('<br><strong>I seguenti utenti non sono stati salvati</strong><br><br>');

					userSaveResponse.error_users.forEach(i => {
						company.users.push(i);
						message = message.concat(`&rsaquo; ${i.username}: ${i.message}<br>`);
					});
				}

				this.snackbar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 60000,
					data: {
						message: message,
						action: 'HO CAPITO'
					}
				});

				return company;
			})
			.catch(err => {
				this.snackbar.open('Si è verificato un problema, i dati potrebbero non essere stati salvati', 'OK', {
					duration: 5000
				});
				throw err;
			});
	}
}
