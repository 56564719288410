import { FamilyPouchListModel } from '../../model/pouch/family-pouch.model';
import { createReducer, on } from '@ngrx/store';
import * as fromFamily from './family-list.actions';

export interface FamilyListState extends FamilyPouchListModel {
	lang?: string
}

export const initialState: FamilyListState = {
	data: []
};

const _reducer = createReducer(
	initialState,
	on(fromFamily.update, (state, familyList) => familyList),
	on(fromFamily.load, state => state)
);

export function reducer(state, action) {
	return _reducer(state, action);
}
