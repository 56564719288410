import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// rest
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../service/rest/user.service';
// widget
import { FormValidatorCustom } from '../../../shared/misc/form-validator';
// localizzazione
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from '../../../service/rest/auth.service';

@Component({
  selector: 'password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    public translate: TranslateService,
    private authService: AuthService,
  ) {
    this.createForm();
  }

  ngOnInit() { }

  createForm() {
    this.form = this.fb.group({
      email: ['', [FormValidatorCustom.email, Validators.required]]
    });
  }

  getFormStructure() {
    const formModel = this.form.value;
    const formStructure = {
      email: formModel.email as string,
    };
    return formStructure;
  }

  onFormSubmit() {
    if (this.form.valid){
      this.recoveryPassword();
    }
  }

  // widget
  openSnackBar(message: string, action = 'Ok', duration = 3000) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  // rest
  recoveryPassword() {
    const formStructure = this.getFormStructure();
    const email = formStructure.email;
    this.userService.passwordRecovery(email)
    .then(() => {
      this.translate
          .get("login")
          .subscribe((login: any) => {
            this.openSnackBar(
              login.password_recovery.success_message
            );
          });
      this.router.navigate(['/authentication/login']);
    }).catch(() => {
      this.translate
          .get("login")
          .subscribe((login: any) => {
            this.openSnackBar(
              login.password_recovery.error_message
            );
          });
    });
  }

}
