import { debounce } from '../decorators/debounce.decorator';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {
	constructor(private _elementRef: ElementRef) {}

	@HostListener('keydown', ['$event'])
	@debounce()
	onKeyDown(e: KeyboardEvent) {
		if (
			// Allow: Delete, Backspace, Tab, Escape, Enter
			[46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
			(e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
			(e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
			(e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
			(e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
			(e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
			(e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
			(e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
			(e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
			(e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
		) {
			return; // let it happen, don't do anything
		}
		// Ensure that it is a number and stop the keypress
		if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
		document.execCommand('insertText', false, pastedInput);
	}

	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent) {
		event.preventDefault();
		const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
		this._elementRef.nativeElement.focus();
		document.execCommand('insertText', false, textData);
	}
}
