import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'authentication-wrapper',
  templateUrl: './authentication-wrapper.component.html',
  styleUrls: ['./authentication-wrapper.component.scss']
})
export class AuthenticationWrapperComponent implements OnInit {

  logo: string = '../logo/SPIN8/v2/logo-SPIN8-esales-business.svg';

  constructor(
    private appConfig: AppConfig
  ) {
    if (this.appConfig.config.company && this.appConfig.config.company.logo) {
			this.logo = this.appConfig.config.company.logo;
    }
  }

  ngOnInit() {
  }

}
