import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import { AppConfig } from './app.config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';


/* Registro la valuta utilizzata nelle tabelle */
registerLocaleData(localeIt, 'it');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {
	@LocalStorage('language') language: string;
	languages = ['it', 'en'];

	constructor(
		public translate: TranslateService,
		private titleService: Title,
		private appConfig: AppConfig,
		private meta: Meta,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private route: ActivatedRoute
	) {
		// language
		// order of priority: 1. query params - 2. navigator language - 3. it
		translate.addLangs(this.languages);
		const default_lang = this.languages.includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : this.languages[0];
		translate.setDefaultLang(default_lang);
		this.language = this.language ? this.language : default_lang
		translate.use(this.language);
		this.route.queryParams.subscribe(params => {
			if (this.languages.includes(params.lang)) {
				this.language = params.lang;
				translate.use(this.language);
			}
		});

		// custom icons registration
		this.matIconRegistry.addSvgIcon(
			'custom-hamburger',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/icon-hamburger.svg')
		);

		this.configAppForCompany(this.appConfig.config.company);
	}

	configAppForCompany(company: any) {
		if (company) {
			this.titleService.setTitle(company.name);
			// const body = document.getElementsByTagName('body')[0];
			// body.classList.remove('default');
			// body.classList.add(company.theme);

			// if (company.meta && company.meta.length) {
			// 	this.meta.addTags(company.meta);
			// }
		}
	}
}
