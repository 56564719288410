import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { FamilyPouchModel } from '../../model/pouch/family-pouch.model';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import { CatalogService } from '../../service/rest/catalog.service';
import * as fromFamilyList from '../family-list/family-list.actions';
import { FamilyListState } from './family-list.reducer';

@Injectable()
export class FamilyListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Family List Context] Load'),
			mergeMap(action =>
				from(this.getFamilyListPouch(action)).pipe(
					map((family: FamilyPouchModel[]) => {
						const familyList: FamilyListState = { data: family };
						return fromFamilyList.update(familyList);
					}),
					catchError(() => of({ type: '[Family List] Family List Load Error' }))
				)
			)
		)
	);

	loadFamilyPublic$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Family List Context] Load Public'),
			mergeMap(action =>
				// Adesso action include anche il param lang
				from(this.getFamilyListRest(action)).pipe(
					map((family: FamilyPouchModel[]) => {
						const familyList: FamilyListState = { data: family };
						return fromFamilyList.update(familyList);
					}),
					catchError(() => of({ type: '[Family List] Family List Load Error' }))
				)
			)
		)
	);
	constructor(
		private actions$: Actions,
		private pouchUtilService: PouchUtilService,
		private catalogService: CatalogService
	) {}

	getFamilyListPouch(action: FamilyListState): Promise<FamilyPouchModel[]> {
		return this.pouchUtilService.getActiveAdapter().familyPouch.getFamilyList(action.pagination, action.filters);
	}
	getFamilyListRest(action: FamilyListState): Promise<FamilyPouchModel[]> {
		return this.catalogService.getFamily(action.pagination, action.filters, action.lang);
	}
}
