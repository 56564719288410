import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
// service
import { AuthService } from '../rest/auth.service';
import { PouchUtilService } from '../pouchdb/pouch-util.service';
import { TokenPayload } from '../../model/login.model';
// store
import { Store } from '@ngrx/store';
import * as fromState from '../../state/index';
import * as fromUser from '../../state/user/user.actions';
import * as fromTagList from '../../state/tag-list/tag-list.actions';
import * as fromAuxiliaryTableList from '../../state/auxiliary_table/auxiliary_table.actions';
import * as fromCompany from '../../state/company/company.actions';
// model
import { UserState } from '../../state/user/user.reducer';
// widget & utility
import { LocalStorage } from 'ngx-webstorage';
import * as jwt_decode from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { skipWhile, take, map} from 'rxjs/operators';
import { UtilService } from '../util/util.service';
import { CompanyState } from '../../state/company/company.reducer';
import { Observable, Subscription } from 'rxjs';
// routes
import * as b2bRouteList from '../../router/b2b-routing';
import * as backofficeRouteList from '../../router/backoffice-routing';

@Injectable()
export class AuthGuard implements CanActivate {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	company$: Observable<CompanyState> = this.store.select(fromState.getCompanyState);
	_company: Subscription;

	constructor(
		private router: Router,
		private authService: AuthService,
		private pouchUtilService: PouchUtilService,
		private store: Store<any>,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		private utilService: UtilService
	) { }

	/**
	 * Inizializza couch
	 * In base ai privilegi dell'utente setta le routes in router.config
	 * Setta un buleano che permette di skippare il controllo
	 */
	checkUserPermission(): Observable<boolean> {
		return this.user$.pipe(
			skipWhile((user: UserState) => !user || !user.id),
			take(1),
			map((user: UserState) => {
				const routes = this.router.config;
				if (
					this.utilService.checkUserPermission(user, 'b2b-dashboard') &&
					user.client_code
				) {
					routes[1].children = b2bRouteList.routes;
					this.router.resetConfig(routes);
					this.store.dispatch(fromCompany.load({ code: user.client_code }));
					this.store.dispatch(fromTagList.load());
					this.store.dispatch(fromAuxiliaryTableList.load());
				} else {
					if (this.utilService.checkUserPermission(user, 'bo-dashboard')) {
						routes[1].children = backofficeRouteList.routes;
						this.router.resetConfig(routes);
						this.store.dispatch(fromCompany.reset());
					} else {
						this.snackBar.open(`L'utente ${user.username} non è associato a nessun Codice Cliente`, 'OK');
						this.authService.logout();
						return false;
					}
				}
				this.authService.byPassAuthGuard = true;
				return true;
			})
		);
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!environment.mockup) {
			if (this.authenticationToken) {
				if (this.authService.byPassAuthGuard) {
					return true;
				}
				const tk_decoded = jwt_decode(this.authenticationToken);
				this.authService.tokenPayload = new TokenPayload(tk_decoded);
				const user: UserState = {
					id: this.authService.tokenPayload.user_id
				};
				this.pouchUtilService.explicitInitCouch();
				this.store.dispatch(fromUser.load(user));
				return this.checkUserPermission();
			} else {
				this.authService.logout();
				return false;
			}
		} else {
			return true;
		}
	}
}
