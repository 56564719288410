import { createAction, props } from '@ngrx/store';
// model
import { OrderPouchModelList } from '../../model/pouch/order-pouch.model';
import { Pagination } from '../../model/structure/list-structure.model';

export const update = createAction('[Order List Context] Load Success', props<OrderPouchModelList>());
export const load = createAction(
	'[Order List Context] Load',
	props<{ pagination: Pagination; filters?: any; sort?: any }>()
);
export const reset = createAction('[Order List Context] Reset');
