import { Routes } from '@angular/router';
import { BackofficeCompanyDetailUsersComponent } from '../page/backoffice/company/company-detail/company-detail-users/company-detail-users.component';
import { BackofficeCompanyDetailComponent } from '../page/backoffice/company/company-detail/company-detail.component';
import { BackofficeCompanyComponent } from '../page/backoffice/company/company.component';
import { MediaCenterComponent } from '../page/media/media-center/media-center.component';
import { BackofficeDashboardComponent } from './../page/backoffice/backoffice-dashboard/backoffice-dashboard.component';
import { DataConfigurationComponent } from '../page/backoffice/data-configuration/data-configuration.component';
import { OrderDetailComponent } from '../page/order/order-detail/order-detail.component';
import { OrderDetailCheckoutComponent } from '../page/order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { UserComponent } from '../page/user/user.component';

// page
export const routes: Routes = [
	{
		path: '',
		component: BackofficeDashboardComponent,
		pathMatch: 'full'
	},
	{
		path: 'user',
		component: UserComponent,
		pathMatch: 'full'
	},
	{
		path: 'backoffice/company',
		component: BackofficeCompanyComponent,
		pathMatch: 'full'
	},
	{
		path: 'backoffice/company/:idCompany',
		component: BackofficeCompanyDetailComponent,
		children: [
			{
				path: '',
				redirectTo: 'users',
				pathMatch: 'full'
			},
			{
				path: 'users',
				component: BackofficeCompanyDetailUsersComponent
			}
		]
	},
	{
		path: 'backoffice/configuration',
		component: DataConfigurationComponent,
		pathMatch: 'full'
	},
	{
		path: 'orders',
		children: [
			{
				path: ':statusOrder/:idCompany/:idOrder',
				component: OrderDetailComponent,
				children: [
					{
						path: '',
						redirectTo: 'carrello',
						pathMatch: 'full'
					},
					{
						path: 'carrello',
						component: OrderDetailCheckoutComponent
					}
				]
			}
		]
	},
	{
		path: 'media_center',
		component: MediaCenterComponent,
		pathMatch: 'full'
	}
];
