import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'agent-detail-users',
	templateUrl: './agent-detail-users.component.html',
	styleUrls: ['./agent-detail-users.component.scss']
})
export class BackofficeAgentDetailUsersComponent implements OnInit {
	constructor(private router: Router) {}
	/**
	 * START : mockup
	 */

	agentList: any = {
		data: [
			{
				id: '0001',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			},
			{
				id: '0002',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			},
			{
				id: '0003',
				descrizione: 'lore ipsum',
				descrizione_lunga: 'lorem ipsum dolor sit amet!'
			}
		],
		columns: [
			{
				name: 'cod_gestionale',
				label: 'Codice agente gestionale'
			}
		]
	};

	/**
	 * END : mockup
	 */

	userList = [{ email: '', valid: false }];

	agent;

	ngOnInit() {}

	selectAgent(agent) {
		if (agent.id) {
			this.agent = agent;
		}
	}

	gotoAgentList() {
		this.router.navigate(['backoffice/agent/']);
  }
}
