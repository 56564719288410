import { Component, OnInit, Input } from "@angular/core";

export class StatusBarConfig {
  condensed?: boolean;
  theme: string;
  steps: StatusBarElement[];
}

export class StatusBarElement {
  label: string;
  icon: string;
  selected?: boolean;
}

@Component({
  selector: "status-bar",
  templateUrl: "./status-bar.component.html",
  styleUrls: ["./status-bar.component.scss"]
})
export class StatusBarComponent implements OnInit {
  _config: StatusBarConfig = new StatusBarConfig();
  theme: string;

  @Input() set config(c) {
    if (c) {
      this._config.steps = this.hightlightSelected(c);
    }
  }
  constructor() { }
  ngOnInit() { }

  // hightlightSelected(c: StatusBarConfig) {
  //   this.theme = c.theme;
  //   let steps: StatusBarElement[] = [];
  //   let selected: boolean = true;
  //   for (let i = 0; i < c.steps.length; i++) {
  //     steps[i] = c.steps[i];
  //     if (selected) {
  //       steps[i].selected = true;
  //       if (c.condensed) {
  //         this._config.steps = [steps[i]]
  //         return
  //       }
  //     }
  //     if (c.steps[i].selected) {
  //       selected = false;
  //     }
  //   }
  //   this._config = c;
  // }

  hightlightSelected(c: StatusBarConfig): StatusBarElement[] {
    this.theme = c.theme;
    let steps: StatusBarElement[] = [];
    let target: boolean;
    c.steps.forEach(s => {
      if(c.condensed){
        if(s.selected) {
          steps.push(s);
          return steps;
        }
      }else{
        if(!target) {
        target = s.selected? true : false;
        s.selected = true;
        }
        steps.push(s);
      }
    });
    return steps;
  }
}
