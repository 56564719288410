import { AbstractPouchdbMethod } from "../../structure/abstract-pouchdb-method";
import { DestinationPouchResponseModel } from "../../../../model/pouch/destination-pouch.model";

export class DestinationListPouch extends AbstractPouchdbMethod{

	getDestinationList(codice_cliente): Promise<any> {
		return new Promise(resolve => {
			this.get('destination_' + codice_cliente)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	putDestinationList(destination): Promise<any> {
		console.log(destination);
		return new Promise(resolve => {
			this.put<DestinationPouchResponseModel>(destination, destination._id)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}

