import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { OrderPouchModel } from '../../model/pouch/order-pouch.model';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';
import * as fromOrder from '../order/order.actions';

// service
@Injectable()
export class OrderDetailEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Order Detail Context] Load'),
			mergeMap(data =>
				from(this.getOrderDetailPouch(data['id'])).pipe(
					map(order => fromOrder.update(order)),
					catchError(() => of({ type: '[Order Detail] Order Detail Load Error' }))
				)
			)
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Order Context] Save'),
			mergeMap(data =>
				from(this.postOrderDetailPouch(data, data['_id'] ? false : true)).pipe(
					map(order => fromOrder.update(order)),
					catchError(() => of({ type: '[Order Detail] Order Detail Load Error' }))
				)
			)
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Order Detail Context] Delete'),
			mergeMap(data =>
				from(this.deleteOrderDetailPouch(data)).pipe(
					map(order => fromOrder.reset()),
					catchError(() => of({ type: '[Order Detail] Order Detail Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService) {}

	getOrderDetailPouch(order_id: string): Promise<any> {
		// return this.pouchdbService.getOrderDetail(order_id).then(res => {
		//   return res;
		// });
		return this.pouchUtilService.getActiveAdapter().orderPouch.getOrderDetail(order_id);
	}

	postOrderDetailPouch(body: OrderPouchModel, useGuid): Promise<OrderPouchModel> {
		body.type = 'order';
		// return this.pouchdbService.putOrder(body, useGuid).then(res => {
		//   return res;
		// });
		return this.pouchUtilService.getActiveAdapter().orderPouch.putOrder(body, useGuid);
	}

	deleteOrderDetailPouch(order): Promise<any> {
		// return this.pouchdbService.deleteOrderDetail(order).then(res => {
		//   return res;
		// });
		return this.pouchUtilService.getActiveAdapter().orderPouch.deleteOrderDetail(order);
	}
}
