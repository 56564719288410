import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UtilOrderService } from './../../service/util/util-order.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ITdDataTableColumn, ITdDataTableRowClickEvent, TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';

import { StateOrderModel } from '../../enum/stateOrder.enum';
import { CausalHeaderOdvModel } from '../../model/pouch/auxiliary-table.model';
import { OrderPouchModelList } from '../../model/pouch/order-pouch.model';
import { Filters, Pagination } from '../../model/structure/list-structure.model';
import { OrderService } from '../../service/rest/order.service';
import { AgentOrderColumnService } from '../../service/td-data-table/implementation/agent-order.service';
import { CompanyOrderColumnService } from '../../service/td-data-table/implementation/company-order.service';
import { SubscribeManagerService } from '../../service/util/subscribe-manager.service';
import * as fromState from '../../state';
import { CompanyState } from '../../state/company/company.reducer';
import * as fromOrderList from '../../state/order-list/order-list.actions';
import { OrderListState } from '../../state/order-list/order-list.reducer';
import { ListWrapperComponent } from '../../widget/list-wrapper/list-wrapper.component';
import { DateFilterModel } from '../../service/pouchdb/filters/order-filter.model';

import * as fromOrder from '../../state/order/order.actions';
import { parse } from 'path';

// Translate
// Mat accordion service
// Mat accordion service
enum PaginationActionType {
	FIRST = 'FIRST',
	PREVIOUS = 'PREVIOUS',
	NEXT = 'NEXT',
	LAST = 'LAST'
}

@Component({
	selector: 'order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderComponent implements OnInit, OnDestroy {
	[x: string]: any;
	// state accordion
	@Input() expa: boolean;

	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@ViewChild('listWrapper', { static: false }) listWrapper: ListWrapperComponent;

	company$: Observable<CompanyState>;
	ordersData$: Observable<OrderListState>;
	causaliTestata$: Observable<CausalHeaderOdvModel[]>;

	orders: OrderPouchModelList = {
		data: [],
		pagination: {
			page_current: 1,
			page_size: 10
		},
		filters: {}
	};

	fromRow = 1;

	causaliTestateOdvSub: Subscription;
	orderState: string;
	orderStates: string[] = [];
	causaliTestataOdv: CausalHeaderOdvModel[] = [];

	text_search: string;
	formFilters: FormGroup;
	filterOpened: boolean;
	columns: ITdDataTableColumn[] = [];
	company: CompanyState;

	sort = [
		{
			'header.date': 'desc'
		}
	];
	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private orderService: OrderService,
		public utilOrderService: UtilOrderService,
		public agentOrderColumnService: AgentOrderColumnService,
		public companyOrderColumnService: CompanyOrderColumnService,
		private subscribeManagerService: SubscribeManagerService,
		public translate: TranslateService
	) {
		this.company$ = this.store.select(fromState.getCompanyState);
		this.ordersData$ = this.store.select(fromState.getOrdersState);
		this.causaliTestata$ = this.store.select(fromState.getAuxiliaryCausaliTestateOdv);

		this.orderState = this.route.snapshot.paramMap.get('statusOrder');

		this.subscribeManagerService.populate(this.initMandatoryData().subscribe(), 'order-init');
		this.subscribeManagerService.populate(this.refreshContextComponent().subscribe(), 'order-refreshcontext');

		this.columns = this.agentOrderColumnService.columns;
		this.filterOpened = document.body.clientWidth > 1200;
		this.getOrderStates();
		this.createFormFilters();
	}

	getOrderList() {
		this.orderState = this.route.snapshot.paramMap.get('statusOrder');
		this.orders.filters.order_states = this.getCurrentOrderStatus();

		if (this.orders) {
			this.orders.data.forEach(order => {
				if (order.header.total === 0) {
					this.store.dispatch(fromOrder.remove({ order: order }));
				}
			});
		}
		this.store.dispatch(
			fromOrderList.load({
				pagination: this.orders.pagination,
				filters: this.orders.filters,
				sort: this.sort
			})
		);
	}

	getCurrentOrderStatus(): StateOrderModel[] {
		if (!this.orderState) {
			return undefined;
		} else {
			const result: StateOrderModel[] = [];
			result.push(StateOrderModel[this.orderState.toUpperCase()]);
			/**
			 * If in a section you have to show multiple orderStates
			 */
			switch (StateOrderModel[this.orderState.toUpperCase()]) {
				// Bozza
				case StateOrderModel.DRAFT:
					result.push(StateOrderModel.ERROR_PREPARING);
					break;

				// Lavorazione
				case StateOrderModel.READY_TO_SEND:
					result.push(StateOrderModel.CONSOLIDATED);
					result.push(StateOrderModel.SENDING);
					result.push(StateOrderModel.PROCESSING);
					result.push(StateOrderModel.PARTIALLY_FULFILLED);
					result.push(StateOrderModel.ERROR_SENDING);
					break;

				// Archiviato
				case StateOrderModel.FULFILLED:
					result.push(StateOrderModel.DELETED);
					break;

				default:
					break;
			}
			return result;
		}
	}

	getOrderStateLabel() {
		return this.translate.instant(`navigation.${this.orderState}`);
	}

	initMandatoryData() {
		// TODO Remove causali
		const causali$ = this.causaliTestata$.pipe(
			skipWhile(causali => !(causali && causali.length > 0)),
			take(1),
			map(causali => {
				this.causaliTestataOdv = causali;
				return this.causaliTestataOdv;
			})
		);

		const company$ = this.company$.pipe(
			skipWhile((company: CompanyState) => !(company && company.codice)),
			map((company: CompanyState) => {
				this.company = company;
				this.orders.filters.client_code = this.company.codice;
				if (!this.router.getCurrentNavigation()) {
					this.getOrderList();
				}
				return this.company;
			})
		);

		const order$ = this.ordersData$.pipe(
			filter((orderList: any) => orderList && orderList.docs),
			tap((orderList: any) => {
				if (Array.from(orderList.docs).length > 0) {
					this.orders.data = orderList.docs;
					// Visualizza il totale con gli sconti applicati senza calcolare l'IVA
					this.orders.data.forEach(element => {
						element.header.total = this.calculateDiscount(element);
					});
				} else {
					this.orders.data = [];
				}
				return this.orders;
			})
		);

		return combineLatest([order$, company$, causali$]);
	}

	refreshContextComponent() {
		return this.router.events.pipe(
			filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
			tap((routerEvent: RouterEvent) => {
				this.router.navigated = false;
				if (this.company && this.company.codice) {
					this.getOrderList();
				}
				return this.router;
			})
		);
	}

	ngOnInit() {}

	paginationChange(pagination: Pagination) {
		this.orders.pagination = pagination;
		this.getOrderList();
	}
	changePageSize(size: number) {
		this.orders.pagination.page_size = size;
		this.changePage(PaginationActionType.FIRST);
		this.getOrderList();
	}

	changePage(actionType: string) {
		switch (actionType) {
			case PaginationActionType.FIRST:
				this.orders.pagination.page_current = 1;
				break;
			case PaginationActionType.PREVIOUS:
				this.orders.pagination.page_current--;
				break;
			case PaginationActionType.NEXT:
				this.orders.pagination.page_current++;
				break;
			case PaginationActionType.LAST:
				this.orders.pagination.page_current = -1;
				break;
		}
		// al momento non è possibile navigare fino all'ultima pagina, manca il total count
		// this.store.dispatch(fromOrderList.load({ pagination: this.orders.pagination, filters: this.orders.filters }));
		this.getOrderList();
	}

	// form - filters
	createFormFilters() {
		this.formFilters = this.fb.group({
			date: [''],
			due_date: [''],
			tot: [''],
			order_states: this.orderState ? [] : [this.orderState]
		});
		this.text_search = '';
		// if (!this.orderState) {
		//   this.formFilters.addControl('order_states', new FormControl(''));
		// }
	}

	setValueFormFilters() {
		this.formFilters.patchValue({
			date: this.orders.filters.date,
			due_date: this.orders.filters.due_date
		});
	}

	prepareSaveFormFilters(): Filters {
		const formModel = this.formFilters.value;
		const saveForm: Filters = {
			client_code: this.orders.filters.client_code as string,
			order_states: formModel.order_states as string[],
			// order_states: this.orders.filters.order_states as string[],
			tot: formModel.tot as any
		};
		return saveForm;
	}

	onFormFiltersSubmit() {
		if (this.formFilters.valid) {
			this.orders.filters = this.prepareSaveFormFilters();
			if (this.formFilters && this.formFilters.value.date) {
				const filterDate: DateFilterModel = {
					begin: +this.formFilters.value.date.begin.startOf('day').format('x'), // 'x'
					end: +this.formFilters.value.date.end.endOf('day').format('x') // 'x'
				};
				if (!this.orders.filters.date) {
					this.orders.filters.date = [];
				}
				// TODO per quando avremo le date in unix timestamp, e filter model come quello di Leca
				// this.orders.filters.date = filterDate;
				this.orders.filters.date.push(filterDate.begin);
				this.orders.filters.date.push(filterDate.end);
			}
			if (this.formFilters && this.formFilters.value.due_date) {
				const filterDueDate: DateFilterModel = {
					begin: +this.formFilters.value.due_date.begin.startOf('day').format('x'), //'x'
					end: +this.formFilters.value.due_date.end.endOf('day').format('x') // 'x'
				};
				if (!this.orders.filters.due_date) {
					this.orders.filters.due_date = [];
				}
				// this.orders.filters.due_date = filterDueDate;
				this.orders.filters.due_date.push(filterDueDate.begin);
				this.orders.filters.due_date.push(filterDueDate.end);
			}
			this.orders.pagination.page_current = 1;
			this.getOrderList();
		}
	}

	resetFilters() {
		this.createFormFilters();
		this.onFormFiltersSubmit();
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		// this.store.dispatch(fromCompany.load({ code: `${e.row.header.client_code}` }));
		// this.store.dispatch(fromOrderDetail.load({ id: e.row._id }));

		this.router.navigate([
			`/orders/${StateOrderModel[e.row.header.status].toLowerCase()}/${e.row.header.client_code}/${
				e.row._id
			}/carrello`
		]);
	}

	// widget
	toggleColumn(e) {
		for (let i = 0; i < this.companyOrderColumnService.columns.length; i++) {
			if (this.companyOrderColumnService.columns[i].name === e.name) {
				this.companyOrderColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	// data
	getFilteredList() {}

	getOrderStates() {
		this.orderStates = this.orderService.orderStates;
	}

	newDraft() {
		// this.store.dispatch(fromArticleList.load(null));

		this.router.navigate([`/orders/draft/${this.company.codice}/new`]);
	}

	sumProduct(id: string): string {
		let totProducts = 0;
		const ordine = this.orders.data.find(dt => dt._id === id);
		if (ordine) {
			ordine.product_list.forEach(element => {
				totProducts += element.price;
			});
		}
		return totProducts.toString();
	}

	// Riporta il totale con sconto senza IVA
	calculateDiscount(data) {
		let total = 0;
		data.product_list.forEach(product_list_element => {
			let price = 0;
			if (product_list_element.ordered_quantity) {
				price = product_list_element.price * product_list_element.ordered_quantity;
			}
			if (product_list_element.discount && product_list_element.discount.length > 0) {
				const totale = parseFloat((price - (price * product_list_element.discount[0].value) / 100).toFixed(2));
				total += totale;
			} else {
				const totale = parseFloat(price.toFixed(2));
				total += totale;
			}
		});
		return parseFloat(total.toFixed(2));
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(fromOrderList.reset());
	}
}
