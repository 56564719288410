import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @Output() recaptchaResponseEmitter = new EventEmitter();
  recaptchaSiteKey: string;

  constructor(
    private appConfig: AppConfig
  ) {
    this.recaptchaSiteKey = this.appConfig.config.recaptchaSiteKey;
  }

  ngOnInit() {
    this.addRecaptchaScript();
  }

  renderRecaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: this.recaptchaSiteKey,
      callback: captcha => {
        this.recaptchaResponseEmitter.emit(captcha);
      }
    });
  }

  expiredRecaptcha() {
    this.recaptchaResponseEmitter.emit(null);
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderRecaptcha();
    };
    window['expiredRecaptcha'] = () => {
      this.expiredRecaptcha();
    };
    (function (id, obj) {
      let js,
        fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById(id)) {
        obj.renderRecaptcha();
        return;
      }
      js = document.createElement('script');
      js.id = id;
      js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })('recaptcha-jssdk', this);
  }

}
