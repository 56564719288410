import { createAction, props } from '@ngrx/store';
// model
import { CompanyListState } from './company-list.reducer';

export const update = createAction(
  '[Company List Context] Update',
  props<CompanyListState>()
);
export const load = createAction(
  '[Company List Context] Load',
  props<CompanyListState>()
);
export const reset = createAction(
  '[Company List Context] Reset'
);
