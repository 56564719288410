import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromArticleState from './article-list.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('article-list', fromArticleState.reducer)
  ],
})
export class ArticleListStateModule { }
