import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, skipWhile } from 'rxjs/operators';

import { CausalHeaderOdvModel } from '../../model/pouch/auxiliary-table.model';
import { CompanyPouchModel } from '../../model/pouch/company-pouch.model';
import * as fromState from '../../state';
import { CompanyState } from '../../state/company/company.reducer';
import { SubscribeManagerService } from './subscribe-manager.service';

// model
@Injectable({
	providedIn: 'root'
})
export class UtilCompanyService {
	causali_testate_odv$: Observable<CausalHeaderOdvModel[]>;
	causalsList: CausalHeaderOdvModel[];

	company$: Observable<CompanyPouchModel>;
	company: CompanyState;

	defaultCompanyCausal: CausalHeaderOdvModel;

	constructor(private store: Store<any>) {
		this.causali_testate_odv$ = this.store.select(fromState.getAuxiliaryCausaliTestateOdv);
		this.company$ = this.store.select(fromState.getCompanyState);

		this.initMandatoryData().subscribe();
	}

	initMandatoryData() {
		return this.causali_testate_odv$.pipe(
			skipWhile((causali: CausalHeaderOdvModel[]) => !(causali && causali.length > 0)),
			mergeMap((causali: CausalHeaderOdvModel[]) => {
				this.causalsList = causali;
				return this.company$;
			}),
			skipWhile((company: CompanyState) => !(company && company.codice)),
			map((company: CompanyState) => {
				this.company = company;
				if (this.company._id && this.causalsList && this.causalsList.length !== 0) {
					this.defaultCompanyCausal = this.causalsList.find(
						c => c.codice_elemento === this.company.causale_ordine_abituale
					);
				}
			})
		);
	}

	getDefaultCompanyCausal() {
		return this.defaultCompanyCausal;
	}
}
