import { TagDescription, TagPouchModel } from './../../model/pouch/tag.model';
import { Injectable } from '@angular/core';
import { FamilyPouchModel } from '../../model/pouch/family-pouch.model';
import { SortGroup } from '../../page/common/catalog/catalog-family/catalog-family.component';

@Injectable({
	providedIn: 'root'
})
export class UtilCatalogService {
	familyImagesPath = './../../../../../assets/img/image-group';
	articleImagesPath = './../../../../../assets/img/popup-articles';
	// GroupName: ImageName
	imagesMap = {
		ALESATURA: 'ALESATURA.jpg',
		REAMING: 'ALESATURA.jpg',
		
		FILETTATURA: 'FILETTATURA.jpg',
		THREADING: 'FILETTATURA.jpg',
		
		FORATURA: 'FORATURA.jpg',
		'FORATURA HM': 'FORATURA.jpg',
		'FORATURA HSS-HSSCO': 'FORATURA.jpg',
		'FORATURA MD': 'FORATURA.jpg',
		DRILLING: 'FORATURA.jpg',
		'HM DRILLING': 'FORATURA.jpg',
		'DRILLING SC': 'FORATURA.jpg',

		DRILLINGSC: 'FORATURA.jpg',
		'DRILLING - SOLID CARBIDE': 'FORATURA.jpg',
		'DRILLING - HSS-HSSCO': 'FORATURA.jpg',


		FRESATURA: 'FRESATURA.jpg',
		'FRESATURA HM': 'FRESATURA.jpg',
		'FRESATURA HSS-HSSCO': 'FRESATURA.jpg',
		'FRESATURA MD': 'FRESATURA.jpg',
		MILLING: 'FRESATURA.jpg',
		'HM MILLING': 'FRESATURA.jpg',
		'MILLING SC': 'FRESATURA.jpg',
		'MILLING - SOLID CARBIDE': 'FRESATURA.jpg',
		'MILLING - HSS-HSSCO': 'FRESATURA.jpg'
		
	};

	mergeTagDescription(descriptionList: TagDescription[], childList: TagPouchModel[]) {
		Object.keys(childList).forEach(key => {
			if (key !== 'W1_CODAZI') {
				childList[key].forEach(tag => {
					const foundDescription = descriptionList.find(dsc => dsc._id === tag.tag);
					if (foundDescription) {
						tag.description = foundDescription.description;
					} else {
						console.log('Description not found: ', tag);
						tag.description = 'Not found';
					}
				});
			}
		});
	}

	getFamilyGroupImagePath(company: any, groupName: string): string {
		return [this.familyImagesPath, company.code, this.imagesMap[groupName.toUpperCase()]].join('/');
	}

	getFamilyImagePath(company: any, articleCode: string): string {
		return [this.articleImagesPath, company.code, articleCode + '.jpg'].join('/');
	}

	divideFamiliesBySortGroup(families: FamilyPouchModel[]): SortGroup[] {
		let sortGroup: SortGroup[] = [];
		if (families.length > 0) {
			// Creo l'arry sortGroup che visualizza le tabelle
			for (let i = 0; i < families[families.length - 1].sort_group; i++) {
				const fml = families.filter(e => e.sort_group === i + 1);
				if (i === 0) {
					sortGroup = [
						{
							sort_group: i + 1,
							list: {
								data: fml
							}
						}
					];
				} else {
					sortGroup.push({
						sort_group: i + 1,
						list: {
							data: fml
						}
					});
				}
			}
		}
		return sortGroup;
	}
}
