export enum StateOrderModel {
	// bozza
	DRAFT = 'DRAFT',

	// in lavorazione
	READY_TO_SEND = 'READY_TO_SEND',
	SENDING = 'SENDING',
	PROCESSING = 'PROCESSING',
	PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
	CONSOLIDATED = 'CONSOLIDATED',

	// archiviato
	FULFILLED = 'FULFILLED',

	// cancellato
	DELETED = 'DELETED',
	ERROR_PREPARING = 'ERROR_PREPARING',
	ERROR_SENDING = 'ERROR_SENDING'
}
