import { Component, OnInit } from '@angular/core';
// model
import { SummaryListModel } from '../../model/summary.model';
// rest
import { SummaryService } from '../../service/rest/summary.service';
// model
import { StatusBarElement, StatusBarConfig } from '../../widget/status-bar/status-bar.component';
import { CardTrendConfigurationModel } from '../../widget/card-trend/card-trend.component';
import { CardChartConfigurableConfigurationModel, CardChartConfigurableConfigurationSelected } from '../../widget/card-chart-configurable/card-chart-configurable.component';
import { ApexOptions } from 'ng-apexcharts';
// widget
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SummaryTableColumnService } from '../../service/td-data-table/implementation/summary.service';
import { IPageChangeEvent } from '@covalent/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogTextEditComponent } from '../../widget/dialog/dialog-text-edit/dialog-text-edit.component';
import * as moment from 'moment';

@Component({
	selector: 'summary-page',
	templateUrl: './summary-page.component.html',
	styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent implements OnInit {

	summaryList: SummaryListModel = new SummaryListModel();

	/**
	 * StatusBarComponent
	 */
	statusBarConfig: StatusBarConfig = {
		condensed: true,
		theme: 'gray',
		steps: [
			{
			label: "Primo",
			icon: "create"
			},
			{
			label: "Secondo",
			icon: "rotate_right",
			selected: true
			},
			{
			label: "Terzo",
			icon: "local_shipping"
			},
			{
			label: "Quarto",
			icon: "archive"
			}
		]
	};

	/**
	 * CardTrendComponent
	 */
	cardTrendConfiguration: CardTrendConfigurationModel = {
		theme: "mat-accent",
		title: "Titolo",
		subtitle: "Sottotitolo",
		trend: 7.2,
		time: "Settimana",
		value: '22',
	}
	// configurazione del grafico importato nel ng-content
	cardTrendConfigurationChart: ApexOptions = {
		chart: {
			type: 'line',
			toolbar: {
				show: false
			}
		},
		colors: ['#078BBD'],
		stroke: {
			curve: 'smooth',
			width: 2,
			dashArray: 0,
		},
		xaxis: {
			labels: {
				show: false
			},
			axisBorder: {
				show: false
			}
		},
		yaxis: {
			labels: {
				show: false
			}
		},
		tooltip: {
			followCursor: true,
			x: {
				show: false
			},
			fixed: {
				enabled: true,
				position: 'topLeft'
			},
		},
		grid: {
			show: false
		},
		series: [
			{
				name: '2019',
				data: [
					{
						x: '10 mag 2019',
						y: 78
					},
					{
						x: '11 mag 2019',
						y: 94
					},
					{
						x: '12 mag 2019',
						y: 101
					},
					{
						x: '13 mag 2019',
						y: 102
					}
				]
			}
		]
	}

	/**
	 * CardChartConfigurableConfiguration
	 * da capire come strutturare la chiamata BE
	 */
	cardChartConfigurableConfiguration: CardChartConfigurableConfigurationModel = {
		title: "Vendite per Prodotto",
		apexChart: {
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				}
			},
			colors: ['#ff9800', '#078BBD'],
			stroke: {
				curve: 'smooth',
				width: 2,
				dashArray: 0,
			},
			xaxis: {
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: false
				}
			},
			tooltip: {
				followCursor: true,
				x: {
					show: false
				},
				fixed: {
					enabled: true,
					position: 'topLeft'
				},
			},
			grid: {
				show: false
			},
			series: [
				{
					name: '2018',
					data: [
						{
							x: '10 mag 2018',
							y: 52
						},
						{
							x: '11 mag 2018',
							y: 75
						},
						{
							x: '12 mag 2018',
							y: 12
						},
						{
							x: '13 mag 2018',
							y: 102
						}
					]
				},
				{
					name: '2019',
					data: [
						{
							x: '10 mag 2019',
							y: 78
						},
						{
							x: '11 mag 2019',
							y: 94
						},
						{
							x: '12 mag 2019',
							y: 101
						},
						{
							x: '13 mag 2019',
							y: 102
						}
					]
				}
			]
		},
		datasetType: [
			{
				label: "Numero di Pezzi",
				id: "pieces",
				icon: "details",
				selected: true
			},
			{
				label: "Valore Economico",
				icon: "attach_money",
				id: "value"
			}
		],
		datasetPeriod: [
			{
				label: 'Ultimi 3 mesi',
				start: +moment().subtract(3, 'months').format('x'),
				end: +moment().format('x'),
				selected: true
			},
			{
				label: 'Ultimi 6 mesi',
				start: +moment().subtract(6, 'months').format('x'),
				end: +moment().format('x')
			},
			{
				label: 'Ultimi 12 mesi',
				start: +moment().subtract(12, 'months').format('x'),
				end: +moment().format('x')
			}
		]
	}

	constructor(
		private summaryService: SummaryService,
		private translateService: TranslateService,
		public summaryTableColumnService: SummaryTableColumnService,
		private snackbar: MatSnackBar,
		private dialog: MatDialog,
	) {
		this.summaryTableColumnService.columns.forEach(column => {
			this.translateService.get(column.label).subscribe(val => {
				column.label = val;
			});
		});
		this.summaryList.pagination = {
			page_current: 0,
			page_size: 20
		};
		this.getSummaryFilteredList();
	}

	ngOnInit() {}

	// util

	/**
	 * DialogTextEditComponent
	 */
	openDialogTextEdit() {
		const dialog = this.dialog.open(DialogTextEditComponent, {
      data: {
				disabled: true,
				title: 'Dialgo Text Edit',
				text: 'Text to edit'
			}
    });
    dialog.afterClosed().subscribe(res => {
			if (res) {
				console.log(res);
			}
    });
	}

	/**
	 * CardChartConfigurableConfiguration: metodo da invocare dopo la get dei dati
	 * 
	 */
	onCardChartConfigurableConfigurationChange(e: CardChartConfigurableConfigurationSelected) {
		console.log(e);
	}

	/**
	 * AttachmentResolverComponent
	 */
	onAttachmentResolverFileChange(e) {
		console.log(e);
	}

	// td-paging-bar
	changePageSize(s: number) {
		this.summaryList.pagination.page_size = s;
		this.getSummaryFilteredList();
	}

	changePage(e: IPageChangeEvent) {
		this.summaryList.pagination.page_current = e.page;
		this.getSummaryFilteredList();
	}

	// rest
	getSummaryFilteredList() {
		this.summaryService.getFilteredList(this.summaryList.pagination)
		.then(res => {
			this.summaryList = res;
		})
		.catch(err => {
			this.snackbar.open(err, this.translateService.instant('general.close').toUpperCase(), {
				duration: 2000
			});
		});
	}
}
