import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class OrderDashboardColumnService extends BaseTableListService {
  constructor() {
    super();
  }
  columns: ITdDataTableColumn[] = [
    {
      name: "Address",
      label: "Indirizzo",
    },
    {
      name: "tot",
      label: "Spesa media",
      numeric: true,
      width: 150,
      format: (v: number) => v.toFixed(2).replace('.', ',') + " €" // TODO fare check se corretto
    }
  ];
}
