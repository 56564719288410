import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf, Type } from '@angular/core';

import { AppConfig, ConfigModel } from '../../app.config';
import { PouchDbBaseAdapter } from './centroutensili/pouchdb-base-adapter.service';
import { PouchUtilService } from './pouch-util.service';
import { PouchDbAdapter } from './structure/pouchdb-adapter';

export const loadPouchService = (
	appConfig: AppConfig,
	pouchUtilService: PouchUtilService,
	...pouchAdapter: PouchDbBaseAdapter[]
) => {
	return () => {
		return new Promise((resolve, reject) => {
			appConfig.config$.subscribe(async (config: ConfigModel) => {
				if (config.urlConfig.couch) {
					pouchUtilService.pouchInstance = pouchAdapter;
					await pouchUtilService.explicitInitCouch();
					resolve();
				}
			});
		});
	};
};
@NgModule({
	imports: [],
	declarations: [],
	exports: []
})
export class PouchdbModule {
	constructor(@Optional() @SkipSelf() parentModule: PouchdbModule) {
		if (parentModule) {
			throw new Error('PouchdbModule is already loaded. Import it in the AppModule only');
		}
	}
	static forRoot(config: Type<PouchDbAdapter>[]): ModuleWithProviders {
		return {
			ngModule: PouchdbModule,
			providers: [
				...config,
				{
					provide: APP_INITIALIZER,
					deps: [AppConfig, PouchUtilService, ...config],
					useFactory: loadPouchService,
					multi: true
				}
			]
		};
	}
}
