import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';

export interface PriceFilter {
	clientCode: string;
	destination?: string;
	articleCode: string | string[];
	lineCode?: string;
	listCode: string;
}

export interface ArticlePriceFilter {
	type: string;
	codice: string;
	codice_articolo?: string[] | string;
	valid: string;
}

export interface ArticleCodes {
	codice_articolo: string;
	// tipo_sk: string
}

export class PricePouch extends AbstractPouchdbMethod {
	private getListFilters(filter): ArticlePriceFilter {
		return {
			type: 'list',
			codice: filter.listCode,
			valid: 'True'
		};
	}

	private getListParams(filter: ArticlePriceFilter, articleCodes: string[]) {
		const minArticleCode = articleCodes[0];
		const maxArticleCode = articleCodes[articleCodes.length - 1];
		const params = {
			$and: [
				{
					type: filter.type,
					codice: filter.codice,
					codice_articolo: {
						$gte: minArticleCode,
						$lte: maxArticleCode
					},
					valid: filter.valid
				},
				{
					codice_articolo: {
						$in: articleCodes
					}
				}
			]
		};
		return params;
	}

	private getScartFilters(filter): ArticlePriceFilter {
		return {
			type: 'article_sheet',
			codice: filter.clientCode,
			valid: 'True'
		};
	}

	private getScartParams(
		filter: ArticlePriceFilter,
		articleCodes: string[],
		articleGroupCode: string,
		articleBudgetNumber: string,
		lineCode: string
	) {
		const minArticleCode = articleCodes[0];
		const maxArticleCode = articleCodes[articleCodes.length - 1];
		const scartArticleCodes = [lineCode].concat(articleCodes, articleGroupCode, articleBudgetNumber);
		const params = {
			$and: [
				{
					type: filter.type,
					codice: filter.codice,
					codice_articolo: {
						$or: [
							{
								$eq: lineCode
							},
							{
								$gte: minArticleCode,
								$lte: maxArticleCode
							},
							{
								$eq: articleGroupCode
							},
							{
								$eq: articleBudgetNumber
							}
						]
					},
					valid: filter.valid
				},
				{
					codice_articolo: {
						$in: scartArticleCodes
					}
				}
			]
		};
		return params;
	}

	async getPrice(filter: any): Promise<any> {
		const prices = {};
		const promisePrice: any[] = [];
		const articleCodes = filter.articleCode;
		const articleGroupCode = filter.articleGroupCode;
		const articleBudgetNumber = filter.articleBudgetNumber;

		const listFilter: ArticlePriceFilter = this.getListFilters(filter);
		const listParams = this.getListParams(listFilter, articleCodes);

		promisePrice.push(
			this.retrieveGenericArticleDetail(listParams).then(doc => {
				prices['list'] = doc;
				return { list: doc };
			})
		);

		const scartFilter: ArticlePriceFilter = this.getScartFilters(filter);
		const scartParams = this.getScartParams(
			scartFilter,
			articleCodes,
			articleGroupCode,
			articleBudgetNumber,
			filter.lineCode
		);

		promisePrice.push(
			this.retrieveGenericArticleDetail(scartParams).then(doc => {
				prices['scart'] = doc;
				return { scart: doc };
			})
		);

		await Promise.all(promisePrice);
		return prices;
	}

	private retrieveGenericArticleDetail(params) {
		return new Promise(resolve => {
			this.getWithIndexFilter(params.$and.type, params as PouchDB.Find.Selector)
				.then((doc: any[]) => {
					resolve(doc['docs']);
				})
				.catch(error => {
					resolve([]);
				});
		});
	}
}
