import { OnDestroy, OnInit } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateMomentService } from '../../util/date-moment.service';

import { BaseTableListService } from '../base-table-list.service';

// Translate
export class AgentOrderColumnService extends BaseTableListService implements OnInit, OnDestroy {
	readonly hierarchy: { value: string; description: string }[] = [
		{ value: 'creation_date', description: 'Data di creazione' }
	];

	columns: ITdDataTableColumn[] = [
		{
			name: 'header.status',
			label: '',
			width: 50
		},
		{
			name: '_id',
			label: this.translate.instant('columnsData.order_number')
		},
		{
			name: 'header.date',
			label: this.translate.instant('columnsData.creation_date'),
			width: 150,
			format: value => {return this.convertMilllisStringToStringDate(value)}
		},
		{
			name: 'header.first_evasion_date',
			label: this.translate.instant('columnsData.delivery_date'),
			width: 150,
			format: value => {return this.convertMilllisStringToStringDate(value)}
		},
		{
			name: 'header.total',
			label: this.translate.instant('columnsData.amount'),
			numeric: true,
			width: 200
		}
	];

	onLangChange$: Subscription;

	constructor(private translate: TranslateService,
		private dateMomentService: DateMomentService,
		) {
		super();
		this.onLangChange$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.columns = [
				{
					name: '_id',
					label: 'ID'
				},
				{
					name: 'header.date',
					label: this.translate.instant('columnsData.creation_date'),
					width: 150,
					format: value => {return this.convertMilllisStringToStringDate(value)}
				},
				{
					name: 'header.first_evasion_date',
					label: this.translate.instant('columnsData.delivery_date'),
					width: 150,
					format: value => {return this.convertMilllisStringToStringDate(value)}
				},
				{
					name: 'header.status',
					label: this.translate.instant('columnsData.status'),
					width: 50
				},
				{
					name: 'header.total',
					label: this.translate.instant('columnsData.amount'),
					numeric: true,
					width: 200
				}
			];
		});
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}
}
