import { Component } from '@angular/core';
import { PageCatalogComponent } from '../page/catalog/page-catalog.component';
import { PublicWrapperComponent } from './../page/public/public-wrapper/public-wrapper.component';
import { Routes } from '@angular/router';
// guard
import { AuthGuard } from '../service/guard/auth.guard';
// wrapper
import { AuthenticationWrapperComponent } from '../page/authentication/authentication-wrapper/authentication-wrapper.component';
import { FramePageComponent } from '../frame/admin/admin.component';
// page
import { LoginComponent } from '../page/authentication/login/login.component';
import { PasswordRecoveryComponent } from '../page/authentication/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from '../page/authentication/password-update/password-update.component';
import { ClientCodeSelectComponent } from '../page/authentication/client-code-select/client-code-select.component';
import { AccessRequestComponent } from '../page/authentication/access-request/access-request.component';

export const routes: Routes = [
	{
		path: 'authentication',
		component: AuthenticationWrapperComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent,
				pathMatch: 'full'
			},
			{
				path: 'password-recovery',
				component: PasswordRecoveryComponent,
				pathMatch: 'full'
			},
			{
				path: 'password-update',
				component: PasswordUpdateComponent,
				pathMatch: 'full'
			},
			{
				path: 'client-code-select',
				component: ClientCodeSelectComponent,
				pathMatch: 'full'
			},
			{
				path: 'access-request',
				component: AccessRequestComponent,
				pathMatch: 'full'
			}
		]
	},
	{
		path: '',
		canActivate: [AuthGuard],
		component: FramePageComponent,
		children: []
	},
	{
		path: 'public',
		component: PublicWrapperComponent,
		children: [
			{
				path: 'catalog',
				component: PageCatalogComponent
			}
		]
	},
	{
		path: '**',
		redirectTo: ''
	}
];
