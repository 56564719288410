import { OnDestroy, OnInit } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { BaseTableListService } from '../base-table-list.service';

export class ArticlePublicCatalogColumnService extends BaseTableListService implements OnInit, OnDestroy {
	onLangChange$: Subscription;
	constructor(private translate: TranslateService) {
		super();
		this.onLangChange$ = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.columns = [
				{
					name: 'codice',
					label: this.translate.instant('columnsData.ref'),
					width: 110
				},
				{
					name: 'descrizione',
					label: this.translate.instant('order.family.description')
				},
				{
					name: 'pezzi_confezione',
					label: this.translate.instant('order.family.piecesPerPack'),
					width: 110
				},
				{
					name: 'stock',
					label: this.translate.instant('order.family.availability'),
					width: 90,
					sortable: false
				},
				{
					name: 'stato_articolo',
					label: '',
					width: 50,
					sortable: false
				},
				{
					name: 'article_price',
					label: this.translate.instant('order.family.price'),
					numeric: true,
					width: 110
				}
				// {
				// 	name: 'qty',
				// 	label: this.translate.instant('order.family.quantity'),
				// 	width: 110
				// },
				// {
				// 	name: 'discount',
				// 	label: this.translate.instant('order.family.discount'),
				// 	width: 100
				// },
				// {
				// 	name: 'calculate_price',
				// 	label: this.translate.instant('order.family.amount'),
				// 	numeric: true,
				// 	width: 110
				// }
			];
		});
	}

	columns: ITdDataTableColumn[] = [
		{
			name: 'codice',
			// label: this.translate.instant('columnsData.ref'),
			label: 'Articolo',
			width: 110
		},
		{
			name: 'descrizione',
			// label: this.translate.instant('order.family.description')
			label: 'Descrizione'
		},
		{
			name: 'pezzi_confezione',
			// label: this.translate.instant('order.family.piecesPerPack'),
			label: 'Pezzi per confezione',
			width: 110
		},
		{
			name: 'stock',
			// label: this.translate.instant('order.family.availability'),
			label: 'Disponibilità',
			width: 90,
			sortable: false
		},
		{
			name: 'stato_articolo',
			label: '',
			width: 50,
			sortable: false
		},
		{
			name: 'article_price',
			// label: this.translate.instant('order.family.price'),
			label: 'Prezzo',
			numeric: true,
			width: 110
		}
		// {
		// 	name: 'qty',
		// 	label: this.translate.instant('order.family.quantity'),
		// 	width: 110
		// },
		// {
		// 	name: 'discount',
		// 	label: this.translate.instant('order.family.discount'),
		// 	width: 100
		// },
		// {
		// 	name: 'calculate_price',
		// 	label: this.translate.instant('order.family.amount'),
		// 	numeric: true,
		// 	width: 110
		// }
	];

	ngOnInit(): void {}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
	}
}
