import { Component, OnInit } from '@angular/core';
// enum
import { FileTypeEnum } from '../../../enum/fileType.enum'
// widget & utility
import { MatSnackBar } from '@angular/material';
import { NewOrderService } from '../../../service/rest/newOrder.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'order-import-excel',
  templateUrl: './order-import-excel.component.html',
  styleUrls: ['./order-import-excel.component.scss']
})
export class OrderImportExcelComponent implements OnInit {

  button_confirm_upload: Boolean = false;
  acceptedType = FileTypeEnum.EXCEL;
  upload_data = [];
  orderCompany: string = this.route.snapshot.parent.params['idCompany'];
  order_message: string;

  constructor(
    private router: Router,
    private newOrderService: NewOrderService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  uploadNewOrder() {
    this.newOrderService.uploadNewOrder(
      this.upload_data[0],
      {client_code: this.orderCompany},
      res => {
        this.order_message = res.message.message + ": " + res.message.order_id
        this.snackBar.open(
          this.order_message, this.translateService.instant('general.close').toUpperCase(), 
          { duration: 10000 }
        );
        this.router.navigate([
          `/orders/draft/${res.message.client_code}/${
            res.message.order_id
          }/carrello`
        ]);
      },
      err => {
        this.order_message = err.body.message.message
        this.snackBar.open(
          this.order_message, this.translateService.instant('general.close').toUpperCase(), 
          { duration: 10000 }
        );
      }
    );
  }

  // EVENTS

  onAttachmentResolverFileChange(e: any[]) {
    this.button_confirm_upload = !(e.length === 0);
    this.upload_data = e;
    console.log(e);
  }

  // WIDGET & UTILITY

  // genericSuccessMessage() {
  //   this.snackBar.open(this.translateService.instant('generic.success_message'), 'OK', {
  //     duration: 3000
  //   });
  // }

  // genericErrorMessage() {
  //   this.snackBar.open(this.translateService.instant('generic.error_message'), 'OK', {
  //     duration: 10000
  //   });
  // }

}
