import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import * as fromFamilyList from './family-list.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('family-list', fromFamilyList.reducer)
    ]
})
export class FamilyListStateModule { }
