import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCompanyList from './company-list/company-list.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromOrders from './order-list/order-list.reducer';
import * as fromOrder from "./order/order.reducer";
import * as fromArticle from "./article-list/article-list.reducer";
import * as fromCategories from "./category-list/category-list.reducer";
import * as fromAuxiliaryTable from "./auxiliary_table/auxiliary_table.reducer";
import * as fromDestinationList from "./destination-list/destination-list.reducer";
import * as fromTagList from "./tag-list/tag-list.reducer";
import * as fromFamilyList from "./family-list/family-list.reducer";
import { UserState } from './user/user.reducer';

// user
export const getUserState = createFeatureSelector<UserState>('user');

export const getCompanyListState = createFeatureSelector<fromCompanyList.CompanyListState>('company-list');
export const getCompanyState = createFeatureSelector<fromCompany.CompanyState>('company');

export const getOrdersState = createFeatureSelector<fromOrders.OrderListState>('order-list');
export const getOrderState = createFeatureSelector<fromOrder.OrderState>('order');
export const getCategoriesState = createFeatureSelector<{ data: fromCategories.CategoryListState }>('categories');
export const getArticleListState = createFeatureSelector<fromArticle.ArticleListState>('article-list');
export const getDestinationListState = createFeatureSelector<fromDestinationList.DestinationListState>('destination-list');
export const tagListState = createFeatureSelector<fromTagList.TagListState>('tag-list');
export const getFamilyListState = createFeatureSelector<fromFamilyList.FamilyListState>('family-list');

// auxiliary tables

export const getAuxiliaryTableState = createFeatureSelector<fromAuxiliaryTable.AuxiliaryTableState>('auxiliary-table-list');

export const getAuxiliaryModalitaDiConsegna = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.modalita_di_consegna
);

export const getAuxiliaryZoneCommerciali = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.zone_commerciali
);

export const getAuxiliaryCausaliTestateOdv = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.causali_testate_odv
);

export const getAuxiliaryNazioniIso = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.nazioni_iso
);

export const getAuxiliaryCodiciIva = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.codici_iva
);

export const getAuxiliaryTipiListini = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.tipi_listini
);

export const getAuxiliaryModalitaDiSpedizione = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.modalita_di_spedizione
);

export const getAuxiliaryPagamenti = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.pagamenti
);

export const getAuxiliaryCausaliSospensioneOrdini = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.causali_sospensione_ordini
);

export const getAuxiliaryUnitaMisura = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.unita_misura
);

export const getAuxiliaryTipiValuta = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.tipi_valuta
);

export const getAuxiliaryVettori = createSelector(
    getAuxiliaryTableState,
    (state: fromAuxiliaryTable.AuxiliaryTableState) => state.vettori
);
