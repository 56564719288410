import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';

export class TagListPouch extends AbstractPouchdbMethod {

    getTagGraphList(tag_graph: string): Promise<any> {
		return new Promise(resolve => {
			this.get(tag_graph)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
    }

    getTagDescriptionList(): Promise<any> {
		const document_id = this.getCurrentLang().toUpperCase() === 'IT' ? 'tags' : 'tags_translation';
		return new Promise(resolve => {
			this.get(document_id)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
