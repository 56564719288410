import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
	ViewChild,
	OnDestroy
} from '@angular/core';
import { TagPouchModel } from '../../model/pouch/tag.model';
import { Subscription, Observable } from 'rxjs';
import { MatCheckbox } from '@angular/material/checkbox';

import { MatAccordion } from '@angular/material';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

interface TagPouchObject {
	W1_CODAZI?: TagPouchModel[];
	W1_GRUPPO?: TagPouchModel[];
	W1_CLASSE?: TagPouchModel[];
	W1_SUBCLAS?: TagPouchModel[];
	W1_NORMA?: TagPouchModel[];
	W1_MATERIA?: TagPouchModel[];
	W1_TRATSUP?: TagPouchModel[];
}

@Component({
	selector: 'nested-tag-object',
	templateUrl: './nested-tag-object.component.html',
	styleUrls: ['./nested-tag-object.component.scss']
})
export class NestedTagObjectComponent implements OnInit, OnDestroy {
	@ViewChild('accordion', { static: true }) Accordion: MatAccordion;

	readonly hierarchy: { key: string; value: string; description: string }[] = [
		{ key: 'W1_GRUPPO', value: 'GRUPPO', description: 'GRUPPO' },
		{ key: 'W1_CLASSE', value: 'TIPOLOGIA', description: 'TIPOLOGIA' },
		{ key: 'W1_SUBCLAS', value: 'CARATTERISTICA', description: 'CARATTERISTICA' },
		{ key: 'W1_NORMA', value: 'NORMA', description: 'NORMA' },
		{ key: 'W1_MATERIA', value: 'MATERIALE', description: 'MATERIALE' },
		{ key: 'W1_TRATSUP', value: 'RIVESTIMENTO', description: 'RIVESTIMENTO' }
	];

	tagsByHierarchy: TagPouchObject[] = [];
	selectedTags: TagPouchModel[] = [];

	viewList: any[];
	onLangChange$: Subscription;

	@Input('data') set object(o: TagPouchObject) {
		if (o) {
			this.tagsByHierarchy = this.configTagList(o);
		}
	}

	private eventsSubscription: Subscription = new Subscription();

	@Input('reset') resetEvents: Observable<boolean>;

	@Output() selectionEmitter = new EventEmitter<TagPouchModel[]>();

	@ViewChildren(MatCheckbox) checkboxList: QueryList<MatCheckbox>;

	constructor(private translateService: TranslateService) {}

	ngOnInit() {
		this.setHierarchyTranslations();

		this.onLangChange$ = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.setHierarchyTranslations();
		});

		if (this.resetEvents) {
			this.eventsSubscription = this.resetEvents.subscribe(res => {
				if (res) {
					this.resetTagList();
					this.Accordion.closeAll();
				}
			});
		}
	}

	ngOnDestroy() {
		this.onLangChange$.unsubscribe();
		this.eventsSubscription.unsubscribe();
	}

	setHierarchyTranslations() {
		this.translateService.get('catalogFilter').subscribe(labels => {
			this.hierarchy.find(element => element.key === 'W1_GRUPPO').description = labels['group'];
			this.hierarchy.find(element => element.key === 'W1_CLASSE').description = labels['typology'];
			this.hierarchy.find(element => element.key === 'W1_SUBCLAS').description = labels['characteristic'];
			this.hierarchy.find(element => element.key === 'W1_NORMA').description = labels['standard'];
			this.hierarchy.find(element => element.key === 'W1_MATERIA').description = labels['material'];
			this.hierarchy.find(element => element.key === 'W1_TRATSUP').description = labels['coating'];
		});
	}

	isTagInSelectedTags(tag: TagPouchModel) {
		return this.selectedTags.includes(tag);
	}

	removeTagFromSelectedTags(tag: TagPouchModel) {
		this.selectedTags = this.selectedTags.filter(t => t.code !== tag.code);
	}

	addTagToSelectedTags(tag: TagPouchModel) {
		this.selectedTags.push(tag);
	}

	toggleTagFromSelectedTags(tag: TagPouchModel) {
		if (this.isTagInSelectedTags(tag)) {
			this.removeTagFromSelectedTags(tag);
		} else {
			this.addTagToSelectedTags(tag);
		}
	}

	findTagInTagsHierarchy(tagIdToFind: string): TagPouchModel {
		let foundTag: TagPouchModel;
		this.hierarchy.some(level => {
			foundTag = this.tagsByHierarchy[level.value].find((tag: TagPouchModel) => tag.tag === tagIdToFind);
			return foundTag !== undefined;
		});
		return foundTag;
	}

	isTagCompatibleWith(tagId: string) {
		let compatibility = false;
		this.selectedTags.forEach(element => {
			compatibility = compatibility || element.incompatibility.includes(tagId);
		});
		return compatibility;
	}

	toggleTagsVisibility(tag: TagPouchModel) {
		tag.incompatibility.forEach(tagId => {
			const incopatibleTag = this.findTagInTagsHierarchy(tagId);
			if (incopatibleTag) {
				incopatibleTag.active = this.isTagCompatibleWith(tagId);
			}
		});
	}

	selectTag(selectedTag: TagPouchModel) {
		this.toggleTagFromSelectedTags(selectedTag);
		this.toggleTagsVisibility(selectedTag);

		this.selectionEmitter.emit(this.selectedTags);
	}

	configTagList(tagObject: TagPouchObject): TagPouchObject[] {
		let result: TagPouchObject[] = [];

		this.hierarchy.forEach(level => {
			result[level.value] = tagObject[level.key];
		});
		return result;
	}

	resetTagList() {
		this.hierarchy.forEach(level => {
			this.tagsByHierarchy[level.value].forEach(tag => {
				tag.active = false;
			});
		});
		this.checkboxList.forEach(check => {
			if (check.checked) {
				check.toggle();
			}
		});
		this.selectedTags = [];
		this.selectionEmitter.emit(this.selectedTags);
	}
	checkDescr(n) {
		if (n.length > 30) {
			return n;
		} else {
			return null;
		}
	}
}
