import { Injectable } from '@angular/core';
// model
import { UserState } from './user.reducer';
// service
import { UserService } from '../../service/rest/user.service';
import { AuthService } from '../../service/rest/auth.service';
// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUser from './user.actions';
// utility & widget
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[User] Load'),
			mergeMap(action => from(this.getUserRest(action))),
			map(user => (fromUser.update(user))),
			catchError(() => of({ type: '[User] User Load Error' }))
		)
	);

	constructor(
		private actions$: Actions,
		private userService: UserService,
		private authService: AuthService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService
	) { }

	getUserRest(user: UserState): Promise<any> {

		return this.userService.getUserDetail({ id: user.id })
			.then(res => {
				return res;
			})
			.catch((err: any) => {
				this.authService.logout();
			});
	}

}