import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromState from '../../../state/index';
import * as fromCompanyList from '../../../state/company-list/company-list.actions';
import { CompanyListState } from '../../../state/company-list/company-list.reducer';
import { CompanyColumnService } from '../../../service/td-data-table/implementation/company.service';
import { Pagination } from '../../../model/structure/list-structure.model';
import { TdDataTableComponent, ITdDataTableRowClickEvent } from '@covalent/core';

@Component({
	selector: 'company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.scss']
})
export class BackofficeCompanyComponent implements OnInit {

	companyList$: Observable<CompanyListState> = this.store.select(fromState.getCompanyListState);
	_companyList: Subscription;
	companyList: CompanyListState = {
		pagination: {
			page_current: 1,
			page_size: 20
		},
		filters: {
			exists: ["users"]
		}
	};

	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

	constructor(
		private router: Router,
		private store: Store<any>,
		public companyColumnService: CompanyColumnService,
	) {
		this.getCompanyFilteredList();
		this._companyList = this.companyList$.subscribe((res: CompanyListState) => {
			if (res) {
			  this.companyList = res;
			}
		});
	}
	ngOnInit() {}
	ngOnDestroy() {
		this._companyList.unsubscribe();
		this.store.dispatch(fromCompanyList.reset());
	}

	// data
	getCompanyFilteredList() {
		this.store.dispatch(fromCompanyList.load(this.companyList));
	}

	// navigation
	paginationChange(pagination: Pagination) {
		this.companyList.pagination = pagination;
		this.getCompanyFilteredList();
	}

	goToDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([`/backoffice/company/${e.row.codice}`]);
	}

	// widget
	toggleColumn(e) {
		for (let i = 0; i < this.companyColumnService.columns.length; i++) {
			if (this.companyColumnService.columns[i].name === e.name) {
				this.companyColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}
}
