import { Injectable } from '@angular/core';
import { PouchDbCentroutensiliAdapter } from '../pouchdb/centroutensili/pouchdb-centroutensili-adapter.service';
import moment from 'moment';
import { CardArrayItemModel } from '../../widget/card-trend/card-trend.component';
import { PouchDbNuiAdapter } from '../pouchdb/centroutensili/pouchdb-nui-adapter.service';
import { AppConfig } from '../../app.config';
import { PouchUtilService } from '../pouchdb/pouch-util.service';
import { PouchDbAdapter } from '../pouchdb/structure/pouchdb-adapter';

@Injectable({
	providedIn: 'root'
})
export class ChartService {
	constructor(private pouchUtilService: PouchUtilService) {}

	getOrdersFromTo(list: any[], from: number, to: number) {
		// console.log("list: ", list);
		// console.log("from-to: ", from, to);
		const fromMoment = +moment(from, 'x');
		const toMoment = +moment(to, 'x');
		const result = [];
		list.forEach(element => {
			const elMoment = +moment(element.date, 'x');
			if (elMoment>fromMoment &&  elMoment<=toMoment) {
				result.push(element);
			}
		});
		return result;
	}

	public getOrdersByClientAndDates(clientCode: string, from: number, to: number): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().orderChart.findOrdersByClientCodeAndDates(clientCode, from, to);
	}

	public getOrdersByDates(from: number, to: number): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().orderChart.findOrdersByDates(from, to);
	}

	public getClientByClientCode(clientCode: string): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().codiceClientePouch.getClient(clientCode);
	}

	// [{client_code, date, status, total}, {...}, ...]
	public groupBy(list: any[], field: string) {
		list = list === undefined ? [] : list;
		const groupedList: Object = {};
		list.forEach(element => {
			if (element.hasOwnProperty(field)) {
				if (groupedList[element[field]] === undefined) {
					groupedList[element[field]] = [];
				}
				groupedList[element[field]].push(element);
			}
		});
		// {field1: [{client_code, date, status, total}, {...}, ...], field2: [{client_code, date, status, total}, {...}, ...], ...}
		return groupedList;
	}

	public calculateAverageOnField(list: any[], field: string) {
		let valoreMedioOrdini = 0;
		const numOrdiniArchivedWeek = list === undefined ? 0 : list.length;
		if (numOrdiniArchivedWeek === 0) {
			valoreMedioOrdini = 0;
		} else {
			list.forEach(order => {
				if (order[field]) {
					valoreMedioOrdini += order[field];
				}
			});
			valoreMedioOrdini /= numOrdiniArchivedWeek;
		}
		return valoreMedioOrdini;
	}

	public calculatePercentage(baseAvg: number, currAvg: number): number {
		if (baseAvg === 0) {
			if (currAvg === 0) {
				return 0;
			}
			return 100;
		}
		return ((currAvg - baseAvg) / baseAvg) * 100;
	}

	// toShowOrders: The orders that are shown on the chart
	// toCompareOrders: The orders against which to calculate trend
	public calculateOrderChart(
		orderChart: CardArrayItemModel,
		toShowOrders: any[],
		toCompareOrders: any[]
	): CardArrayItemModel {
		const today = moment().endOf('day');
		const lastWeek = moment()
			.subtract(6, 'days')
			.startOf('day');

		orderChart.main.xaxis.categories = this.getDayWeekLabels(lastWeek, today);

		const calculatedDraftTrend = this.calculatePercentage(toCompareOrders.length, toShowOrders.length);
		orderChart.cardTrendConfiguration.value = toShowOrders.length.toString();
		orderChart.cardTrendConfiguration.trend = +calculatedDraftTrend.toFixed(2);

		let index = 0;
		const data = [];
		for (let d = lastWeek; d <= today; d.add(1, 'days')) {
			const formDate = d.format('YYYY/MM/DD');
			var ordersInDay : number = 0;
			toShowOrders.forEach(
				order => {
					if(moment(order.date).format("DD/MM/YYYY") == d.format("DD/MM/YYYY")){
						ordersInDay += 1;
					}
				}
			);
			data[index] = ordersInDay;
			index += 1;
		}
		orderChart.main.series[0].data = data;

		return orderChart;
	}

	private getDayWeekLabels(from: moment.Moment, to: moment.Moment): string[] {
		const result = [];
		const weekdays: string[] = moment.weekdaysShort();
		const momFrom = moment(from);
		const momTo = moment(to);
		for (const d = momFrom; d <= momTo; d.add(1, 'days')) {
			result.push(weekdays[d.weekday()]);
		}
		return result;
	}
}
