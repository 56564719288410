import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export class CategoryListModel {
  _id?: string;
  _rev?: string;
  level_1?: NestedCategoryItemModel[];
  type?: string;
}
export class NestedCategoryItemModel {
  id?: string;
  label?: string;
  parent?: string;
  data?: NestedCategoryItemModel[];
}
// export class NestedCategoryItemModel {
//   code?: string;
//   description?: string;
//   sequence?: string;
//   level_2?: NestedCategoryItemModel[];
//   articles?: Article[];
// }

export class NestedCategoryConfigModel {
  selected?: string;
  data: NestedCategoryItemModel[];
}

@Component({
  selector: 'nested-category-list',
  templateUrl: './nested-category-list.component.html',
  styleUrls: ['./nested-category-list.component.scss']
})
export class NestedCategoryListComponent implements OnInit {

  _list: NestedCategoryItemModel[] = [];
  listRendered: NestedCategoryItemModel = new NestedCategoryItemModel();
  resettedList: any[];
  selected: string = null;
  breadcrumb: string[] = [];

  @Input('list') set list(l: NestedCategoryItemModel[]) {
    if (l) {
      this.resettedList = l;
      this._list = this.configCategory(l);
      this.selectCategory();
    }
  }

  @Output() selectionEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  configCategory(list: any[], parent?: string, index?: number): NestedCategoryItemModel[] {
    let level = index ? index : 2;
    const listCategory: NestedCategoryItemModel[] = [];
    list.forEach((element, i) => {
      const nc: NestedCategoryItemModel = new NestedCategoryItemModel();
      nc.id = element.code;
      nc.label = element.description;
      if (element['level_' + level.toString()]) {
        nc.data = this.configCategory(element['level_' + level.toString()], element.code, level + 1);
      }
      nc.parent = parent ? parent : '';
      listCategory.push(nc);
    });

    return listCategory;
  }

  selectCategory(id?: string) {
    let found = false;
    const a = this._list;
    if (id) {
      // ricerca ricorsiva della categoria selezionata
      const search = (a, id) => {
        for (let i = 0; i < a.length; i++) {
          const l = a[i];
          if (l.id === id) {
            this.selected = l;
            this.listRendered.id = l.id;
            this.listRendered.label = l.label;
            if (l.data) {
              this.listRendered.data = l.data;
            } else {
              this.listRendered.data = [];
            }
            found = true;
          } else if (l.data) {
            search(l.data, id);
          }
          if (found) {
            break;
          }
        }
      };
      search(a, id);
    } else {
      // categorie di primo livello
      this.selected = null;
      this.listRendered.data = this._list;
    }
  }

  // eventi

  selectSubCategory(selected: string) {
    this.breadcrumb.push(selected);
    this.selectCategory(selected);
    this.selectionEmitter.emit(selected);
  }

  selcteParentCategory() {
    this.breadcrumb.pop();
    const selected = this.breadcrumb[this.breadcrumb.length - 1];
    this.selectCategory(selected);
    this.selectionEmitter.emit(selected);
  }

  resetCategoryFilter() {
    console.log(this.resettedList);
    this._list = this.configCategory(this.resettedList);
    this.selectCategory();
  }
}
