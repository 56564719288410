import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

@Injectable()
export class DateMomentService {
	retrieveRangeDate(startDate: Moment, endDate: Moment): any[] {
		const rangeDate = [];
		rangeDate.push(startDate);
		const countDate = moment(startDate);
		while (countDate < endDate) {
			countDate.add(1, 'days');
			rangeDate.push(moment(countDate));
		}
		return rangeDate;
	}

	retrieveWeekEnds(date) {
		// recupero il primo lunedì più vicino rispetto alla data selezionata
		const firstMonday = moment(date);
		firstMonday.day(1);
		// recupero il prossimo sabato rispetto al lunedì recuperato precedentemente
		const firstSaturday = moment(firstMonday);
		firstSaturday.day(6);
		return [firstMonday, firstSaturday];
	}

	retrieveRangesDateDefault() {
		return {
			Today: [moment(), moment()],
			Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'Last 7 Days': [moment().subtract(6, 'days'), moment()],
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			'Last Month': [
				moment()
					.subtract(1, 'month')
					.startOf('month'),
				moment()
					.subtract(1, 'month')
					.endOf('month')
			]
		};
	}

	convertStringDateFromFormatToFormat(stringDate: string, fromFormat: string, toFormat) {
		return moment(stringDate, fromFormat).format(toFormat);
	  }
}
