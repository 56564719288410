import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class CompanyOrderColumnService extends BaseTableListService {

	constructor(
	) {
		super();
	}

	columns: ITdDataTableColumn[] = [
		{
			name: "order_number",
			label: "Ordine #"
		},
		{
			name: "payment_method",
			label: "Metodo pagamento"
		},
		{
			name: "status",
			label: "Stato",
			width: 60
		},
		{
			name: "date",
			label: "Data creazione",
			numeric: true,
			width: 150
		},
		{
			name: "first_evasion_date",
			label: "Data consegna",
			numeric: true,
			width: 150
		},
		{
			name: "tot",
			label: "Importo",
			width: 150,
			numeric: true
		}
	];

}
