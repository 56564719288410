import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NavItem } from '../../model/nav-item.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
//localizzazione
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { UserState } from '../../state/user/user.reducer';
import { Store } from '@ngrx/store';
import * as fromState from '../../state/index';
import { UtilService } from '../util/util.service';

@Injectable()
export class NavigationService {
	private _itemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _items: NavItem[] = [];
	items$: Observable<NavItem[]> = this._itemsSubject.asObservable();

	private _currentlyOpenSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _currentlyOpen: NavItem[] = [];
	currentlyOpen$: Observable<NavItem[]> = this._currentlyOpenSubject.asObservable();

	isIconSidenav: boolean;
	isHorizontal: boolean = false;

	user$: Observable<UserState> = this.store.select(fromState.getUserState);
	_user: Subscription;
	user: UserState;

	constructor(
		private snackbar: MatSnackBar,
		public translate: TranslateService,
		private store: Store<any>,
		private utilService: UtilService
	) {
		if (!this._user) {
			this._user = this.user$.subscribe(res => {
				if (res) {
					this.user = res;
					this._items = [];
					this._itemsSubject.next(this._items);

					const pageCatalog = this.addItem(
						'navigation.catalog',
						'list',
						`/orders/draft/${this.user.client_code}/new/catalogo`,
						10,
						'b2b-dashboard'
					);

					const dashboard = this.addItem('dashboard', 'dashboard', '/', 10, 'b2b-dashboard');
					const orders = this.addItem(
						'navigation.orders',
						'',
						'/orders',
						30,
						'b2b-order',
						null,
						null,
						'head-menu'
					);
					const createOrder = this.addSubItem(
						orders,
						'navigation.newOrder',
						'add_circle',
						`/orders/draft/${this.user.client_code}/new/catalogo`,
						31,
						'b2b-order'
					);
					const drafts = this.addSubItem(
						orders,
						'navigation.draft',
						'gesture',
						'/orders/draft',
						31,
						'b2b-order'
					);
					const processing = this.addSubItem(
						orders,
						'navigation.in_progress',
						'rotate_right',
						'/orders/ready_to_send',
						32,
						'b2b-order'
					);
					const archive = this.addSubItem(
						orders,
						'navigation.fulfilled',
						'folder',
						'/orders/fulfilled',
						33,
						'b2b-order'
					);

					const backoffice_dashboard = this.addItem(
						'dashboard', 
						'dashboard', 
						'/', 
						10, 
						'bo-dashboard'
					);
					const backoffice = this.addItem(
						'navigation.backoffice',
						'',
						'/backoffice',
						40,
						'bo-dashboard',
						null,
						null,
						'head-menu'
					);
					const backoffice_company = this.addSubItem(
						backoffice,
						'navigation.backoffice_company',
						'work',
						'/backoffice/company',
						51,
						'bo-company'
					);
					const backoffice_configuration = this.addSubItem(
						backoffice,
						'navigation.backoffice_configuration',
						'settings',
						'/backoffice/configuration',
						52,
						'bo-configuration'
					);
					const backoffice_agents = this.addSubItem(
						backoffice,
						'navigation.backoffice_agents',
						'work',
						'/backoffice/agent',
						51,
						'bo-agent'
					);

					const media = this.addItem('navigation.media', '', '/media', 50, null, null, 'head-menu');
					const media_center = this.addSubItem(
						media,
						'navigation.mediacenter',
						'perm_media',
						'/media_center',
						41
					);
				}
			});
		}
		// setta di default tutte le voci di menu esplose (aperte)
		// this._currentlyOpen = this._items;

		//  questa funzione permette di generare menu dinamici ma in questo momento volatili
		const dynamicMenuFunctionDemo = () => {
			const dynamicFunction = () => {
				const snackbarRef = snackbar.open('This menu item got added dynamically!', 'Remove item', <
					MatSnackBarConfig
				>{
					duration: 5000
				});

				snackbarRef.onAction().subscribe(() => {
					this.removeItem(dynamicMenu);
				});
			};

			const dynamicMenu = this.addItem('Dynamic Menu Item', 'extension', dynamicFunction, 12);
		};
	}

	// TODO: rifare la firma del metodo
	// Genera un elemento senza padre inoltre fa scattare l'observable su _itemsSubject
	addItem(
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			permission: permission || null,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		if (this.utilService.checkUserPermission(this.user, permission)) {
			this._items.push(item);
			this._itemsSubject.next(this._items);

			return item;
		}
	}

	// TODO: rifare la firma del metodo
	// Genera un elemento che andrà a popolare il campo subItems di uno specifico elemento padre passato nel parametro parent
	addSubItem(
		parent: NavItem,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		if (this.utilService.checkUserPermission(this.user, permission)) {
			parent.subItems.push(item);
			this._itemsSubject.next(this._items);

			return item;
		}
	}

	// rimuove un elemento dall'itemSubject
	removeItem(item: NavItem) {
		const index = this._items.indexOf(item);
		if (index > -1) {
			this._items.splice(index, 1);
		}

		this._itemsSubject.next(this._items);
	}

	isOpen(item: NavItem) {
		// return (this._currentlyOpen.indexOf(item) !== -1);

		// Voci di menu sempre aperte
		return true;
	}

	// ritorna i subItem dell'elemento padre interrogato attivando un observable
	toggleCurrentlyOpen(item: NavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	getAllParents(item: NavItem, currentlyOpen: NavItem[] = []) {
		currentlyOpen.unshift(item);
		if (item.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: NavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen = [];
		const item: NavItem = this.findByRouteRecursive(route, this._items);
		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	// metodo ricorsivo che recupera l'elemento innestato selezionato
	findByRouteRecursive(route: string, collection: NavItem[]): any {
		let result = _.find(collection, { route: route });
		let typeItem: string;
		if (!result) {
			_.each(collection, item => {
				typeItem = 'subItems';
				const found = this.findByRouteRecursive(route, item[typeItem]);
				if (found) {
					result = found;
					return false;
				}
			});
		}
		return result;
	}

	get currentlyOpen() {
		return this._currentlyOpen;
	}

	getSidenavItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}

	getActiveItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}
}
