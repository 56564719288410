import { DiscountTypeModel } from '../../enum/discountType.enum';
import { ListStructureModel } from './../structure/list-structure.model';
import { BasePouchModel } from './base-pouch.model';
import { DiscountModel } from '../article.model';

export interface OrderPouchModelList extends ListStructureModel<OrderPouchModel[]> {}

export class OrderPouchModel implements BasePouchModel {
	header: HeaderPouchModel = new HeaderPouchModel();
	vector: string[] = [];
	note: string[] = [];
	product_list: ProductPouchModel[] = [];
	_id: string;
	_rev: string;
	type?: string = 'order';
}

export class HeaderPouchModel {
	// corrisponde al campo anno_ordine e mm_gg_ordine
	date?: number;
	//date_millis?: number;
	order_number?: number;
	division?: string;
	society_code: string;
	order_causal: string;
	client_code: string;
	// in formato utc
	first_evasion_date: number; // data consegna
	//first_evasion_date_millis?: number; // data consegna
	goods_destination_code: string; // destinazione
	goods_destination_new?: DestinationNew; // new destination
	customer_order_ref_date?: string;
	shipping_mode: string;
	delivery_methods: string;
	price_list_code: string;
	payment_code?: string;
	support_type?: string;

	status: string = 'DRAFT';
	discount_agent?: string; // da verificare
	discount_type?: DiscountTypeModel;

	company?: Company;
	total?: number;
	gic_cup?: string;
	condizione_pagamento?: Pagamento;
	note?: string;
	taxable?: number;
	iva?: number;
	amount?: number;
}

export interface Company {
	business_name: string;
	piva: string;
}

export interface Pagamento {
	item_code?: string;
	description?: string;
	short_description?: string;
	flag_end_month?: string;
	num_days_first_installment?: string;
	num_installment?: number;
	discount_on_invoice?: string;
	payment_type?: string;
	send_provisional_lot?: string;
}

export interface ProductPouchModel {
	line_code: string;
	class_code: string;
	description: string;
	descrizione_aggiuntiva: string;
	sup_row_external: string;
	code: string;
	order_causal_code: string;
	ordered_quantity: number;
	confirmed_quantity?: number;
	price: number;
	note: string;
	um_vendita?: string;
	qty_free?: number;
	coeff_conv_ven_mag?: number;
	discount?: DiscountModel[];
	// todo: remove it when the article translation is implemented

	// populated from ArticleList data in util-order.service.ts
	disponibilita: string;
	pezzi_confezione?: number;
}

export class DestinationNew {
	business_name?: string;
	contact_person?: string;
	address?: {
		address: string;
		locality: string;
		country: string;
		zip_code: string;
		province: string;
	};
}
