import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../../app.config";

@Injectable()
export class ConfigurationService {

  constructor(private http: HttpClient, private appConfig: AppConfig) {
  }

  load() {
    let api: string;
    const rnd = new Date().getTime();
    Object.keys(environment).forEach(key => {
      if (environment[key] && environment[key].constructor !== {}.constructor) {
        api = "env-" + key + "/environment.json?rnd=" + rnd;
      }
    });
    return new Promise(resolve => {
      this.http.get(api).subscribe(
        (res: any) => {
          this.configMapping(res);
          resolve(this.appConfig.config);
        }, (error) => {
          console.error('error', error);
          resolve(error);
        }
      );
    });
  }

  public configMapping(config) {
    this.appConfig.populateConfig(config);
  }
}
