import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromTagList from './tag-list.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('tag-list', fromTagList.reducer)
  ]
})
export class TagListStateModule {}
