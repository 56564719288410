import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { OrderPouchModelList } from '../../model/pouch/order-pouch.model';
import { Pagination } from '../../model/structure/list-structure.model';

import * as fromOrderList from '../order-list/order-list.actions';
import { PouchUtilService } from '../../service/pouchdb/pouch-util.service';

// service
@Injectable()
export class OrderListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType('[Order List Context] Load'),
			mergeMap((action: { pagination?: Pagination; filters?: any; sort?: string[] } = {}) =>
				from(this.getOrderListPouch(action.pagination, action.filters, action.sort)).pipe(
					map((order: OrderPouchModelList) => fromOrderList.update(order)),
					catchError(() => of({ type: '[Order List] Order List Load Error' }))
				)
			)
		)
	);

	constructor(private actions$: Actions, private pouchUtilService: PouchUtilService) {}

	getOrderListPouch(pagination: Pagination, filters?: any, sort?: string[]): Promise<any> {
		return this.pouchUtilService.getActiveAdapter().orderPouch.getOrderList(pagination, filters, sort);
	}
}
