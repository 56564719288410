import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

import { debounce } from '../decorators/debounce.decorator';

export enum K_CODE {
	BACKSPACE = 8,
	TAB = 9,
	ENTER = 13,
	END = 35,
	HOME = 36,
	LEFT_ARROW = 37,
	UP_ARROW = 38,
	RIGHT_ARROW = 39,
	DELETE = 46,
	ESCAPE = 27,
	CTRL_A = 65,
	CTRL_C = 67,
	CTRL_V = 86,
	CTRL_X = 88
}

@Directive({
	selector: '[appInputCeil]'
})
export class InputCeilDirective {
	@Input() base: number;
	@Output() updateInputValue = new EventEmitter<number>();

	constructor(private _elementRef: ElementRef) {}

	insertValue: string;

	@HostListener('focusout', ['$event'])
	onfocusout(e: KeyboardEvent) {
		this.emitEvent(e);
	}

	@HostListener('keyup', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (e.keyCode === K_CODE.ENTER) {
			this.emitEvent(e);
		}
		if (
			[K_CODE.DELETE, K_CODE.BACKSPACE, K_CODE.TAB, K_CODE.ESCAPE, K_CODE.ENTER].indexOf(e.keyCode) !== -1 ||
			(e.keyCode === K_CODE.CTRL_A && e.ctrlKey === true) ||
			(e.keyCode === K_CODE.CTRL_C && e.ctrlKey === true) ||
			(e.keyCode === K_CODE.CTRL_V && e.ctrlKey === true) ||
			(e.keyCode === K_CODE.CTRL_X && e.ctrlKey === true) ||
			// Mac
			(e.keyCode === K_CODE.CTRL_A && e.metaKey === true) ||
			(e.keyCode === K_CODE.CTRL_C && e.metaKey === true) ||
			(e.keyCode === K_CODE.CTRL_V && e.metaKey === true) ||
			(e.keyCode === K_CODE.CTRL_X && e.metaKey === true) ||
			// other
			e.keyCode === K_CODE.END ||
			e.keyCode === K_CODE.HOME ||
			e.keyCode === K_CODE.LEFT_ARROW ||
			e.keyCode === K_CODE.UP_ARROW ||
			e.keyCode === K_CODE.RIGHT_ARROW
		) {
			return; // let it happen, don't do anything
		}
	}

	private emitEvent(e: KeyboardEvent) {
		const input = e.target as HTMLInputElement;
		const inputVal = input.value;
		if (!isNaN(+inputVal)) {
			this._elementRef.nativeElement.value = Math.ceil(+inputVal / this.base) * this.base;
		} else {
			e.preventDefault();
		}
		this.updateInputValue.emit(this._elementRef.nativeElement.value);
	}
}
