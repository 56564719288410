import { BaseTableListService } from '../base-table-list.service';
import { ITdDataTableColumn } from '@covalent/core';

// Translate
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2).replace('.', ',');

export class ProductCheckoutColumnService extends BaseTableListService {
	readonly hierarchy: { key: string; description: string }[] = [{ key: 'description', description: 'Descrizione' }];
	columns: ITdDataTableColumn[] = [
		{
			name: 'code',
			label: this.translate.instant('columnsData.ref'),
			width: 110
		},
		{
			name: 'description',
			label: this.translate.instant('columnsData.description')
		},
		{
			name: 'price',
			label: this.translate.instant('columnsData.price'),
			width: 120,
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'discount',
			label: this.translate.instant('columnsData.discount'),
			width: 100
		},
		{
			name: 'ordered_quantity',
			label: this.translate.instant('columnsData.quantity'),
			width: 80,
			numeric: true
		},
		{
			name: 'help',
			label: '',
			width: 40
		},
		{
			name: 'calculate_price',
			label: this.translate.instant('columnsData.amount'),
			width: 100,
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'note',
			label: this.translate.instant('columnsData.note'),
			width: 50,
			numeric: true
		}
	];
	constructor(private translate: TranslateService) {
		super();
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.columns = [
				{
					name: 'code',
					label: this.translate.instant('columnsData.ref'),
					width: 110
				},
				{
					name: 'description',
					label: this.translate.instant('columnsData.description')
				},
				{
					name: 'price',
					label: this.translate.instant('columnsData.price'),
					width: 120,
					numeric: true,
					format: DECIMAL_FORMAT
				},
				{
					name: 'ordered_quantity',
					label: this.translate.instant('columnsData.quantity'),
					width: 80,
					numeric: true
				},
				{
					name: 'discount',
					label: this.translate.instant('columnsData.discount'),
					width: 100
				},
				{
					name: 'calculate_price',
					label: this.translate.instant('columnsData.amount'),
					width: 100,
					numeric: true,
					format: DECIMAL_FORMAT
				},
				{
					name: 'note',
					label: this.translate.instant('columnsData.note'),
					width: 50,
					numeric: true
				}
			];
		});
	}
}
