import { AbstractPouchdbMethod } from "../../structure/abstract-pouchdb-method";

export class CategoriesPouch extends AbstractPouchdbMethod{

	getCategoryList(): Promise<any> {
		return new Promise(resolve => {
			this.get('groups_agent')
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
