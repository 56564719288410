import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'apexchart-wrapper',
  templateUrl: './apexchart-wrapper.component.html',
  styleUrls: ['./apexchart-wrapper.component.scss']
})
export class ApexchartWrapperComponent implements OnInit {

  @ViewChild('chart', {static: false}) chart: ChartComponent;
  @Input() config: ApexOptions;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}