import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	@Input() title: string;
	@Input() titleOrder: string;
	@Input() subtitle: string;
	@Input() options: string;
	@Input() badge: string;
	@Input() backButton: boolean;

	@Input() navigation: boolean;

	constructor(public location: Location) {}

	ngOnInit() {}
}
