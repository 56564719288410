import { AbstractPouchdbMethod } from "../../structure/abstract-pouchdb-method";
import { Pagination, Filters } from "../../../../model/structure/list-structure.model";

export class CodiceClientePouch extends AbstractPouchdbMethod{

	getClient(clientCode: string): Promise<any> {
		return new Promise(resolve => {
			this.get('client_' + clientCode)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					// some error (maybe a 409, because it already exists?)
					console.log(err);
				});
		});
	}

	getClientList(filters?: Filters, pagination?: Pagination): Promise<any> {
		return new Promise((resolve, reject) => {
			const params = {
				type: 'client',
			} as PouchDB.Find.Selector;
			if (filters.description) {
				if (!params.$and) {
					params.$and = [];
				}
				let description = [];
				let codice: any = {
					codice: {
						$regex: `(?i)${filters.description}`
					}
				}

				description.push(codice);

				let ragione_sociale: any = {
					ragione_sociale: {
						$regex: `(?i)${filters.description}`
					}
				}

				description.push(ragione_sociale);

				params.$and.push({
					$or: description
				});
			}

			if (filters.exists && filters.exists.length > 0) {
				if (!params.$and) {
					params.$and = [];
				}
				filters.exists.forEach(i => {
					let extists: any = {};
					extists[i] = {
						"$exists": true
					}
					params.$and.push(extists);
				});
			}

			if (filters.excludes && filters.excludes.length > 0) {
				if (!params.$and) {
					params.$and = [];
				}
				filters.excludes.forEach(i => {
					let exists: any = {};
					exists[i] = {
						"$exists": false
					};
					params.$and.push(exists);
				});
			}

			this.getWithIndexFilter('all_clients', params, pagination)
				.then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
		});
	}

}
