import { CompanyCodeEnum } from '../../../../enum/company-code.enum';
import { AppConfig } from '../../../../app.config';
import { Pagination } from '../../../../model/structure/list-structure.model';
import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';


export class ArticlePouch extends AbstractPouchdbMethod {

	getArticleList(
		appConfig: AppConfig,
		pagination?: Pagination,
		filters: {
			descrizione?: string,
			article_codes?: string[],
			family?: string,
			excludes?: any
		} = {}): Promise<any> {

		return new Promise(resolve => {
			let filt: PouchDB.Find.Selector;
			filt = {
				type: "article"
			} as PouchDB.Find.Selector;

			if(filters.family) {
				filt.$and = [{
					budget_number: filters.family
				}]
			}

			if (filters.descrizione) {
				filt.$or = [
						{
							descrizione: {
								$regex: `(?i)${filters.descrizione.trim()}`
							}
						},
						{
							codice: {
								$regex: `(?i)${filters.descrizione.trim()}`
							}
						}
				];
			}
			if (filters.article_codes && filters.article_codes.length !== 0) {
				filt.$or = [
					{
						codice: {
							$in: filters.article_codes
						}
					}
				];
			}

			if (filters.excludes) {
				if (!filt.$or) {
					filt.$or = [];
				}
				Object.keys(filters.excludes).forEach(i => {
					let extists: any = {};
					extists[i] = {
						"$exists": false
					}
					filt.$or.push(extists);

					let not: any = {};
					not["$not"] = {};
					not["$not"][i] = filters.excludes[i];
					filt.$or.push(not);
				});
			}

			let sort: string[];
			switch(appConfig.config.company.code) {
                case CompanyCodeEnum.NUI:
                    sort = ['order_description']
                    break;
                default: 
                    sort = ['description_sorting']
                    break;
            }

			this.getWithIndexFilter('article', filt, pagination, sort)
				.then(doc => {
					// console.log(doc)
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getArticleListByCategory(codiceCategory: string, pagination?: Pagination): Promise<any> {
		return new Promise((resolve, reject) => {
			const filters = {
				'type': 'article'
			} as PouchDB.Find.Selector;
			if (codiceCategory) {
				filters['group_agent'] = codiceCategory;
			}
			this.getWithIndexFilter('article_list', filters, pagination)
				.then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
		});
	}

	getArticlePrice(group, articleCode): Promise<any> {
		return new Promise(resolve => {
			this.get(`list_${group}_${articleCode}`)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					// some error (maybe a 409, because it already exists?)
					console.log(err);
				});
		});
	}

	getExludesFilter() {

	}

}
