import { FamilyPouchModel } from '../../../../model/pouch/family-pouch.model';
import { Pagination } from '../../../../model/structure/AbstractListModel';
import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';

export class FamilyPouch extends AbstractPouchdbMethod {
	searchKeys = ['description', 'code', 'variant', 'material', 'finish_coating'];
	getFamilyList(pagination?: Pagination, filters: any = {}): Promise<FamilyPouchModel[]> {
		const type = this.getCurrentLang().toUpperCase() === 'IT' ? 'family' : 'family_translation';
		return new Promise((resolve, reject) => {
			const params = {
				$and: [
					{
						type: type
					}
				]
			} as PouchDB.Find.Selector;
			const sort = ['sort_group', 'sort_class'];
			if (filters.description) {
				const descriptionTokens = filters.description.trim().split(" ");
				const orArray = [];
				this.searchKeys.forEach(searchKey => {
					const andArray = [];
					descriptionTokens.forEach(token => {
						const andObject = {};
						andObject[searchKey] = {
							$regex: `(?i)${token}`
						};
						andArray.push(andObject);
					});
					orArray.push({$and: andArray});
				});
				const desc = {
					$or: orArray
				};
				params.$and.push(desc);
			}
			if (filters.families && filters.families.length > 0) {
				const desc = {
					code: {
						$in: filters.families
					}
				};
				params.$and.push(desc);
			}

			this.getWithIndexFilter('all_family', params, pagination, sort)
				.then((res: any) => {
					resolve(res.docs);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}
