import { Injectable } from '@angular/core';

import { AppConfig, ConfigModel } from '../../app.config';
import { PouchDbBaseAdapter } from './centroutensili/pouchdb-base-adapter.service';

@Injectable()
export class PouchUtilService {
	pouchInstance: PouchDbBaseAdapter[];
	config: ConfigModel;
	activeAdapter: PouchDbBaseAdapter;

	constructor(private appConfig: AppConfig) {
		this.appConfig.config$.subscribe(config => {
			if (config.urlConfig) {
				this.config = appConfig.config;
			}
		});
	}

	explicitInitCouch() {
		return new Promise(async (resolve, reject) => {
			if (this.config.urlConfig.couch) {
				for (const pouchdb of this.pouchInstance) {
					const environment = this.config.urlConfig.couch.find(env => {
						return env.database === pouchdb.database;
					});
					if (environment) {
						await pouchdb.initDb(environment);
						this.activeAdapter = pouchdb;
					}
				}
				resolve();
			}
		});
	}

	getActiveAdapter(): PouchDbBaseAdapter {
		return this.activeAdapter;
	}
}
