// Translate
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/rest/auth.service';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	constructor(public authService: AuthService, public translate: TranslateService) {}
	ngOnInit() {}
}
