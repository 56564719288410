import { Injectable } from '@angular/core';

import { PouchDbBaseAdapter } from './pouchdb-base-adapter.service';

@Injectable()
export class PouchDbNuiAdapter extends PouchDbBaseAdapter {
	database = 'nui';

	constructor() {
		super();
	}
}
