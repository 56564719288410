import { Component, OnInit } from '@angular/core';
// model
import { CompanyState } from '../../../../../state/company/company.reducer';
// store
import { Store } from '@ngrx/store';
import * as fromState from '../../../../../state/index';
import * as fromCompany from '../../../../../state/company/company.actions';
// utility & widget
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../../../../model/user.model';

@Component({
	selector: 'company-detail-users',
	templateUrl: './company-detail-users.component.html',
	styleUrls: ['./company-detail-users.component.scss']
})
export class BackofficeCompanyDetailUsersComponent implements OnInit {

	// store
	company$: Observable<CompanyState> = this.store.select(fromState.getCompanyState);
	_company: Subscription;
	company: CompanyState = <CompanyState>{};

	constructor(
		private store: Store<any>,
		private snackBar: MatSnackBar
	) {
		this._company = this.company$.subscribe((res: CompanyState) => {
			if (res) {
				this.company = res;
			}
		});
	}

	ngOnInit() {}

	// data
	onUserListChange(e: User[]) {
		if (this.company.codice) {
			this.company.users = e;
			this.store.dispatch(fromCompany.saveUsers(this.company));
		} else {
			this.snackBar.open(
				"Prima di salvare è necessario selezionare un Cliente",
				"HO CAPITO",
				{
					duration: null
				}
			);
		}
	}

}
