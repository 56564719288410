import { AbstractPouchdbMethod } from '../../structure/abstract-pouchdb-method';
import { PersonPouchModel } from '../../../../model/pouch/person-pouch.model';

export class ContactPouch extends AbstractPouchdbMethod{

    getContactList(): Promise<any> {
		return new Promise(resolve => {
			this.get('contacts')
				.then(doc => {
					console.log(doc)
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

    putContact(contactModel: PersonPouchModel, isGuid: boolean): Promise<any> {
        const guid = isGuid ? 'order' : contactModel._id;
		return new Promise(resolve => {
			this.put<PersonPouchModel>(contactModel, guid, isGuid)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
    }
}
