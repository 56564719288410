import { Route } from '@angular/router';
// page
import { CompanyDestinationComponent } from '../page/company/company-destination/company-destination.component';
import { CompanyFullDetailComponent } from '../page/company/company-full-detail/company-full-detail.component';
import { OrderComponent } from '../page/order/order.component';
import { OrderDetailComponent } from '../page/order/order-detail/order-detail.component';
import { OrderDetailCatalogComponent } from '../page/order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { PageCatalogComponent } from '../page/catalog/page-catalog.component';
import { DashboardComponent } from '../page/dashboard/dashboard.component';
import { UserComponent } from '../page/user/user.component';
import { MediaCenterComponent } from '../page/media/media-center/media-center.component';

export const routes: Route[] = [
	{
		path: '',
		component: DashboardComponent,
		pathMatch: 'full'
	},
	{
		path: 'user',
		component: UserComponent,
		pathMatch: 'full'
	},
	// {
	// 	path: 'destinations',
	// 	component: CompanyDestinationComponent,
	// 	pathMatch: 'full'
	// },
	{
		path: 'details',
		component: CompanyFullDetailComponent,
		pathMatch: 'full'
	},
	{
		path: 'orders',
		children: [
			{
				path: ':statusOrder',
				component: OrderComponent
			},
			{
				path: ':statusOrder/:idCompany/:idOrder',
				component: OrderDetailComponent,
				children: [
					{
						path: '',
						redirectTo: 'catalogo',
						pathMatch: 'full'
					},
					{
						path: 'catalogo',
						component: OrderDetailCatalogComponent
					},
					{
						path: 'carrello',
						component: OrderDetailCheckoutComponent
					}
				]
			}
		]
	},
	{
		path: 'media_center',
		component: MediaCenterComponent,
		pathMatch: 'full'
	}
];
